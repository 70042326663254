
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@font-face {
  font-family: 'Frutiger-Basiligo';
  src: url('../assets/dashboard/fonts/FrutigerLTStd-Cn.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Frutiger-Basiligo';
  src: url('../assets/dashboard/fonts/FrutigerLTStd-Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Garamond-Basiligo';
  src: url('../assets/dashboard/fonts/EBGaramond-Bold.ttf');
  font-weight: 700;
}
strong {
    font-weight: 600;
  }
.small-cart {
    border-radius: 90px;
    width: 64px;
    height: 64px;
    background: #8ea89c;
    color: #fff;
    position: fixed;
    z-index: 99999;
    cursor: pointer;
    bottom: 70px;
    right: 12px;
}

.small-cart img {
    width: 48px;
    margin-left: 8px;
    margin-top: 6px;
}

.full-cart {
    position: fixed;
    bottom: 65px;
    z-index: 99999999999;
    width: 25vw;
    right: 10px;
    height: 75vh;
    background: white;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
}

.cart-row {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 75px !important;
    display: block;
}

.cart-total {
    font-weight: 600;
    color: #001F3D;
}

.cart-img {
    max-width: 25%;
    float: left;
    padding: 3px;
    border: 1px solid whitesmoke;
}

.cart-price {
    color: #B7E2E5;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
}

.cart-calories {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    display: block;
    margin-bottom: -5px;
}

.cart-text {
    width: 70%;
    float: right;
    padding-right: 10px;
}

.cart-img img {
    max-width: 5vw;
}

.cart-content {
    height: 48vh;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.cart-head, .cart-content {
    border-bottom: 1px solid whitesmoke;
}

.cart-counter {
    background-color: #001F3D;
    color: #fff;
    padding: 5px;
    padding-top: 5px;
    width: 18px;
    height: 18px;
    border-radius: 23px;
    font-size: 11px;
    position: relative;
    top: -55px;
    left: 45px;
    display: block;
    padding-top: 0px;
}

.empty-cart-img img {
    max-width: 220px;
}

.empty-cart-row {
    text-align: center;
}

.empty-cart-text {
    font-weight: 600;
}

.cart-remove {
    font-size: 11px;
    text-decoration: underline;
    margin-left: 10px;
    position: relative;
    top: -2px;
    cursor: pointer;
}

.full-cart .icon-close {
    float: right;
    position: relative;
    top: -5px;
}

.select2-results__group {
    color: #001F3D;
    text-decoration: underline;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #001F3D !important;
}

.select2-selection__rendered {

}

.select2-results__option {
    padding: 5px 10px !important;
}

.editsides-btn {
    font-size: 12px !important;
    background-color: #001F3D;
    color: #fff !important;
    cursor: pointer;
    display: block;
    text-align: center;
    margin-top: 5px;
}

.delivery-bage {
    position: relative;
    top: -12px;
}

.additional-delivery-date {
    display: block;
    font-size: 14px;
    margin-top: -7px;
    margin-bottom: 4px;
}

.custom-day-delivery {
    background-color: #e6f4ed;
    padding: 0px 10px;
}

.dish-label-value {
    font-size: 12px;
    color: white;
    background: #B7E2E5;
    display: block;
    width: 100%;
    padding: 2px;
    text-align: center;
}

.pdf-icons-side {
    background-color: #001F3D;
    text-align: center;
    width: 100%;
    padding: 0 10%;
}

.table-noborder td {
    text-align: left;
    border: none !important;
}

.pdf-icon span {
    color: #B7E2E5 !important;
    font-size: 14px;
    line-height: 17px;
    display: block;
}

.pdf-icon i {
    color: #fff;
    background: #12BDC7;
    padding: 10px;
    border-radius: 36px;
    position: relative;
    top: -14px;
    left: 12px;
    font-size: 22px;
}

.pdf-icon {
    border: 2px solid #12BDC7;
    text-align: center;
    padding: 10px;
    margin: 10px 5px;
    border-radius: 10px;
    width: 70px;
    min-height: 85px;
    cursor: pointer;
    display: inline-block;
}

.pdf-icon:hover {
    background-color: rgba(255,255,255,0.2) !important;
}
.pdf-container{
    margin: 0 0 10px 0;
    border-bottom: 1px solid #fff;
    float: left;
    height: 100%;
    padding:0;
    width:100%;
}
.pdf-head{
    text-align: center;
}
.pdf-head h3 {
    color: #fff;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .pdf-head span {
    color: #9fc1e1;
    font-size: 12px;
        margin-bottom: 10px;
        display: block;
    }
.addresses-fields h3 {
    font-weight: bold;
}

.hint-bubl {
    background: #B7E2E5;
    color: #fff;
    padding: 3px 7px;
    border-radius: 22px;
    cursor: pointer;
    font-size: 11px;
    position: relative;
    top: -4px;
    left: -4px;
}

.right-switcher {
    margin-left: 20px;
}

.bottom-mod-type-switcher {
    display: block;
    float: right;
    margin-right: 20px;
    border: 1px solid #bebebe;
    border-radius: 3px;
    padding: 5px 15px;
    color: #858585;
    cursor: pointer;
    margin-bottom: 15px;
}

.bottom-mod-type-switcher.future {
    background: #001f3d;
    color: white;
    border-radius: 0px;
    border-color: #001f3d;
}

#terms-page h1 {
    font-size: 28px;
    color: #a1aaa7;
    text-align: center;
    font-family: Lato,sans-serif;
    font-weight: initial;
    letter-spacing: 4px;
    margin-top: 30px;
}

.terms-content {
    padding: 40px;
}

.terms-content p {
    text-align: justify;
}

.terms-content b {
    font-weight: bold !important;
    text-transform: uppercase;
    color: #a1aaa9;
}

.terms-content .disclaimer {
    background-color: #f1f1f1;
    padding: 20px;
    border-top: 3px solid #a1aaa9;
}

.terms-content ul li {
    list-style: circle;
    margin-bottom: 10px;
    margin-top: 10px;
}

.terms-content ol li {
    margin-bottom: 10px;
    margin-top: 10px;
}

.demoview td, .demoview tr, .demoview p, .demoview span, .demoview h5 {
    opacity: 0.5 !important;
}

.small-btn-text {
    font-size: 11px;
}

.bold {
    font-weight: bold !important;
}

.demoview img {
    opacity: 0.3 !important;
}

.progressSoon {
    position: relative;
    top: 125px;
    font-size: 23px;
    height: 0;
    text-transform: uppercase;
    opacity: .5;
    width: 100%;
    text-align: center !important;
    display: block;
}

.days-left-text {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: coral;
}

.days-upd-text {
    position: relative;
    top: 10px;
    color: #B7E2E5;
    font-size: 13px;
}

.addr-checkbox {
    margin-top: 8px;
}

.addr-checkbox input {
    margin-right: 8px;
    position: relative;
    top: 4px;
}

.address-edit-div select {
    font-size: 13px !important;
    font-weight: 400 !important;
    border: 1px solid #e4ebee !important;
    border-radius: 2px !important;
    padding-left: 10px !important;
}

.rating-side b {
    min-width: 150px;
    display: block;
    float: left;
    font-size: 12px;
    text-transform: uppercase;
}

.car-arrow {
    cursor: pointer;
}

.car-arrow img {
    width: 48px;
    height: 48px;
}

.medical-text-area {
    width: 100%;
    height: 160px;
}

.disabled-select {
    opacity: 0.6;
}

.share-img {
    width: 100%;
}

.bold-texts b {
    font-weight: bold !important;
}

.bold-texts span {
    text-transform: uppercase;
}

.popup-dash-calendar-fix .rdb {
    top: -116px !important;
}

.food-prefs-header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
}

.date-changer {
    color: #12BDC7 !important;
    text-decoration: underline !important;
    display: block;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    cursor: pointer !important;
}

.progress-block {
    margin-bottom: 20px;
}

.popup-select {
    margin-bottom: 14px;
}

.popup-note-text {
    font-size: 12px;
    line-height: 14px !important;
    text-align: left;
}

.popup-note-text b {
    font-weight: bold;
    font-size: 12px !important;
}

.progress-block .date {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    top: 8vw;
}

.progress-block .before img {
    max-width: 200px;
}

.progress-block .after img {
    max-width: 200px;
}

html {
    overflow-x: hidden;
    display: flex;
    align-items: stretch;
    min-height: 100%;
    width: 100%;
    scrollbar-color: #f3f3f3 #fdfdfd;
}
body {
    background: #fff;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.small-save {
    padding: 10px !important;
}

.dash-ava {
    width: 160px;
    max-height: 160px;
    background: #B7E2E5;
}

.demo-view {
    opacity: 0.1;
}

.manager-img {
    background: #B7E2E5;
    border-radius: 50%;
}

.save-later {
    opacity: 0.6;
}

.maps-input {
    cursor: pointer;
}

.map-ul li {
    list-style: disc;
    margin-left: -25px;
}

.terms-url {
    position: relative;
    top: -27px;
    left: 195px;
    color: #001F3D !important;
    text-decoration: underline !important;
}

/* icons */
.icons-regular {
    font-size: 1.7rem;
}

.icons-medium {
    font-size: 3rem;
}
select{
    appearance: none;
}
/* end of icons */

html *, body * {
   font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.8;
    color: #001F3D;
    font-size: 15px;
}

input:active,
input:focus {
    outline: none !important;
}

.menu-day-text {
    font-size: 13px;
    margin-top: 10px;
    font-weight: bold;
    /*color: silver !important;*/
    color: #333 !important;
}

.selections .not-selected,
.selections .gray {
    color: #bbb;
}

.share-textarea {
    resize: vertical;
    width: 100%;
    height: 90px;
    padding: 10px;
}

.macros span {
    padding-right: 3px;
}

.regular {
    font-weight: 400;
}

.regular-bold {
    font-weight: 600;
    color: #414d65;
}

.uppercase {
    text-transform: uppercase;
}

.selected-plan {
    background: #fdfdfd;
}

.selected-plan .checkbox-container .check-block {
    background: #fff;
    margin: 0 auto;
}

.gold {
    color: #e4cb9a !important;
}

.s-gray {
    color: #001F3D !important;
}

.green,
.green span,
.selected,
.selections .selected,
.selections .green {
    color: #12BDC7;
    margin: .5rem 0 1rem 0;
}

.absolute-cal .selected {
    margin: 0;
}

.green-green {
    color: #4dab8f !important;
}

.gold-gold {
    color: #12BDC7 !important;
}

.plan-check {
    padding: 15px 15px 7px 15px;
    background: #fbfaf9;
    border-bottom: 1px solid #efefef;
}

.bg-gray-bordered {
    background: #fbfbfb;
    border-bottom: 1px solid #efefef;
    padding: 15px 0 10px 0;
}

.bg-gray-bordered.rev-rating {
    margin-top: 10px;
}

.plan-check .checkbox-container .checkmark {
    border-radius: .2rem;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.rdtDay.delivery-day.skipped-day {
    background: #3c475a !important;
    border: 1px solid #616f87;
    color: #ad7767 !important;
}

.row .calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.rdtDay.delivery-day.skipped-day[selector=one-more] {
    color: #001F3D !important;
    background: #414d65 !important;
}

.bg-gr-light {
    background: #fff;
}

.bg-gr-light-s {
    background: rgb(251, 250, 249);
}

.bg-gr-light-l {
    background: #e6f4ed;
    padding: 15px;
}

.bg-gr-light-l > div {
    padding-left: 0;
}

.bg-gr-light-2 {
    background: #fbfbfb;
    padding: 15px 15px 20px;
}

.bg-gr-light-2 > div {
    padding-left: 0;
}

.plan-check .checkbox-container input:checked ~ .checkmark {
    border-color: #e4cb9a;
    background: #e4cb9a;
}

.plan-check .checkbox-container input:checked ~ .meals {
    font-weight: 600;
}

.plan-check .checkbox-container .meals:after {
    display: block;
    content: attr(title);
    font-weight: 600 !important;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
}
.plan-page,.wrap-progress-page, .modify-page{
    height: calc(100vh)!important;
}
.plan-page .top-params-filters{
    max-width: calc(100vw - 30px);
}
.top-nav-heading {
    margin: 0 auto;
    height: 100%;
    max-width: 1440px;
}

.plan-check .checkbox-container input:checked ~ .checkmark:after {
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
}

.heading h1 {
    font-size: 27px;
    letter-spacing: 0.1em;
    color: #333;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-top: 1rem;
}

.heading .sub-haeding span {
    font-size: 15px;
    letter-spacing: 0.1em;

    font-weight: 400;
    line-height: 1.2;
    font-style: italic;
    color: #B7E2E5;
    display: inline-block;
    width: 100%;
    text-align: center;
}

.time-programm-group .choosed {
    background: #e6f4ed;
}

.time-programm-group h5.green {
    text-decoration: none;
}

.roboto,
.page-header .nav-item .nav-link {
    font-family: 'Lato', sans-serif;
}

#root {
    height: 100%;
}

input,
button {
    outline: none !important;
}

.container,
.container-fluid {
    margin: 0;
}

.name {
    font-size: 1.1rem;
}

.service,
.count {
    color: #b6aba8;
    font-size: .95rem;
}

.middle-block .name:last-child {
    margin-bottom: 1rem;
}

.last-block {
    min-width: 4rem;
}

.list-holder .icons-sub-block span {
    font-size: 1.3rem;
}

.list-holder .icons-sub-block span span {
    font-size: .8rem;
}

.sub-row {
    height: 50%;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main-holder {
    max-width: 100%;
    flex: 1 1;
    padding-top: 62px;
}

.main-inner {
    height: 100%;
    padding: 1% 0;
    width: 100%;
}
.order-block-holder,
.calendar-holder,
.list-holder .list {
    height: 100%;
    background: #fff;
    overflow-y: auto;
    overflow-x: hidden !important;
    font-weight: normal;
    padding: 12px 30px;
}
.order-block-holder,
.list-holder .list{
    border-bottom: 1px solid #f3f3f3;
}
.calendar-holder,
.order-block-holder {
    padding: 0;
}

.order-block-holder {
    padding-top: 10px;
    z-index: 299;
}

.simple-calendar {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 15px;
}

.calendar-holder {
    background: #001F3D !important;
}
.paper-bc:hover{
    cursor: pointer;
}
.calendar-holder .paper-bc{
    margin-bottom: 15px!important;
}
.calendar-holder .select-delivery{
    margin-top: 15px;
    margin-bottom: 15px!important;
}
.list-holder .custom-list {
    position: relative;
}

.calendar-holder .hide-arrow-calendar {
    margin-bottom: 15px;
}

.list-holder .custom-list.list {
    overflow: hidden;
}

.calendar-holder,
.list-holder {
    position: relative;
}

.multiselect {
    margin-bottom: 2rem;
}

.multiselect .subtext {
    line-height: 50px;
}

.multiselect h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .075em;

}

.huge-icons {
    font-size: 32px;
    width: 32px;
    padding-right: 20px;
}

.mp-table {
    display: table;
}

.multiselect .multiselect-holder {
    background: #fbfaf9;
    border-bottom: 1px solid #efefef;
    padding: 1rem;
    /*background-image: url('../assets/bg2-w.png');*/
}

.Select-control {
    border-color: #cccacabd !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
    border: none !important;
}

.Select--single > .Select-control .Select-value, .Select-placeholder {
    bottom: 6px !important;
    color: #999 !important;
    left: 5px !important;
    line-height: 31px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    position: absolute !important;
    right: auto !important;
    top: auto !important;
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    border: 1px solid #e6d3aa !important;
    background: #f5ead3 !important;
    border-radius: 2px !important;
    cursor: pointer;
    letter-spacing: .8px;
}

.mp-table span {
    display: table-cell;
    vertical-align: middle;
}

/* headers */
.place-order,
h2, h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #414d65;
}

.Select.is-focused:not(.is-open) > .Select-control {
    border-color: #ccc !important;
    box-shadow: none !important;
    background: #fff !important;
}

.day h5 {
    color: #001F3D;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
    letter-spacing: .05em;
    margin-top: .9rem;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: .5rem;
}

.head-line h5 {
    font-size: 20px;
    padding: 25px 0 .5rem;
    color: #001F3D;
    margin: 0;
    position: relative;
    cursor: pointer;
}

.day h5 span {
/*    color: #B7E2E5;*/
}

.on-dishes {
    width: 300px;
}

.on-dishes .rc-tooltip-inner {
    width: 300px;
}

.PromoImage {
    width: 100%;
}

.dishDetailsBlock {
    padding: 20px 10px;
}

.PromoButton {
    position: relative !important;
    top: -65px;
    left: 20%;
    margin-bottom: -40px !important;
}

.diet-name-edit {
    color: #12BDC7;
    font-size: 20px;
    font-weight: bold;
}

.diet-overview-edit {
    font-size: 15px;
    font-weight: bold;
}

.product-title {
    font-size: 12px !important;
    line-height: 15px !important;
}

.skips {
    display: inline-block;
    height: 25px;
    width: 25px;
    background: #B7E2E5;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    line-height: 26px;
    margin-right: 7px;
    cursor: pointer;
}

.on-skip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 3%;
}

.hidden-on-desktop.replicate {
    margin: 1rem 0;
    border: none !important;
}

.hidden-on-desktop.replicate .button-regular.transition.hidden-on.paper-bc {
    left: 0;
}

.head-line {
    background: #fff;
}

.order-block-holder .head-line {
    text-align: center;
}

.order-block-holder .head-line h5 {
    padding: 15px 0 15px 0;
}

.ingridients {
    list-style: disc;
    padding-left: 20px;
}

.day-menu h2 {
    color: #001F3D;
    font-weight: 700;
    font-size: 16px;

    margin: 0;
    letter-spacing: .05em;
}

h2, h5 {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.rdtDay.partial {
    background: #d2e6dd;
}

.rdtDay.full {
    background: #81b19b;
}

.Select-control {
    height: 45px !important;
}

.Select--multi .Select-value {
    background-color: #ffffff;
    border: none !important;
}

.Select--multi .Select-value-label,
.Select--multi .Select-value-icon {
    padding: 1px 7px 3px;
    border: 1px solid #dec48e;
    background: #ead6ae;
    letter-spacing: .8px;
    color: #001F3D !important;
}

.Select--multi .Select-value-icon {
    border-right: #ead6ae !important;
}

.Select--multi .Select-value-icon:focus,
.Select--multi .Select-value-icon:hover {
    background-color: #dec48e !important;
    color: #001F3D !important;
}

.Select-input > input {
    padding: 13px 0 12px !important;
}

.button-regular,
.place-order {
    width: 90%;
    margin: 5%;
    border: 1px solid #eef2ee !important;
    padding: 10px 15px;
    cursor: pointer;
    background: #c7d9c7;
    border-radius: 1px;
    color: #001F3D;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    /* text-transform: uppercase; */
}

.place-order.paper-bc {
    background: #fff;
}

.locked {
    opacity: .5;
    cursor: not-allowed !important;
}

.button-regular {
    margin: 1% 5%;
    background: #fff;
    padding: 7px 0px;
    min-height: 48px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .01);
}

.place-order.paper-bc,
.paper-bc.button-regular {
    border-radius: 0;
    height: 48px;
}
.without-scroll{
    padding: 20px ;
}
.place-order.paper-bc .paper-bb,
.paper-bc.button-regular .paper-bb {
    background: #fff !important;
    opacity: .2;
}

.button-regular.main {
    margin: 2% 1%;
    background: none;
    width: 40%;
    display: block;
    min-height: 70px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: 20px;
}

.programm-title {
    text-align: center;
}

.duration,
.programm-title h5 {
    font-size: 20px;
    font-weight: 600;
    /*margin: .75rem 0;*/
    letter-spacing: .075em;
    font-family: "Playfair Display", Georgia, serif;
    line-height: 1.5rem;
}

.programm-title h5 {
    font-size: 13px !important;
    line-height: 18px !important;
}

.duration {
    font-weight: 600;
    text-transform: none;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 1.35;
    color: #001F3D;
}

.bg-gr-light-duration {
    background: #d2eade;
    border-bottom: 1px solid #d2eade;
    height: 4.5rem;
    display: flex;
    align-items: center;
}

.bg-gr-light.programm-title {
    background: #fbfaf9;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    margin: 0px !important;
    padding: 0px !important;
}

.program-chooses {
    padding: 10px 0;
}

.program-choose {
    text-align: center;
    padding: 10px 0;
}

.checkbox-box,
.program-choose .checkbox-container,
.my-plan .checkbox-container {
    position: relative;
    padding-right: 40px;
    margin-right: 40px;
    margin-left: 0;
    margin-bottom: 0;
    display: inline-block;
    top: 0;
    right: 0;
}

.checkbox-box {
    position: relative !important;
    max-width: 100% !important;
    padding-right: 70px !important;
    margin-right: 70px !important;
    margin-left: 0;
    margin-bottom: 0;
    display: inline-block !important;
    top: 0;
    right: 0;
}

.checkbox-box .checkmark:before {
    content: 'yes';
    position: absolute;
    left: -30px;
    top: -5px;
}

.program-choose .checkbox-container {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
}

.program-choose .checkbox-container .checkmark {
    border-radius: 40%;
}

.check-block .check-block-text, .check-block .check-block-text * {
    color: #a7a7a7;
    font-size: 14px;
    line-height: 30px;
}

.selected-plan .check-block {
    border-color: #B7E2E5 !important;
    border-radius: .1rem;
}

.selected-plan .check-block .check-block-text, .selected-plan .check-block .check-block-text * {
    color: #001F3D;
}

.allert-message-aed {
    display: none;
    text-align: right;
    margin-top: 10px;
}

.program-choose .checkbox-container input[type=checkbox]:checked + .check-block .check-block-text {
    font-weight: 600;
    color: #001F3D;
}

.program-choose .checkbox-container input[type=checkbox]:checked + .check-block .checkmark {
    opacity: 1;
    border-color: #e4cb9a;
    background: #e4cb9a;
    border-radius: .2rem;
}

.program-choose .checkbox-container .checkmark,
.program-choose .checkbox-container .radiomark {
    position: absolute;
    height: 19px;
    width: 19px;
    margin: .2rem;
    top: -7px;
    right: -9px;
    background: #fff;
    z-index: 2;
}

.program-choose .checkbox-container .checkmark {
    opacity: 0;
}

.time-programm-group .plan-name {
    padding: 0px 15px 0px;
    cursor: pointer;
    line-height: 1.35;
    color: #001F3D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 52px;
}

.time-programm-group .plan-name:after {
    display: block;
    content: attr(title);
    font-weight: 600 !important;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
}

.time-programm-group .plan-list .green {
    background: #f8f9fa;
}

.time-programm-group .plan-list .plan-name:hover {
    text-decoration: underline;
}

.program-choose .checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.choose-holder {
    /*height: 15%;*/
}

.buttons-grid-holder {
    /*height: 65%;*/
}

.buttons-grid {
    height: 100%;
}

h2.green,
h5.green {
    font-size: 15px;
    letter-spacing: .05em;
    text-transform: uppercase;
    /*color: #959595;*/
    font-weight: 400;
    /*background-image: url('../assets/leaf.png');
    background-position: right center;
    background-repeat: no-repeat;
    width: max-content;
    background-size: 11%;;
    padding-right: 40px;
    display: inline-block;*/
}

.main-page .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 63px);
}
.main-content .buttons-holder > div {
    min-height: 70px;
}
.title-holder {
    align-items: flex-end;
    padding: 0;
}

.title-holder .title-block h1 {
    text-align: center;
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: 0.2em;
    color: #555;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    line-height: initial;
}

.title-holder .title-block p span {
    font-size: 1.5rem;
    line-height: 26px;
    font-weight: 400;
    color: #001F3D;

    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: inline-block;
    text-align: center;
}

.title-holder .title-block p {
    text-align: center;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arr-rr,
.title-holder .title-block p .ar-golr-r {
    background-image: url('../assets/Slider-Arrow-Gold.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin-left: 10px;
    width: 70px;
    height: 15px;
	display: none;
}

.arr-ll,
.title-holder .title-block p .ar-golr-l {
    background-image: url('../assets/Slider-Arrow-Gold-inverted.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    margin-right: 10px;
    width: 70px;
    height: 15px;
	display: none;
}

.programm-titles > div {
    text-align: center;
}

.choose-holder {
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.step-holder {
    margin-top: 2rem;
}

.chat-holder-part .dish-holder {
    border: none !important;
}

.back {
    font-weight: 600;
    cursor: pointer;
    line-height: 16px;
    font-weight: normal;
}

.back span {
    font-size: 16px;
    padding-right: 5px;
    padding-right: 5px;
    color: #001F3D;
}

.stars p {
    margin: 0;
    line-height: 30px;
}

.one-day-menu h2 h2 {
    margin-top: 15px;
    font-size: 18px;
    margin-bottom: 0;
}

.one-day-menu p {
    margin: 15px 0 5px 0;
}

.one-day-menu .menu-product div .tags .green,
.one-day-menu .menu-product div .tags .macros {
    margin: 0;
    line-height: 18px;
}

.one-day-menu .menu-product div .tags .macros span {
    font-size: 12px;
    line-height: 12px;
}

.one-day-menu h2 span {
    font-size: 18px;
    font-weight: 700;

    line-height: 18px;
}

.one-day-menu img {
    max-width: 100%;
}

.heading {
    margin: 0;
}

.icon-star.light-dark-text {
    display: initial;
}

.heading .stars p .icon-star.light-dark-text {
    color: #b6b6b6;
}

.week-switch {
    background: #fff;
    padding: 5px 0;
    text-align: center;
    color: #001F3D;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #eef2ee;
}

.week-switch span {
    color: #001F3D;
    font-size: 17px;
    line-height: 16px;
    padding-right: 5px;
}

.regular-page {
    max-width: 100%;
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
    flex: 1 1;
    position: relative;
    z-index: 2;
}

.progress-page {
    padding-right: 15px;
    padding-left: 15px;
}
.progress-page .part-1 {
    padding: 20px 2% 20px;
}

.progress-page .part-2-inner {
    border-left: 1px solid #f3f3f3;
    height: 100%;
    width: 100%;
    margin: 0;
}
.progress-page .part-2-inner .image{
    margin-bottom: 10px;
}
.blog-heading-menu {
    position: relative;
    z-index: 1;
}
.thank-logo{
    background: url('../assets/big_logo.svg') no-repeat bottom;
    -webkit-background-size: contain;
    background-size: contain;
    max-width: 400px;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    opacity: 0.3;
}
.next-delivery .buttons > div,
.track-progress .buttons > div{
    padding: 0 10px;
    margin: 5px 0;
    flex: 1 1 100px;
}
.page-404 h1{
    font-size: 80px;
    text-shadow: 2px 2px 3px #8a8a8a;
}
.page-404{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.track-progress{
    display: none;
    margin-bottom: 15px;
}
.track-progress .buttons{
    display: flex;
    flex-wrap: wrap;
}
.track-progress h5{
    margin-bottom: 15px!important;
}
.weeks-nav {
    padding: 0 15px;
}
.weeks-nav .mini{
    margin-bottom: 15px;
}
.weeks-nav .week-switch{
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.2s;
}
.vertical-scrollbar,
.horizontal-scrollbar{
    overflow: auto;
}
.chat-holder-part .one-day-menu,
.chat-holder-part .dish-holder {
    padding: 25px 15px 10px 15px;
    background: #ffffff;
}
.one-day-menu .regular-bold{
    margin-bottom: 0;
}
.one-day-menu .menu-product{
    margin: 0 -15px;
    padding: 10px 15px;
}
.one-day-menu{
    z-index: 299;
}
.right-part .one-day-menu {
    padding: 15px 15px 10px 15px;
}

.dish-holder .dish-image {
    max-width: 100%;
    width: 100%;
    padding-top: 10px;
    margin: 0 auto;
    display: block;
}

h2 span.date {
    font-family: 'Lato', sans-serif;
}

.checkbox-container input:checked ~ .toggle-mark {
    background: #B7E2E5 !important;
}

.toggle-mark:before {
    background: #e2e2e2 !important;
}

.checkbox-container input:checked ~ .toggle-mark:before {
    background: #fff !important;
}

.order-buttons .button-regular:hover:not(.disabled),
.order-buttons .place-order:hover:not(.disabled),
.order-buttons .replicate:not(.disabled) {
    margin-top: 15px;
}
.button-regular:hover:not(.disabled), .regular-button:hover:not(.disabled){
/*
    background: #B7E2E5!important;
    box-shadow: 0 1px 5px rgba(0,0,0,.15);
    color: #001F3D!important;
    border-color: #B7E2E5!important;
*/
	  background: #12BDC7!important;
    box-shadow: 0 1px 5px rgba(0,0,0,.15);
    color: #fff!important;
    border-color: #12BDC7!important;
}
.mealplan-heading-bx.main-page .button-regular:hover span, .mealplan-heading-bx.main-page .button-regular:hover span.title
{
	color: #fff;
}
.replicate {
    border-radius: 2px;
    background: #fff !important;
    border: 1px solid #f4f4f4 !important;
}

.step-holder .button-regular {
    margin-top: -7px;
}

.papa {
    /*overflow: hidden;*/
}

.day-menu {
    cursor: pointer;
    border-bottom: 1px solid #f9f9f9;
    cursor: pointer;
    height: auto;
    align-items: center;
    padding: 7px 30px 7px 30px;
    min-height: 85px;
}

.day-menu:last-child {
    border-bottom: 1px solid #f9f9f9;
}

.day-menu:hover {
    background: #fcfcfc;
    border-bottom: 1px solid #fefefe;
}
.inner-plan-details .place-order{
    margin-left: 0;
    margin-right: 0;
}
.day-menu .date {
    padding: 10px;
    background: #fcfcfc;
}

.filled .date {
    background: #e6f4ed;
}

.day-menu .date p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #B7E2E5 !important;
    text-align: center;
}

.filled .date p {
    color: #B7E2E5 !important;
    text-align: center;
}

.in-buffer .date p {
    color: #001F3D !important;
}

.day-menu .date p:first-child {
    font-size: 22px;
    font-weight: 800;
    line-height: 18px;
}

.selections {

}

.selections span {
    color: #bbbbbb;
    padding-right: 3px;
}

.tags li:after,
.selections span:after {
    content: ',';
    color: #bbbbbb;
}

.tags li:last-child:after,
.selections span:last-child:after {
    content: '';
}

.selections span.choosed {
    color: #B7E2E5;
}

/*meal plan*/
.dish-groups {
    margin: 1rem 0 0 0;
    padding-right: 90px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.tags {
    text-align: left;
    line-height: 14px;
}

.tags li {
    color: #001F3D;
    width: max-content;
    display: inline-block;
    padding-right: 5px;
    height: 15px;
    font-size: 13px !important;
    color: #999 !important;
}

.product div .tags li span {
    font-size: 13px !important;
    color: #999 !important;
}

.product div .tags li span:nth-child(2) {
    padding-left: 5px;
}

.dish-groups li {
    display: inline-block;
    font-size: 13px;
    letter-spacing: .1em;
    padding: 6px 16px;
    font-weight: 400;
    border-radius: 5px;
    margin-right: 0.5rem;
    cursor: pointer;
    text-transform: uppercase;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 5px;
    background: #fff;
    border: 1px solid #12bdc7;
    color: #12bdc7;
}

.dish-groups li.active,
.dish-groups li:hover {
    background-color: #12bdc7;
    color: #fff;
    border: 1px solid #12bdc7;
}

.products {
    margin: 0 0 10px 0;
    position: relative;
    width: 100%;
}

/*.products .product {
    position: relative;
    border: 1px solid #f4f4f4;
    width: 200px;
    min-height: 380px;
    padding: 10px;
    overflow: hidden;
    margin-right: 15px;
    margin-bottom: 5px;
    border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .02);
    background: #fff;
}*/
/*
.products .product .inner {
    height: 94%;
}*/

.products .product .inner.clickable, .clickable {
    cursor: pointer;
}

.day-calories {
    background: none !important;
    border-radius: 0px !important;
    text-transform: unset !important;
    position: relative;
    top: -2px;
    border: none !important;
    cursor: auto !important;
    color: #001F3D !important;
}

.day-calories b {
    color: #001F3D !important;
    font-weight: bold;
}

.products .product h3 {
    color: #001F3D;
    font-size: 16px;
    margin: 0;
    font-weight: bolder;
}

.editing {
    border-bottom: 4px solid #ccc;
}
.can-change.no-border {
    border-color: transparent !important;
    padding: 5px !important;
}
.dish-groups li.can-change:hover,
.dish-groups li.can-change {
    background: #fff;
    color: rgb(0,31,61);
    cursor: auto;
    text-transform: none;
	font-weight: normal;
    border: 1px solid #f8f7f7;
}

.dish-groups li.bg-gold {
    background: #001F3D !important;
    color: #fff;
    border: 1px solid #001F3D;
    position: absolute;
    right: 0;
}

.click-on {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}

.details {
/*    color: #B7E2E5;*/
    text-decoration: underline;
    cursor: pointer;
}

.details:hover {
    text-decoration: none;
}

.products .product .img-holder{

    height: 200px;
    background-position: center;
    background-size: cover;
    /*overflow: hidden;*/

}

.product-title,
.products .product .product-title {
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
    text-align: left;
    line-height: 20px;
    letter-spacing: .6px;
    margin-top: 0;
    margin-bottom: 5px;
}
.d-kal-info span
{
  font-size:13px;
}
.products .chosen .product-title {
    color: #ddd;
}

.gold {
    color: #B7e2e5;
}

.products .chosen .tags li {
    color: #fff;
}

.products .chosen:hover .product-title {
    color: #ddd;
}

.show-info {
    text-align: center;
    display: block;
    cursor: pointer;
    margin-bottom: -10px;
    padding-top: 5px;
    background: rgb(244,244,244);
    background: linear-gradient(0deg, rgba(244,244,244,1) 0%, rgba(244,244,244,1) 25%, rgba(255,255,255,1) 100%);
    opacity: .5;
    width: 100%;
    margin-left: -5%;
    color: #001f3d;
    text-decoration: underline;
    position: absolute;
    bottom: 10px;
}

.products .product .stars p {
    padding-top: 5px;
    margin-left: -5px;
}

.products .chosen .stars p .icon-star.green {
    color: #fff;
    margin: 0;
}

.icon-star.green {
    color: #12BDC7 !important;
}

.icon-star.green:before {
    content: "\e9d9" !important;
}

.dish-holder .day {
    margin-bottom: 30px;
}

.products .checkbox-container {
    top: 14px;
    right: 27px;
    z-index: 9;
}

.products .chosen {
    /*background-image: radial-gradient(circle,#36465f,#313c51,#2b3344,#252a37,#1e212b);*/
    /*background: #001F3D;*/    
    background: transparent;
    border: 2px solid #12bdc7;
}

.rdtDays table tbody tr td,
.rdtDays table thead tr .dow {
    padding: 3% 2%;
    text-align: center;
    font-size: 13px !important;
    color: #a99e8b !important;
}

.rdtDays table thead tr .dow {
    padding: 3%;
}

/*.hide-arrow-calendar:nth-child(2) .rdtDays table thead tr .dow{
    display: none;
}*/
.hide-arrow-calendar:nth-child(2) .rdtDays table thead tr th {
    border: none;
}

.row .calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td[selector=one-more] {
    color: #001F3D !important;
}

.row .calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td[selector=one-more],
.calendar-holder .hide-arrow-calendar .rdtStatic .rdtPicker .rdtDays table tbody tr .rdtDay {
    background: #001F3D !important;
    border: 1px solid #616f87;
}

.calendar-holder .hide-arrow-calendar .rdtStatic .rdtPicker .rdtDays table tbody tr .rdtDay {
    color: #a0a9bb !important;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table thead tr th {
    background: #001F3D !important;
    border: none !important;
    color: #fff !important;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table thead tr .dow {
    color: #e3ece8 !important;
    font-weight: bold;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody {
    box-shadow: 0 1px 5px rgb(42, 54, 75);
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td{
    border-collapse: collapse;
    height: 32px;
    width: 32px;
}
.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.delivery-day {
    background-color: #4f5d77 !important;
    color: #b4d2c4 !important;
    position: relative;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid #616f87;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.delivery-day:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 3px;
    top: 3px;
    height: 4px;
    background: #8995ac;
    border-radius: 50%;
    width: 4px;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.delivery-day[selector=one] {
    background-color: #001F3D !important;
    color: #fff !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
    border: 1px solid #001F3D;
    font-weight: bold;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.delivery-day.skipped-day[selector=one] {
    background-color: #001F3D !important;
    border: 1px solid #001F3D;
    color: #937167 !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td[selector=one-more] {
    background: #fff !important;
    color: #f0f0f0 !important;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td[selector=past] {
    background: #585f6d !important;
    color: #8787a3 !important;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td[selector=one-more]::after {
    display: none;
}

.calendar-holder .bottom-container .button-regular {
    background: transparent !important;
    border: 1px solid #B7E2E5 !important;
/*    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);*/
}

.calendar-holder .bottom-container .button-regular span {
    color: #B7E2E5;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr .rdtOld.delivery-day,
.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr .rdtNew.delivery-day {
    background: #fff !important;
    color: #f0f0f0 !important;
}

.rdtDays table thead tr th {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background: #001F3D !important;
    color: #fff !important;
    font-family: Lato, Helvetica, Arial, sans-serif !important;
    border-bottom: 1px solid #414d61;
}

.hide-arrow-calendar .rdtNext, .hide-arrow-calendar .rdtPrev, .hide-arrow-calendar .rdtSwitch {
    background: #fff;
    color: #001F3D !important;
    font-weight: 600;
    font-size: 18px;

    letter-spacing: .05em;
}

.hide-arrow-calendar .rdtSwitch {
}

.hide-arrow-calendar .rdtStatic .rdtPicker .rdtDays table tbody tr .rdtDay {
    border: 1px solid #f7f7f7;
    color: #343f54 !important;
    background: #fff !important;
}
.search-profile-main-cal{
    outline: none !important;
}
.search-profile-main-cal .rdtSwitch {
    font-weight: 700 !important;
    text-transform: uppercase !important;
}

.bg-f {
    background: #fff !important;
}

.bg-1-1 {
    background-image: url('../assets/bg-1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.bg-1-2 {
    /*background-image: url('../assets/gold-bg.png');*/
    background-repeat: repeat-x;
    background-position: center;
    background-size: 125px;
}

.summary-product-holder {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
}

.summary-product-holder .s-product-count {
    flex: 1 1;
}

.summary-product-holder .s-product-title {
    font-size: 16px;
    line-height: 40px;
}

.summary-product-holder p {
    line-height: 40px;
}

.summary-product-holder .s-product-count .s-term,
.summary-product-holder .s-product-count .s-count {
    padding: 0 20px;
}

.summary-product-holder .s-product-count .s-count:before {
    content: 'x';
}

.summary-product-holder .s-product-count .s-term {
    font-size: 14px;
    color: #8e9b94;
}

.main-page .button-regular {
    border-color: transparent;
    background: #fff;
    width: 50%;
    border-radius: 2px;
    height: 100%;
}

.main-page .button-regular:hover {
    /*background: #c4e269;*/
    background: #B7E2E5;
    border: 1px solid #B7E2E5;
}

.main-page .button-regular:hover span.title,
.main-page .button-regular:hover span {
    /*color: #5b6741;*/
    color: #fff;
}

.main-page .button-regular span {
    color: #cee0b5;
    /*color: #699008;*/
}

.main-page .button-regular span.title {
    line-height: 21px;
    min-width: 93%;
    padding: 0 10%;
    text-transform: uppercase;
    letter-spacing: .7px;
    font-weight: 400;
    font-size: .95rem;
    color: #001F3D;
}

.logo-holder a {
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 0 auto;
    background: url('../assets/Basiligo-Logo.png') center no-repeat;
    /*background: url('../assets/b-logo.png') 45px center no-repeat;*/
    background-size: 90px;
}

.logo-holder a .brand-name {
    display: inline-block;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: .2em;
    color: #6b6b6b;
    font-weight: 800;

    padding-top: 9px;
    background: #fff;
    padding: 13px 0 5px 5px;
    width: 100%;
}

/****/

.list-holder .list .list-item {
    padding: 1.1rem 0;
    border-left: 3px solid #fff;
    border-bottom: 1px solid #e4ebee;
    border-top: 1px solid #fff;
    text-decoration: none;
    transition: all 0.2s;
}

.map {
    height: 100%;
    background: #fff;
    position: relative;
}

.sidebar {
    min-width: 80px;
    width: 80px;
}

.transition {
    transition: all .5s;
}

.page-header * {
    z-index: 12;
}

.page-header {
    height: 7vh;
    min-height: 63px;
    background: #fff;
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 40;
}

/* Sidebar nav border */
.b-rounded-blue {
    border: 1px solid #e4ebee;
    border-radius: 2px;
}

.b-rounded-gray {
    border-radius: .1rem;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
}

.b-shadowed-gray {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
}

.b-shadowed-gray input {
    padding-left: 9px;
    padding-right: 9px;
    border: none;
    height: 2rem;
}

input.b-shadowed-gray,
.b-shadowed-gray div div select {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.b-rounded-green {
    border: 1px solid #cadccc !important;
    border-radius: .3rem;
}

.b-rounded-red {
    border: 1px solid #ffb598 !important;
    border-radius: .3rem;
}

.b-rounded-blue.active,
.nav-item .b-rounded-blue:hover {
    border: 1px solid #d8e4ea;
}

.page-header .nav-item {
    margin: 0 3%;
}

.page-header > div {
    height: 100%;
}

.page-header input {
    font-weight: lighter;
}

/* Sidebar shadow */
.parent-input input,
.input-location input,
.shadowed {
    -webkit-box-shadow: 1px 1px 1px 0 rgba(187, 205, 194, 0.2);
    -moz-box-shadow: 1px 1px 1px 0 rgba(187, 205, 194, 0.2);
    box-shadow: 1px 1px 1px 0 rgba(187, 205, 194, 0.2);
}

.input-location input:hover,
.input-location input:active,
.shadowed:hover,
.shadowed.active {
    -webkit-box-shadow: 1px 1px 1px 0 rgba(152, 180, 163, 0.25);
    -moz-box-shadow: 1px 1px 1px 0 rgba(152, 180, 163, 0.25);
    box-shadow: 1px 1px 1px 0 rgba(152, 180, 163, 0.25);
}

.nav-item-active {
    background: #fff;
}

/* Header =============================================== */
.navbar-nav {
    justify-content: space-around;
    height: 100%;
    min-height: 63px;
}

.burger-content li {
    padding-left: 0;
}

.navbar-nav a {
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-size: 15px;
    /*text-transform: uppercase;*/
    text-align: center;
}

.navbar-nav a:hover {
    color: #B7E2E5;
}

/* Avatar style ==============================================*/
.full-bg-holder {
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
}

.full-backgr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-size: cover;
}

.rounded-block {
    border: 1px solid #ececec;
}

.rounded-block,
.rounded-block-nb {
    border-radius: .3rem;
    overflow: hidden;
}

/* Message counter =============================================*/
.count {
    position: absolute;
    top: -8px;
    right: -8px;
}

.logo-holder {
    position: relative;
}

.logo-image {
    max-width: 110px;
    margin-top: 20px;
}

/* Checkboxes ======================= */
.address-page .checkbox-container {
    position: relative;
    width: 100%;
    margin-top: -5px;
}

.checkbox-container {
    display: block;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 25px;
}

.checkbox-container input {
    position: absolute;
    display: none;
    cursor: pointer;
}

.checkmark,
.radiomark {
    position: absolute;
    height: 20px;
    width: 20px;
    min-height: 20px;
    flex: 0 0 20px;
    border: 1px solid #cccacabd;
    margin: 0.2rem;
    top: 0;
    right: 3px;
    background: #fff;
}

.checkmark:after,
.radiomark:after {
    content: "";
    position: absolute;
    display: none;
}

.radiomark {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.checkbox-container input:checked ~ .checkmark:after,
.checkbox-container input:checked ~ .radiomark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 5px;
    top: -2px;
    width: 8px;
    height: 18px;
    border: solid #B7E2E5;
    border-width: 0px 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container .radiomark:after {
    left: 3px;
    top: 3px;
    width: 8px;
    height: 8px;
    background: #b1ccb5;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.radio-label {
    display: block;
}

.radio-label-inline {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
}

/* Styled select ==========================*/
textarea,
select:focus {
    outline: none;
}

.styled-select select {
    background: transparent;
    border: none;
    font-size: 16px;
    height: 2rem;
    padding: 0px 15px;
    width: 100%;
    background: #fff;
    font-weight: 600;
}

.cal-holder input {
    width: 100%;
    background: #fff;
    border: none;
    padding: 5px;
    font-weight: 600;
}

.cal-holder .absolute-cal .rdtDays table thead tr th {
    padding-top: 5px;
    padding-bottom: 5px;
    background: #001F3D;
    color: #B7E2E5;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
}
.rdtDays table tbody tr .rdtDay:hover,
.rdtDays table tbody tr .rdtDay.rdtActive {
    background: #001F3D !important;
    color: #fff !important;
}

.cal-holder .absolute-cal .rdtOpen {
    bottom: 67px;
    right: 21px;

}

.check-bas {
    display: block;
    position: absolute;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 25px;
    top: 0;
    right: 15px;
    height: 30px;
    width: 40px;
}

.list-holder .list .colorTitle {
    color: #6a6664;
    font-size: 1.1rem;
}

/* Font-size =========================== */
.parent-input input {
    font-size: 15px;
}

.letter-xs {
    font-size: 0.8rem !important;
}

.letter-xxs {
    font-size: 0.7rem !important;
}

.form-input {
    position: relative;
}


::placeholder{
    color: #001F3D;
    opacity: 1;
}
.sign-up-form input::placeholder,
.sign-up-form .form-input input,
.sign-up-form .sign-up-button,
.sign-up-button-google,
.sign-up-button-facebook,
.letter-sm {
    font-size: 0.9rem !important;
}

.letter-normal {
    font-size: 1rem !important;
}

.letter-sxnormal {
    font-size: 1.04rem !important;
}

.letter-xnormal {
    font-size: 1.3rem !important;
}

.letter-xxx {
    font-size: 1.5rem !important;
}

.booking-calendar thead .rdtPrev span,
.booking-calendar thead .rdtNext span,
.map-overlay .map-overlay-inner,
.letter-md {
    font-size: 1.5rem !important;
}

.letter-xmd {
    font-size: 1.8rem !important;
}

.search-title .icon-write,
.letter-lg {
    font-size: 2rem;
}

.letter-xlg {
    font-size: 2.5rem !important;
}

.letter-xl {
    font-size: 3rem;
}

.font-weight-medium {
    font-weight: 500;
}

.first-block {
    width: 100%;
}

.height-papa {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.buttons-holder {
    align-items: stretch;
}

.bottom-buttons-holder {
    margin-top: 0;
    padding: 20px 0;
}
.bottom-buttons-holder select{
    height: 48px;
}
.bottom-buttons-holder .button-regular {
    width: 100%;
    margin: 0;
}
.bottom-buttons-holder .form-input input{
    height: 48px;
}
.bottom-buttons-holder .button-regular a,
.bottom-buttons-holder .button-regular a:hover {
    color: #001F3D;
    width: 100%;
    display: inline-block;
    letter-spacing: .4px;
}

/* Colors ============================== */
.white-color {
    color: #fff;
}

.text-light {
    color: #e8e4e2;
}

.green-color {
    color: #12BDC7;
}
.sign-up-form .login-text a{
    color: #001F3D;
    font-weight: 500;  
    font-size: 15px;
}

thead .rdtPrev span,
thead .rdtNext span {
    color: #fff!important;
    font-size: 32px;
    line-height: 38px;
    cursor: pointer;
}

.name,
.price,
.dark-text {
    color: #6c6664;
}

.light-dark-text {
    color: #b1b1b1;
}

.sign-up-form {
    background: #fff;
    /* width: 25%; */
    min-width: 350px;
    max-width: 500px;
    height: max-content;
    padding: 1.7rem 0;
}

textarea.standart-input,
span.standart-input,
input.standart-input,
input.standart-input:focus {
    width: 100%;
    padding: 5px 15px;
    outline: 0;
    resize: none;
}

.sign-up-form input::placeholder {
    font-weight: lighter;
}

.sign-up-form .checkbox-container {
    padding-right: 30px;
}

.sign-up-form .sign-up-button,
.sign-up-button-google,
.sign-up-button-facebook {
    background: #001F3D;
    border-radius: 10px;
    height: 3.4rem;
    cursor: pointer;
    line-height: 1;
    width: 100%;
    color: #fff;
    border: none;
    outline: 0;
}

.sign-up-button-google .icon-write,
.sign-up-button-facebook .icon-write {
    position: absolute;
    left: 1rem;
}

.sign-up-form .sign-up-button-google {
    background: linear-gradient(#d57669, #d68379);
}

.sign-up-form .sign-up-button-google:active {
    background: linear-gradient(#d68379, #d57669);
}

.sign-up-form .sign-up-button-facebook {
    background: linear-gradient(#788dc5, #8496ca);
}

.sign-up-form .sign-up-button-facebook:active {
    background: linear-gradient(#8496ca, #788dc5);
}

.progile-general .sign-up-button input,
.sign-up-form .sign-up-button input {
    color: #fff;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
}

.sign-up-form .login-text a:hover {
    text-decoration: none;
}

.sign-up-form .form-input input {
    box-shadow: 1px 1px 1px 0 rgba(187, 205, 194, .2);
    border: 1px solid #D0D0D0;
    line-height: 1.8;
    font-weight: 600;
    border-radius: 10px;
    min-height: 55px;
    font-size: 15px;
}
.sign-up-form .form-input input::placeholder{
    opacity: 0.5;
}
.sign-up-form .form-input input:hover {
    box-shadow: 1px 1px 1px 0 rgba(187, 205, 194, .25);
}
.sign-up-form .form {
    min-height: 600px;
    min-width: 500px;
  }
  .sign-form-container {
    height: 450px;
  }
.form-input input {
    height: 2rem;
    padding: 0 15px;
    font-size: .8rem;
}

.form-input span.has-error {
    font-size: 0.8rem;
    position: absolute;
    right: 0;
    top: 100%;
    color: #d57669;
}

.list-and-map .search-title .search-icon {
    transform: rotateY(180deg);
    font-size: 1.7rem;
}

.settings-ic {
    font-size: 1.15rem;
}

.map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(241, 241, 241, 0.6);
}

.map-overlay .map-overlay-inner {
    font-weight: bold;
    color: #9eb2a2;
    letter-spacing: 1.5px;
}

.margin-top-2rem {
    margin-top: 2rem;
}

.padding-right-2rem {
    padding-right: 2rem !important;
}

.xyz-fin {
    opacity: 1;
    visibility: visible;
    -moz-transition: opacity 300ms linear, visibility 0s linear;
    -o-transition: opacity 300ms linear, visibility 0s linear;
    -webkit-transition: opacity 300ms linear, visibility 0s linear;
    transition: opacity 300ms linear, visibility 0s linear;
    flex: 1 1;
}

.xyz {
    opacity: 0;
    visibility: hidden;
    -moz-transition: opacity 300ms linear, visibility 0s linear 300ms;
    -o-transition: opacity 300ms linear, visibility 0s linear 300ms;
    -webkit-transition: opacity 300ms linear, visibility 0s linear;
    -webkit-transition-delay: 0s, 300ms;
    transition: opacity 300ms linear, visibility 0s linear 300ms;
}

.restore-form {
    margin-top: -5rem;
}

.forgot-form-eye .form-input {
    position: relative;

}

.forgot-form-eye .icon-write {
    position: absolute;
    right: 0.1rem;
    top: 0.2rem;
}

.absolute {
    position: absolute;
}

.white-back {
    background: #fff;
}

.light-gray-back {
    background: #fbfbfb;
}

.m-page .checkmark {
    border: none;
    background: #78c951;
    margin-top: 0.1rem;
}

.m-page .checkmark:after {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    left: 4px;
    top: 0;
    width: 7px;
    height: 12px;
}

a,
a:hover {
    text-decoration: none;
}

.boarded-around {
    border: 1px solid #cccaca;
    border-radius: 0.15rem;
    padding: 0.1rem 0.3rem;
}

.list-item.link-item:hover {
    border-left: 3px solid #d3ded9;
    border-top: 1px solid #d3ded9;
    border-bottom: 1px solid #d3ded9;
}

.active-link-item {
    border-left: 3px solid #8dbfaa !important;
    border-top: 1px solid #b1ccb5 !important;
    border-bottom: 1px solid #b1ccb5 !important;
}

.like-icons .icon-write {
    margin-left: 0.3rem;
    margin-right: 0.2rem;
}

ul {
    list-style-type: none;
}

.top-params-filters {
    position: relative;
}

.top-params-filters .filter-back {
    position: fixed;
    background: #eee;
    opacity: 0.5;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.top-params li a {
    text-decoration: none;
}

.active-pin {
    border: 1px solid #8dbfaa;
}

.chat-holder-part .neighbors-map-holder {
    padding-top: 70%;
    margin-top: 1rem;
    position: relative;
}

.chat-holder-part .neighbors-map-holder .neighbors-map-inner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.simple-calendar table,
.container-calendar table {
    width: 100%;
    user-select: none;
}

.simple-calendar table tbody,
.container-calendar table tbody {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .02);
}

.calendar-day-not-this-month {
    color: #fff;
    background: #fff;
}

.simple-calendar .calendar-selected-day,
.calendar-selected-day {
    background: #ffab8a;
    color: #fff !important;
}

.calendar-active-day {
    background: #8cd8ad;
    color: #fff !important;
    font-weight: 500;
}

.calendar-half-active-day {
    background: #e2e6e4;
    color: #fff !important;
    font-weight: 500;
}

.calendar-in-range-day {
    background: #fbbfa7;
    color: #fff !important;
    font-weight: 500;
}

.cal-absolute .modal-calendar {
    position: absolute;
    z-index: 1;
    background: #fff;
    border-radius: 0.3rem;
    border: 1px solid #e4ebee;
    box-shadow: 1px 1px 1px 0 #bbcdc2;
}

/* Contact Step1 Window */
.contact-modal-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(206, 206, 206, 0.5);
    z-index: 13;
}

.contact-modal {
    width: 60%;
    min-width: 35rem;
    height: 42rem;
    max-height: 88.5%;
    background: #fff;
    border-radius: 0.3rem;
    overflow: hidden;
}

.chat-holder-part .contact-modal-header {
    background: #fff;
    border-bottom: 1px solid #e4ebee;
}

.chat-holder-part a:hover {
    text-decoration: none;
    color: #8c8583;
}

.contact-modal-header {
    min-height: 2.8rem;
    background: #f1f1f1;
}

.contact-modal-tabs > div {
    width: 200px;
}

.contact-modal-main {
    height: max-content;
    overflow-y: scroll;
}

.contact-modal-main > .row .checkmark {
    position: relative;
}

.contact-modal-main .full-bg-holder {
    margin: 0 auto;
}

.contact-modal-main .full-backgr {
    -webkit-border-radius: .3rem;
    -moz-border-radius: .3rem;
    border-radius: .3rem;
}

.contact-modal textarea {
    resize: none;
}

.modal-header-styled {

}

.modal-header-styled .title-block p .m-title {
    font-size: 1.2rem;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;

    width: 70%;
    display: inline-block;
}

.modal-header-styled .title-holder .title-block p {
    text-align: center;
    margin: 0;
}

.modal-header-styled .title-block p .ar-golr-l {
/*    background-image: url('../assets/Slider-Arrow-Gold-inverted.png');*/
    margin-right: 10px;
}

.modal-header-styled .title-block p .ar-golr-r {
/*    background-image: url('../assets/Slider-Arrow-Gold.png');*/
    margin-left: 10px;
}

.modal-header-styled .title-block p .ar-golr-l,
.modal-header-styled .title-block p .ar-golr-r {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    width: 10%;
    height: 15px;
}

.rdtDay {
    width: 14.285714%;
}

.pointer {
    cursor: pointer;
    outline: none!important;
}

.chat-holder-part {
    position: relative;
    overflow: hidden !important;
    transition: all 0.1s;
    width: 100%;
}

.chat-holder-part.active-part {
    right: 0;
    border-left: 1px solid #f3f3f3;
}
.wrap-progress-page .progress-page{
    flex: 1 1;
}
.wrap-progress-page{
    display: flex;
    flex-direction: column;
}
.wrap-progress-page .togle-dishes-menu{
    padding-top: 3px;
}
.border-bottom-light-dark {
    border-bottom: 1px solid #e4ebee;
}

.calendar-services-list {
    margin: 0;
    padding: 0;
}

.calendar-services-list li {
    border: 1px solid #b6aba8;
    border-radius: .3rem;
    background: #fff;
}

.parent-input input {
    margin-bottom: 0 !important;
}

.parent-input input,
.input-location input {
    padding: .4rem .75rem;
    outline: 0;
    border: 1px solid #e4ebee;
    border-radius: .3rem;
    margin-bottom: 1.5rem;
    width: 100%;
}

.flex-grow {
    flex: 1 0 auto;
}

.icon-write {
    user-select: none;
}

.link-item .icon-write {
    transition: all 0.2s;
}

.parent-input input {
    padding: .4rem .75rem;
}

.header-close-icon {
    margin-top: -0.2rem;
}

.m-page-bottom-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
}

.summator span {
    line-height: 0;
}

.calendar-services-list li {
    border: none;
    padding: 0.1rem;
}

.calendar-in-range-day,
.calendar-selected-day {
    background: none;
}

.calendar-selected-day .day-inner .date-number,
.calendar-in-range-day .day-inner .date-number {
    background: #f7c8b5;
    color: #fff;
}

.text-underline {
    text-decoration: underline;
}

.calendar-between-day {
    background: #baf3c3;
    color: #747474 !important;
    font-weight: 500;
}

.rdtDays table tbody tr .rdtDay {
    border: 1px solid #f7f7f7;
    color: #343f54 !important;
}

.search-profile-main-cal.absolute-cal .rdtOpen {
    position: absolute;
    bottom: 48px !important;
    right: 0 !important;
    width: 19rem !important;
    background: #fff;
    border: 1px solid #e4ebee !important;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
}

.search-profile-main-cal.absolute-cal .rdtOpen .rdtPicker {
    background: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
}

.down-calendar-show .search-profile-main-cal.absolute-cal .rdtOpen {
    top: 2.2rem !important;
    z-index: 3;
}

.down-calendar-show:focus,
.down-calendar-show * {
    z-index: 3;
    outline: none;
}

.prof-cal-holder .absolute-cal .rdtOpen {
    bottom: -100% !important;
    border: none;
}

.search-profile-main-cal thead tr:first-child {
    background: #f1f1f1;
    font-size: .8rem;
    line-height: 1.6;
    color: #8c8583;
    border-bottom: 1px solid #3d485d;
}

.absolute-cal .selected {
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
    color: #001F3D;;
}

.rdtDays table tbody tr .rdtDay.rdtOld,
.rdtDays table tbody tr .rdtDay.rdtNew {
    color: #9a9b9c !important
}

.contact-modal.short-modal {
    width: 46%;
    height: max-content;
}

.drop-zone-image {
    background: center no-repeat;
    background-size: cover;
    min-height: 6rem;
}

.h-max-content {
    height: max-content !important;
}

.calendar-td-back {
    background: #8cd8ad !important;
}

.green-border {
    border: 1px solid #8dbfaa;
}

.fullscreen.fullscreen-enabled {
    overflow-y: auto;
}

.toggle-mark {
    display: inline-block;
    width: 37px;
    min-width: 37px;
    height: 22px;
    border-radius: 11px;
    background: #fff;
    border: 1px solid #cacaca;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.toggle-mark::before {
    content: '';
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background: #f4b29a;
    left: 2px;
    top: 2px;
    position: absolute;
    display: block;
    transition: all 0.2s ease-in-out;
}

.checkbox-container input:checked ~ .toggle-mark {
    background: #8cd8ad;
}

.checkbox-container input:checked ~ .toggle-mark::before {
    left: 17px;
    background: #fff;
}

.dropzone-part {
    min-height: max-content;
}

.service-prices {
    border-bottom: 1px solid #e4ebee;
}

.service-prices:last-child {
    border: none;
}

.check-block {
    border: 1px solid rgb(241, 238, 234);
    position: relative;
    transition: all 0.3s;
    text-align: center;
    min-height: 2rem;
    padding: 0 5px;
    width: 80px;
}

.plan-selection-header .step-title {
    height: 50px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    line-height: 20px;
}
body .Select--multi .Select-value{
    display: inline-flex;
}
body .Select--multi .Select-value-icon{
    display: flex;
    align-items: center;
}
.programm-titles .checkbox-container {
    display: block;
    margin: 0 auto !important;
    width: auto;
    padding: 0 10px;
}

.programm-titles .checkbox-container .check-block {
    display: inline-block !important;
    width: 100%;
}

.selected-plan .checkbox-container input:checked ~ .check-block {
    border-color: #f1eeea;
}

.check-block .checkmark {
    top: -6px;
    right: -5px;
    border: 1px solid #e8e8e8bd;
}

.check-block .checkmark:after {
    display: block;
    opacity: 0;
    transition: all 0.3s;
}

.checkbox-container input:checked ~ .check-block .checkmark:after {
    opacity: 1;
}

.checkbox-container input:checked ~ .check-block {
    border-color: #c1dacf;
}

.m-page-burger {
    width: 31px;
    min-width: 31px;
    height: max-content;
    z-index: 12;
}

.m-page-burger .m-page-burger-line {
    display: block;
    position: relative;
    height: 2px;
    margin-bottom: 9px;
    width: 100%;
    background: #001F3D;
    z-index: 12;
    transition: all 0.3s;
    transform: rotate(0deg);
    top: 0;
}

.m-page-burger .m-page-burger-line:nth-child(3) {
    margin-bottom: 0;
}

.m-page-burger.active-burger .m-page-burger-line:nth-child(1) {
    transform: rotate(-45deg);
    top: 10px;
}

.m-page-burger.active-burger .m-page-burger-line:nth-child(2) {
    width: 0;
}

.m-page-burger.active-burger .m-page-burger-line:nth-child(3) {
    transform: rotate(45deg);
    top: -12px;
}

.hidden-lg-up {
    display: none !important;
}

.m-page-menu-modal {
    position: fixed;
    top: 10vh;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 999;
}

.m-page-menu-modal .scroll-auto {
    overflow-y: auto;
    height: 100%;
    background-color: #fff;
}

.m-page-menu-modal:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    height: 200px;
    background-color: #fff;
}

.m-page-menu-modal a:hover,
.m-page-menu-modal a:focus,
.m-page-menu-modal a:active {
    color: #cf6f10;
}

.profile-icon-subs {
    font-size: 2rem;
    z-index: 13;
    color: #313b4e;
}
.profile-icon-subs.h-profile-ic:before
{
	content: "" !important;
	background: url('../assets/dashboard/img/user-ic.svg') no-repeat scroll 0 0;
	width: 32px;
	height: 32px;
	display: inline-block;
}
.burger-content {
    position: absolute;
    top: 0.8vh;
    left: -7.5rem;
    width: 12rem;
    padding-top: 3.5rem;
    background: #fff;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .15);
    padding-left: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
}

.burger-content .nav-item {
    margin: 0 1rem;
}

.burger-content .nav-link,
.burger-content .nav-link:hover {
    padding: 5px 0;
    font-size: 13px;
}

.regular-button {
    border-radius: .3rem;
    border: 1px solid #001F3D;
    cursor: pointer;
    transition: all 0.2s;
}

.g-toggle {
    background: #fff;
    border: 1px solid #e4ebee;
    border-radius: .3rem;
    display: inline-block;
    height: 2rem;
    width: 100%;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
}

.g-toggle .g-nop {
    font-size: .8rem;
    width: 50%;
    display: inline-block;
    text-align: center;
}

.g-toggle .g-mark {
    position: absolute;
    width: 50%;
    height: 100%;
    background: #B7E2E5;
    box-shadow: 0 0 0 1px #B7E2E5;
    opacity: .5;
    left: 50%;
    border-radius: .3rem;
    transition: all .3s;
}

.g-toggle input {
    display: none;
}

.g-toggle input:checked ~ .g-mark {
    left: 0;
}

.paper-button {
    overflow: hidden;
    position: relative;
}

.paper-button:active::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    opacity: 0.1;
    background: #fff;
    animation: paperer 0.7s linear 0s 1;
    z-index: 1;
    pointer-events: none;
}

.calendar-input .rdtStatic.rdtOpen {
    /*width: 100%!important;*/
    /*min-width: 11rem;*/
    outline: none !important;
}

/* blog */
.cookie-message.container-fluid,
.blog-heading {
/*
    background-image: url('../assets/hero-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
*/
	background-color: #231F20;
    min-height: 80px;
    align-items: center;
    position: relative;
}
.blog-heading::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 80px;
    height: 100%;
    background-color: #001F3D;
    opacity: .95;
}

.blog-heading.single {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.blog-heading.single::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: radial-gradient(circle, #36465f, #313c51, #2b3344, #252a37, #1e212b);
    opacity: .8;
}

.blog-heading.single h1 {
    font-size: 1.8rem;
}

.min-60-vh {
    min-height: 60vh;
}

.article-body * {
    font-size: 1rem !important;
    font-family: Lato, Helvetica, Arial, sans-serif !important;
}

.article-body img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 10px;
}

.blog-heading-menu .blog-heading.menu-heading:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .95;
}

.blog-heading-menu .blog-heading h1,
.blog-heading-menu .blog-heading.menu-heading h1 {
    font-size: 1.5rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    letter-spacing: .1em;
    margin: 8px 0;
}
.blog-page-post .title-holder .blog{
    min-height: 30vh;
}
.title-holder .blog {
    height: 100%;
    padding: 35px 0;
    min-height: 80px;
}
.horizontal-scrollbar{
    overflow-x: auto;
    overflow-y: hidden;
    border-right: none;
    border-left: none;
}
.vertical-scrollbar{
    overflow-y: auto;
    overflow-x: hidden;
}
.title-holder .title-block p span {
    font-size: .9rem;
    letter-spacing: 2px;
}
.title-block.main-title{
    margin-bottom: 20px;
    min-height: 119px;
}
.blog-heading-menu .blog-heading.menu-heading h1
.blog-heading .title-block {
    height: 100%;
}

.title-holder .support h1,
.title-holder .blog h1 {
    font-size: 1.5rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    letter-spacing: .1em;
    margin: 8px 0;
    line-height: 1;
}

.title-holder .blog h1,
.title-holder .blog p span {
    color: #efefef;
}

.masonry-wrap,
.discussions-holder {
    margin-top: 1rem;
}
.discussions-holder {
    border: 2px solid black;
    border-color: transparent;
    top: 12px!important;
}

.discussion {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    background: #fff;
    margin-bottom: 1px;
    overflow: hidden;
}

.article {
    width: 49%;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    float: left;
    margin: 0 5px 5px 0;
    background: #fff;
    overflow: hidden;
    margin-bottom: 3px;
}

.discussion:hover,
.article:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}

.bg-gray-blog {
    background: #f2f2f2;
    min-height: calc(100vh - 62px);

}
.modal-back-layer .button-regular{
    max-width: 250px;
}
.fixed-footer{
    position: fixed;
    width: 100%;
}
.cupons-holder {
    width: 95%;
    margin: 0 auto;
}

.support-holder {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px 0px;
}

.about {
    width: 70%;
}

.about-holder .single-blog-page {
    padding: 25px !important;
}

.blog-page {
    padding: 0;
}

.back-holder {
    border-radius: 2px;
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .05);
    padding: 9px 15px;
    margin: 15px 1% 0 1%;
    /*display: none;*/
}

.single-blog-wrapper .back-holder {
    margin: 15px 0 0;
}

.back-holder .back-link span,
.back-holder .back-link,
.back-holder .back-link span:hover,
.back-holder .back-link:hover {
    margin-top: 0;
}

.blog-filters {
    padding: 0 35px 0 15px;
    background: #f2f2f2;
    position: sticky;
    top: 0px;
    z-index: 12;
}

.single-blog-wrapper .sticky-back {
    top: 0;
}

.sticky-back {
    position: sticky;
    top: 60px;
}

.blog-filters .dish-groups li.active {
    background-color: #333e53;
    color: #fff;
    border: 1px solid #465261;
}

.blog-filters .dish-groups li {
    border: 1px solid #4f5861;
}

.category-holder {
    margin-top: 12px;
    position: sticky;
    top: 70px;
    z-index: 12;
}

.article .word-content {
    padding: 25px;
}

.article .word-content .article-title {

    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: .06em;
    line-height: 1.3;
    color: #59616e;
    word-wrap: break-word;
}

.article .word-content .article-desc {
    letter-spacing: 0.2px
}

.article img {
    width: 100%;
    min-height: 200px;
}

.article .categoryes span {
    color: #999;
    padding-right: 5px;
}

.article .categoryes span:after {
    content: ',';
}

.article .categoryes span:last-child:after {
    content: '';
}

.support-holder .title {
    margin-top: 28px;
    font-weight: 700;
    font-size: 1rem;
    color: #4e5861;
    letter-spacing: .3px;
}

.category {
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    margin-bottom: 1px;
    cursor: pointer;
}

.category:hover {
    border-radius: 3px;
    background: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
}

.category.active {
    background: #354158;
    box-shadow: 0 2px 3px rgba(255, 255, 255, .15);
}

.category.active p {
    color: #fff !important;
}

.category .category-title {
    margin-bottom: 0;
    padding: 10px 0;
    text-align: left;
}

.categoryes {
    margin-top: 1rem;
    color: #B7E2E5;
}

span.has-error {
    font-size: .8rem;
    position: absolute;
    right: 0;
    bottom: -20px;
    color: #d57669;
}

.bottom-row {
    margin: 25px 0 0;
    padding: 0 5%;
    background-image: linear-gradient(to right bottom, #36465f, #313c51, #2b3344, #252a37, #1e212b);
    /* background-image: linear-gradient(to right bottom, #3a6659, #427364, #4a816f, #528e7a, #5b9c85); */
    /* background-image: linear-gradient(to right bottom, #B7E2E5, #84a797, #77a391, #69a08b, #5b9c85); */
    background: #fbfaf9;
}

.req {
    font-weight: 600;
}

.calendar-holder .bottom-container .req {
    color: #fff !important;
    font-weight: 400;
}

.bottom-buttons-holder .check-bas {
    right: 0;
    margin-left: 15px;
}

.back {
    padding-right: 10px;
    color: #001F3D;
    font-size: 16px;
    line-height: 22px;
}

.back,
.back-link,
.back:hover,
.back-link:hover {
    margin-top: 18px;
    font-size: 1rem;
    letter-spacing: .3px;
    display: inline-block;
    line-height: 1rem;
    color: #595959;
}

.back {
    margin-top: 15px;
}

.pref-page .multiselect:first-child {
    padding-top: 1.3rem;
}
.pref-page .multiselect
.form-line div .light-dark-text {
    margin-bottom: 5px !important;
    color: #929292;
    letter-spacing: .3px;
}

.form-line {
    margin-top: 0 !important;
}

.form-line:first-child {
    margin-top: 15px !important;
}

.form-line div .regular-bold {
    margin-bottom: 5px !important;
}

.form-line div h5 {
    margin: .5rem 0;
}

.like-h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .075em;

}

.disabled-before {
    position: relative;
}

.disabled-before:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f5f5f5;
    opacity: .1;
}

.main-page .disabled-before:hover {
    border-color: transparent;
    background: #fff !important;
    border: 1px solid #eef2ee !important;
    cursor: inherit;
}

.place-order,
.next,
.place-order:hover,
.next:hover {
    background: #001F3D !important;
    border: none;
    border-radius: 2px !important;
    color: #B7E2E5 !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
    border: none !important;
}

.place-order.paper-bc {
    margin-top: 15px;
    padding: 15px 0;
    line-height: 1!important;
}

.place-order:hover,
.next:hover {
    background: #B7E2E5 !important;
}
.paper-bc{
    text-align: center;
}
.next:hover,
.place-order.paper-bc:hover,
.next:hover span {
    color: #001F3D !important;
}

.progress-track:hover span {
    color: #001F3D !important;
}

.next span,
.next span:hover {
    border-radius: 2px !important;
    color: #B7E2E5;
}

.disabled,
.prg-button,
.button-regular.disabled {
    background: #fff !important;
    border-color: #cacaca !important;
    color: #a2a2a2!important;
}
.disabled span{
    color: #a2a2a2;
}
.disabled:hover span{
    color: #001F3D;
}
.place-order.disabled {
    background: #001F3D !important;
    border: none;
    border-radius: 2px !important;
    color: #001F3D !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
    border: none !important;
}

.main-page .button-regular.disabled-before span {
    color: #c3c3c3 !important;
}

.main-page .button-regular.disabled-before:hover span, .main-page .button-regular.disabled-before:hover span.title {
    color: #c3c3c3 !important;
}

.b-rounded-blue.styled-select {
    border: none;

}

.b-rounded-blue.styled-select select {
    border-radius: .3rem;
}

.padded {
    padding-right: 15px;
    padding-left: 15px;
}

.w-90 {
    width: 90% !important;
}

.rdtPicker .rdtDays table tbody tr .rdtToday,
.rdtPicker .rdtDays table tbody tr .rdtToday:hover,
.rdtPicker .rdtDays table tbody tr .rdtDay.rdtDisabled,
.rdtPicker .rdtDays table tbody tr .rdtDay.rdtDisabled:hover,
.rdtPicker .rdtDays table tbody tr .rdtOld.rdtDisabled,
.rdtPicker .rdtDays table tbody tr .rdtOld.rdtDisabled:hover {
    color: #bebebe7a !important;
    background: #fbfbfb !important;
}

.desc-text {
    line-height: 40px;
}

textarea.standart-input,
.parent-input input {
    font-size: 15px !important;
}

.summary-page {
    padding-top: 30px;
}
.summary-page .checkbox-box{
    padding-right: 0!important;
    margin-right: 0!important;
}
.summary-page .summary-check .checkbox-box{
    justify-content: flex-end;
}
.prices {
    line-height: 36px;
    padding: 8px 0;
}

.bg-gr-lither {
    background: #e6f4ed;
    border-bottom: 1px solid #cce4d8;
}

.t-price,
.total {
    margin-bottom: 0;
    line-height: 40px;
}

.duration2 {
    font-weight: 600;
    text-transform: none;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 20px;
    letter-spacing: .4px;
}

.pr-holder {
    line-height: 54px;
}

.rc-tooltip-inner {
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15) !important;
    border-radius: 2px !important;
    width: 250px;
    padding: 15px !important;
}

.rc-tooltip {
    opacity: 1 !important;
}

.rc-tooltip-inner .ttitle {
    color: #001F3D;
    padding-left: 15px;
    position: relative;
    text-transform: capitalize;
    display: inline-block;
    width: 70%;
}

.rc-tooltip-inner .tprice {
    display: inline-block;
    width: 30%;
    text-align: right;
    padding-left: 5px;
}

.rc-tooltip-inner .ttitle,
.rc-tooltip-inner .tprice {
    margin: 0;
    padding-top: 0;
    padding-bottom: 5px;
}

.rc-tooltip-inner .ttitle:before {
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    top: 7px;
    width: 5px;
    height: 12px;
    border: solid #B7E2E5;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.mail {
    display: inline-block;
    margin-top: 5px;
    font-size: 16px;
    color: #fff;
    padding: 5px 40px;
    border-radius: 2px;
    background: #001F3D !important;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
    color: #B7E2E5;
}

.tel {
    color: #001F3D;
    font-size: 16px;
}

.tel:hover {
    color: #001F3D;
    text-decoration: underline;
}

.mail:hover {
    color: #B7E2E5;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .25);
}

.hh {
    height: 43px !important;
}

.spaced {
    letter-spacing: 1px;
}

.icon-close {
    margin-right: -5px;
}

.order-modify-modal .checkmark:before,
.summary-page .checkmark:before {
    display: none;
}
.order-modify-modal .place-order{
    min-width: 160px;
}
.order-modify-modal .checkbox-container,
.summary-page .checkbox-container {
    display: flex !important;
}

.order-modify-modal .checkbox-container {
    padding-right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0;
}

.voucher {
    padding: 15px;
}

.voucher .b-rounded-gray {
    overflow: hidden;
    width: 100%;
}

.voucher .code {
    padding-left: 20px;
    padding-bottom: 5px;
}

.voucher .code span {
    font-size: 12px;
}

.bg-cos {
   background: #12BDC7;
    width: 545px;
    height: 540px;
    transform: rotate(-25deg);
    position: absolute;
    top: -280px;
    display: table;
}

.bg-cos p {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    text-align: left;
}

.heading-p {
    padding: 20px 0 10px 20px;
}

.bg-cos p span {
    color: #B7E2E5;
    font-weight: 400;
    font-size: 24px;
}

.bg-cos p .ammount {
    font-size: 3em;
    color: #001F3D;
    margin-left: 10px;
    font-weight: 200;
}

.bg-cos p .numbers {
    font-size: 2.7rem;
    color: #B7E2E5;
    font-weight: 400;
}

.aed {
    color: #d9d9d9 !important;
    font-size: 18px !important;
}

.voucher .type {
    font-size: 1rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    letter-spacing: .04em;
    font-weight: 400;
    color: #001F3D;
    padding-bottom: 0;
    margin-bottom: 0;
}

.gold-renew {
    height: 20px !important;
    padding: 6px 20px 22px !important;
    font-size: 12px !important;
}

.voucher .d-title {
    font-size: 2.7rem;
    line-height: 2.3rem;
    letter-spacing: .01em;
    font-weight: 400;
    color: #B7E2E5;
}

.feedback-page .bottom-buttons-holder{
    padding: 20px 15px;
}
.feedback-page .day {
    margin-top: 30px;
}
.feedback-page .holder{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.feedback-page .holder .time-programm-group{
    margin: 15px 10px;
    max-width: 100%!important;
}
.progress-page .part-1-inner .part-2 {
    padding: 1rem 0 0 30px;
    border-left: 1px solid #f3f3f3;
}

.prg-button {
    width: 100% !important;
    margin: 0;
    margin-bottom: 1rem;
}

.progress-page .row .part-2 .prg-button span {
    color: #001F3D;
}

.progress-page .row .part-2 .prg-button:hover span {
    color: #B7E2E5;
}

.checkbox-box .disabled {
    color: #c4c4c4 !important;
}

.menu-product {
    cursor: pointer;
    padding-bottom: 15px;
    border-bottom: 1px solid #f1f1f1;
    border-top: 1px solid #fff;
    display: flex;
    align-items: flex-start!important;
}
.menu-product .image{
    min-width: 90px;
    max-width: 90px;
    margin-right: 10px;
}
.menu-product:hover {
    background: #fcfcfc;
}

.bottom-block {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.bottom-block .place-order {
    /* float: left;
    width: 50%;
    margin: 0; */
}

.day .day-header {
    width: 100%;
    /*border-bottom: 1px solid #f3f3f3;*/
}

.day-header h5 {
    border-bottom: none;
    display: inline-block;
}

.day-header h5 span {
    width: 100%;
    float: left;
}

.day .day-header {
    border-bottom: 1px solid #ebe8ea;
    margin-bottom: 1.2em;
}

ul.dish-groups.pl-0.mb-0 {
    margin: 0 0 1em!important;
}

.day-header .control-buttons {
    position: relative;
    float: right;
}

.control-buttons .button {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    display: inline-block;
    padding: 0 20px;
    line-height: 1.5em;
    margin: 1.5em 0;
    cursor: pointer;
}

.control-buttons .button:hover {
    background: #ebebeb;
}
.to-button {
    width: 36px;
    height: 36px;
    border: 1px solid #616f87;
    text-align: center;
    position: absolute;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
    border-radius: 6px;
    cursor: pointer;
    z-index: 147;
}

.to-button.up {
    right: 26px;
    top: 0;
}

.to-button.down {
    bottom: -25px;
    right: 26px;
}

.to-button > span {
    font-size: 1.5em;
    line-height: 1.6em;
    color: #a0a9bb;
}

.to-button:hover {
    background: #B7E2E5;
}

.to-button:hover span {
    color: #fff;
}

.rdtDay.selected {
    background: hsl(40, 76%, 83%);
    color: #000 !important;
}

.order-buttons {
    padding-top: 15px;
}

.order-buttons .week-switch {
    background: #f9f9f9;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .01);
    border: 1px solid #ededed;
}

.order-buttons .week-switch:hover {
    border: 1px solid #dee0e5;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .03);

}

.sub-line {
    background: #f9f9f9;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
}

.order-buttons .mini-replicate .replicate:hover,
.order-buttons .mini-replicate .replicate {
    margin: 0;
    height: auto;
    padding: 6px;
    border-radius: 3px;
}

.order-buttons .mini-replicate .replicate {
    background: #f9f9f9 !important;
    color: #001F3D;
    border: 1px solid #ededed !important;
}

.order-buttons .mini-replicate .replicate:hover:not(.disabled),
.order-buttons .week-switch:hover:not(.disabled),
.weeks-nav .week-switch:hover:not(.disabled) {
    background: #B7E2E5!important;
    color: #001F3D!important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .15);
    border-color: #B7E2E5!important;
}

.order-buttons .mini .week-switch span,
.order-buttons .mini .week-switch {
    line-height: 14px;
    font-size: 14px;
}
.vertical-scrollbar{
    height: 100%;
}
.order-buttons .mini .week-switch {
    padding: 5px;
}
.right-part{
    padding: 0;
}
.right-part .dish-holder {
    height: 100%;
    border-right: 1px solid #f3f3f3;
    border-bottom: 0;
    padding-top: 15px;
}
.inner-plan-details .dish-holder{
    width: 100%;
}
.progress-page-inner .custom-scrollbar{
    max-width: initial;
}
.right-part .part-2-inner h2.green {
    margin-top: 0;
}
.pref-page .Select-control{
    overflow: initial;
}
.h-30 {
    height: 30px !important;
}
.top-buttons {
    margin: 10px -15px;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.top-buttons .sub-line {
    margin-top: 15px;
}
.order-modify-modal.in-modify-page .form-input{
    position: initial;
}
.contact-page{
    max-height: inherit;
}
.order-modify-modal.in-modify-page .rdtOpen{
    bottom: 0!important;
    top: 0!important;
    margin: auto;
    height: 348px;
}
.order-modify-modal{
    position: relative;
}
.top-buttons .paper-bc {
    background: #fff !important;
    color: #001F3D !important;
    border: 1px solid #eef2ee !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .03) !important;
    font-size: 14px;
    line-height: 30px;
}

.progress-chart-navigation {
    text-align: right;
    margin-bottom: 0;
    padding-left: 0;
    padding: 5px 0;
}

.progress-chart-navigation li {
    display: inline-block;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    background: #fff;
}

.progress-chart-navigation li:hover,
.progress-chart-navigation li.active {
    background: #001F3D !important;
    color: #B7E2E5 !important;
}

.day-menu.delivered,
.day.delivered {
    opacity: .7;
}

.day.in-buffer {
    opacity: .85;
}

.day-header .control-buttons .button {
    background: #fff !important;
    color: #001F3D !important;
    border: 1px solid #001F3D !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .03) !important;
    font-size: 14px;
    line-height: 24px;
}

.day.in-buffer .top-params-filters .products .checkbox-container,
.day.delivered .top-params-filters .products .checkbox-container {
    display: none;
}

.day.in-buffer .top-params-filters .products .product.chosen::after,
.day.delivered .top-params-filters .products .product.chosen::after {
    content: "delivered";
    position: absolute;
    top: 30%;
    left: 10%;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    font-size: 32px;
    -webkit-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    transform: rotate(-25deg);
    text-shadow: 0 1px 5px rgb(15, 15, 15);
}

.day.in-buffer .top-params-filters .products .product.chosen::after {
    /*content: "prepares for delivery";*/
    content: "DONE (Cannot be modified)";
    text-align: center;
    left: 5%;
    top: 30%;
    line-height: 30px;
    font-size: 28px;
}

.next-date {
    font-weight: bold;
    color: #1e212b !important;
}

.part-1-inner h5.green {
    margin-bottom: 0;
    line-height: 20px;
}

.delivery-n {
    margin-top: 30px;
}

.calendar-holder .hide-arrow-calendar .rdtStatic .rdtPicker .rdtDays table tr td.rdtToday.delivery-day {
    background: #B7E2E5 !important;
    color: #1e212b !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.rdtDay.in-buffer[selector=past] {
    background-color: #5b6d64 !important;
    color: #fff !important;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
    border: 1px solid #001F3D;
    font-weight: 700;
    cursor: pointer;
}

.calendar-holder .hide-arrow-calendar .simple-calendar .rdtPicker .rdtDays table tbody tr td.rdtDay.rdtOld.in-buffer {
    background: #001F3D !important;
    border: 1px solid #616f87 !important;
    color: #001F3D !important;
}

.cupons-holder .programm-title h5 {
    width: 100%;
    text-transform: uppercase;
}

.bg-green-l {
    background: #e6f4ed;
}

.desc-holder {
    box-shadow: 0 1px 3px rgba(0, 0, 0, .02);
    border: 1px solid #f6f6f6;
    margin-top: 1rem;
}

.desc-holder p {
    margin-bottom: 0;
    margin-top: 0;
}

.desc-holder h5 {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.desc-holder .desc-headline {
    margin: 0;
    background: #f5f5f5;
    border-bottom: 1px solid #f0efee;
}

.desc-holder .desc-headline .title-block .m-title {
    color: #001F3D;
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-size: 15px;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-weight: 400;
}

.by-defoult .voucher {
    opacity: .2;
}

.chart-head-line > div {
    padding: 0px;
}
.chart-head-line {
    border-bottom: 1px solid #efefef;
    margin: 5px 0;
    padding-bottom: 2px;
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chart-head-line h5{
    margin-top: 0;
    text-align: left!important;
}
.inp-on-mobile.chheck-holder{
    padding-left: 0;
}
.empty-chart:after {
    content: "Click progress tracking button to add new data";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40%;
    margin: auto;
    text-align: center;
    font-size: 24px;
    padding: 20px 20px 0;
    display: flex;
    max-width: 80%;
    align-items: center;
    font-weight: 400;
    color: #B7E2E5;
    justify-content: center;
}

.calendar-inner {
    position: relative;
    flex: 1 1;
    overflow: auto;
    overflow-x: hidden;
    padding-top: 14px;
}

.rdt {
    right: auto !important;
    left: 0 !important;
    z-index: 999;
}

.some-class.modal-wrapper {
    padding: 0;
}
.modal-wrapper.some-class .close-button {
    top: 5px;
    right: 5px;
}

.single-blog-page {
    background: #fff;
    padding: 30px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .05);
    margin-top: 15px;
    border-radius: 3px;
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
}

.single-blog-wrapper .bg-and-sh {
    padding: 0;
    display: flex;
    align-items: stretch;
}

.single-blog-wrapper .bg-and-sh .prev-class,
.single-blog-wrapper .bg-and-sh .next-class {
    display: flex;
    width: 100%;
}

.blog-img-holder img {
    width: 50%;
    max-width: 50%;
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;
}

.single-blog-page h1 {

    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: .08em;
    line-height: 2rem;
    margin-bottom: 0;
    color: #555;
}

.title-holder .support-main h1 {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .2em;

    font-size: 3rem;
    font-weight: 600;
    color: #555;
}

.pd-layer .close-button {
    top: 5px !important;
    right: 5px !important;
}

.pd-layer .close-button span {
    font-size: 1rem;
}

.w-c-80 {
    width: 80% !important;
}

.mr-0-i {
    margin-right: 0 !important;
}

.second-bg {
    background-image: url('../assets/contact2-img.jpg');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.word-content .control-buttons-holder {
    position: absolute;
    right: 5px;
    top: 10px;
}

.word-content .control-buttons-holder span {
    cursor: pointer;
    font-size: 1.2em;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    border-radius: .4em;
    line-height: 1.5;
    margin-right: 10px;
}

.word-content .control-buttons-holder span:hover {
    text-shadow: 0 2px 4px rgba(0, 0, 0, .15);
}

.word-content .control-buttons-holder .edit {
    color: #c39a5a;
}

.word-content .control-buttons-holder .delete {
    color: #333f54;
}

.discussions-inner .blog-filters {
    padding-left: 0;
}

.discussions-inner .back-holder {
    margin: 15px 0;
}

.discussions-inner .masonry-wrap {
    margin-top: 15px;
}

.masonry-wrap .masonry,
.discussions-inner .masonry-wrap .masonry {
    padding: 0 0 30px;
}

.discussions-inner .masonry-wrap .masonry .article {
    width: 32%;
    margin: 0px 5px 5px 0px;
}

.all-my {
    padding-top: 15px;
    padding-bottom: 2px;
}

.all-my .category {
    background: none;
    box-shadow: none;
    padding: 15px;
    padding-bottom: 0;
    padding-top: 0;
}

.all-my .category:hover {
    box-shadow: none;
}

.all-my .category:first-child .row {
    margin-right: 2px;
}

.all-my .category:last-child .row {
    margin-left: 2px;
}

.all-my .category .row {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 3px hsla(0, 0%, 100%, .15);
}

.all-my .category:hover .row,
.all-my .category.active .row {
    background: #354158;
    box-shadow: 0 2px 3px hsla(0, 0%, 100%, .15);
    border-radius: 3px;
}

.all-my .category:hover p {
    color: #fff;
}

.all-my .blog-filters {
    position: absolute;
    top: 0;
    left: -60px;
    padding-right: 0;
    background: none;
    z-index: 900;
}

.all-my .blog-filters .back-holder {
    padding: 2px;
}

.all-my .blog-filters .back-holder .back-link span {
    padding-right: 0;
    font-size: 18px;
    padding: 11px 12px 15px 12px;
}

.t-title h1 {
    margin-bottom: 0;
}

.t-page-head,
.t-page {
    margin-bottom: 0;
}

.t-page .article-body {
    min-height: auto;
}

.t-page .article-body p {
    margin-bottom: 5px;
}

.t-page-head {
    padding: 15px 30px;
    background-image: url('../assets/hero-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.t-page-head:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: radial-gradient(circle, #475974, #465166, #414b60, #3e4558, #323745);
    opacity: .95;
    border-radius: 3px;
}

.t-page-head h1 {
    text-align: center;
    font-family: Lato, Helvetica, Arial, sans-serif;
    color: #fff;
}

.add-thread-form {
    margin-top: 14px;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, .05);
    padding: 25px;
    background: #fff;
}

.add-form-holder .bg-gr-light-2,
.add-form-holder .bg-gray-bordered {
    background: #fff;
}

.n-shadow {
    box-shadow: none !important;
}

.add-form-holder .form-input.styled-select,
.add-form-holder .form-input.text-area-input {
    box-shadow: none !important;
}

.add-form-holder .form-input.styled-select,
.add-form-holder .form-input {
    box-shadow: 0 2px 3px rgba(0, 0, 0, .05) !important;
}

.add-thread-form h5 {
    margin-top: 0;
}

h2.article-title {
    line-height: 1.6;
    color: #919aab;
}

p.article-date {
    margin-bottom: 0;
    color: #B7E2E5;
    font-size: 14px;
}

.article .threat .article-title {
    line-height: 1.3;
    margin-top: 10px;
}

.article .threat span.disqus-comment-count,
.article .threat p.article-date {
    font-size: 13px;
    color: #B7E2E5;
}

.art-on-bg {
    box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    overflow: hidden;
    background: #fff;
}

.bg-and-sh {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    background: #fff;
    margin-bottom: 30px;
}

.article-wrapper .next-prev-article {
    margin: 0;
    width: 100%;
}

.next-prev-article .word-content {
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.prev-class .next-prev-article .word-content h2:before,
.next-class .next-prev-article .word-content h2:before {
    content: "Next Article ⟶";
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #B7E2E5;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    position: relative;
    text-align: right;
}

.prev-class .next-prev-article .word-content h2:before {
    content: "⟵ Prev Article";
    text-align: left;
}

.prev-class .next-prev-article .word-content h2,
.next-class .next-prev-article .word-content h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #001F3D;
    width: 100%;
}

.prev-class .next-prev-article .word-content h2 {
    text-align: left;
}

.next-class .next-prev-article .word-content h2 {
    text-align: right;
}

.next-class .next-prev-article .word-content .categoryes {
    text-align: right;
    text-transform: lowercase;
}

.tags {
    color: #B7E2E5;
    font-weight: bold;
}

.next-prev-article .image-bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding: 9%;
}

.next-prev-article .word-content h2 {
    font-family: Lato, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .3px;
    line-height: 1.4;
}

.next-prev-article .word-content .tags,
.next-prev-article .word-content .categoryes {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.3;
}

.article-body {
    min-height: 35vh;
}

.category.disabled {
    background: none !important;
}

.category.disabled p {
    color: #b8b8b8 !important
}

.all-my .category.disabled:hover .row {
    background: #fff !important;
}

.all-my .category.disabled:hover .row p {
    color: #b8b8b8 !important
}

.reviews-list {
    padding: 0;
}

.reviews-list .review span {
    font-weight: 600;
    color: #414d65;
}

.reviews-list .review > p {
    word-break: break-all;
    font-size: 14px;
    line-height: 18px;
}

/*scroll edit*/
.horizontal-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    border-right: none;
    border-left: none;
}

.horizontal-scrollbar {
    overflow-x: auto;
    overflow-y: hidden;
    border-right: none;
    border-left: none;
}

.horizontal-scrollbar::-webkit-scrollbar {
    height: 5px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.horizontal-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}

html::-webkit-scrollbar {
    height: 5px;
}

html::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

html::-webkit-scrollbar {
    width: 10px;
}

html::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}
.modal-father::-webkit-scrollbar {
    height: 5px;
}

.modal-father::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.modal-father::-webkit-scrollbar {
    width: 10px;
}

.modal-father::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}

.modal-wrapper::-webkit-scrollbar {
    height: 5px;
}

.modal-wrapper::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.modal-wrapper::-webkit-scrollbar {
    width: 10px;
}

.modal-wrapper::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}

.filters-on-block .category-holder::-webkit-scrollbar {
    height: 5px;
}
.wrap-one-day{
    padding-bottom: 0;
}
.filters-on-block .category-holder::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.filters-on-block .category-holder::-webkit-scrollbar {
    width: 10px;
}

.filters-on-block .category-holder::-webkit-scrollbar-thumb {
    background-color: #B7E2E5;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}

.calendar-holder::-webkit-scrollbar {
    height: 5px;
}

.calendar-holder::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.calendar-holder::-webkit-scrollbar {
    width: 10px;
}

.calendar-holder::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}

.order-block-holder::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
}

.order-block-holder::-webkit-scrollbar {
    width: 10px;
}

.order-block-holder::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}
.dish-holder{
    flex: 1 1;
    padding: 0px 30px;
    border-left: 1px solid #f3f3f3;
}
.calendar-holder{
    max-width: 250px;
}
.chat-holder-part{
    max-width: 250px;
    border-left: 1px solid #f3f3f3;
}
.calendar-inner::-webkit-scrollbar-track {
    background-color: #001F3D;
}

.calendar-inner::-webkit-scrollbar {
    width: 5px;
}

.calendar-inner::-webkit-scrollbar-thumb {
    background-color: #7b8aa5;
    border-top: 1px solid #B7E2E5;
    border-bottom: 1px solid #B7E2E5;
    border-radius: 0.2rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-left: 1px solid #f3f3f3;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #f3f3f3;
}
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #f3f3f3;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0.2rem;
}
.custom-scrollbar{
    overflow: auto;
    overflow-x: hidden;
    max-width: 100vw;
}
/*scroll edit*/

.horizontal-scrollbar {
    overflow: auto;
}

h4.thank-you {
    font-size: 1.5rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    letter-spacing: .01em;
}

p.thank-you {
    font-size: 1rem;
    font-family: Lato, Helvetica, Arial, sans-serif;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    letter-spacing: .01em;
}

.day-header .control-buttons .button.button-skipped {
    background: #001F3D !important;
    color: #B7E2E5 !important;
}

.day-menu .selections p {
    margin: 0;
    line-height: 18px;
}

.day-menu .selections span {
    margin: 0;
    line-height: 12px;
    display: inline-block;
}

.spicy {
    color: #ffa299;
    font-size: 12px;
    margin: 0;
    font-weight: bold;
}
.empty-jar{
  position: absolute;
  bottom: 50px;
  left: 5%;
  width: 300px;
  height: 272px;
}
.jar-img{
  background-image: url('../assets/cookieJar.png');
  width: 100%;
  height: 100%;
  background-size: cover;
}
.empty-jar>span{
  position: absolute;
  top: 40%;
  width: 100%;
  font-size: x-large;
  letter-spacing: 3px;
  padding-left: 28px;
  text-transform: uppercase;
}
.need-to-turn{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    line-height: 100vh;
    text-align: center;
}
.need-to-turn h2{
    padding-top: 100px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #595355;
}
.need-to-turn h2:after{
    content: '';
    background: url('../assets/Basiligo-Logo.png') center no-repeat;
    display: block;
    height: 50px;
    background-size: contain;
    position: absolute;
    bottom: 120px;
    width: 100%;
}
.cookie-message.container-fluid{
    position: fixed;
    bottom: 0;
    z-index: 999;
    min-height: 50px;
    padding: 10px 10px 15px 10px;
}
.cookie-message.container-fluid .cookie-message-text{
    color: #d5b78c;
    font-size: 16px;
}
.cookie-message.container-fluid .button-regular{
    line-height: 17px!important;
    min-height: 17px;
    width: 130px;
    margin: 0 auto;
}


/* apply button on summary */
.apply-button {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #001F3D;
  padding: 0 10px;
  background: #001F3D;
  cursor: pointer;
  border-radius: 2px;
  height: 100%;
  overflow: hidden;
}
.apply-button>span {
  color: #B7E2E5;
    line-height: 1;
    font-size: 14px;
}
.apply-button:hover {
  background: #e5cb9a!important;
  box-shadow: 0 1px 5px rgba(0,0,0,.15);
  border-color: #e5cb9a!important;
}
.apply-button:hover>span {
  color: #001F3D!important;
}
.code-input input {
    font-size: 15px!important;
}
.pop-style{
    margin: 15px;
    padding: 15px;
    border: 1px solid #eee;
}
.mod-type-switcher {
  display: block;
  position: absolute;
  right: 20px;
  top: 25px;
  border: 1px solid #001f3d;
  border-radius: 3px;
  padding: 5px 15px;
  color: #001f3d;
  cursor: pointer;
}
.progress-message .button-regular{
    max-width: 100%;
    width: 100%;
}
.place-order.cancel,
.mod-type-switcher.future {
    background: #001f3d;
    color: #fff;
    border-color: #001f3d;
    border-radius: 0px;
}
.static-page p{
    margin-bottom: 5px;
}
a,
a:hover{
    color: #001F3D;
}

/*
.static-page *{

}
*/
.single-blog-page .static-page h1{
    font-size: 1.5rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    color: #414d65;
    text-align: left;
}
.single-blog-page .static-page h2{
    font-size: 1.2rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    text-align: left;
}
.single-blog-page .static-page h3,
.single-blog-page .static-page h4,
.single-blog-page .static-page h5{
    font-size: 1rem;
    margin-bottom: .5rem;
    margin-top: .5rem;
    color: #B7E2E5;
    text-transform: uppercase;
    letter-spacing: .3px;
    text-align: left;
}


/* Dashboard */


#dashboard {
  color: #001F3D;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.03em;

  overflow-x: hidden; }

#dashboard input {
    margin: 0 10px;
  padding: 5px 10px;
  border: 1px solid rgba(89, 88, 90, 0.5); }
  input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #001F3D;
    border-bottom: 2px solid #001F3D; }
  input:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #001F3D;
    border-bottom: 2px solid #001F3D; }

#dashboard p {
  margin-bottom: 25px; }

#dashboard h1, #dashboard h2, #dashboard h3, #dashboard h4, #dashboard h5, #dashboard h6 {
  font-family: 'Montserrat', sans-serif;
	font-weight: 500;
}

#dashboard h1 {
  font-size: 3.3em;
  line-height: 0.95; }

#dashboard h2 {
  font-size: 32px;
  line-height: 42px; }

#dashboard h3 {
  font-size: 24px;
  line-height: 32px; }

#dashboard h4 {
  font-size: 15px;
  line-height: 27px; }

#dashboard h5 {
  font-size: 14px;
  line-height: 18px; }


#dashboard a, .link-dash {
  color: #001F3D !important;
  text-decoration: underline;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.waurl {
    font-size: 12px;
    color: #001F3D !important;
}

#dashboard a:hover {
    color: #12BDC7;
    opacity: .8; }

#dashboard .grey {
  /*color: silver !important;*/
  color: #333 !important;
}

#dashboard .gold {
  color: #12BDC7; }

#dashboard .btn {
  text-decoration: none; }
#dashboard .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none; }

#dashboard .transparent-button {
  border: 1px solid #211F1F;
  border-radius: 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 14px 25px 13px;
  color: #211F1F; }

#dashboard .transparent-button:hover {
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.5); }

#dashboard .transparent-white-button {
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 14px 25px 13px;
  color: #fff; }

#dashboard .transparent-white-button:hover {
    font-weight: 400;
    background-color: #fff;
    color: #001F3D; }

.set-links a {
    color: black !important;
}
#dashboard .transparent-gold-button {
  border: 1px solid #12BDC7;
  border-radius: 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 14px 25px 13px;
  color: #12BDC7; }

#dashboard .transparent-gold-button:hover {
    font-weight: 400;
    background-color: #12BDC7;
    color: #fff !important; }

#dashboard .gold-button {
  border: 1px solid #12BDC7;
  background-color: #12BDC7;
  border-radius: 0;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 14px 25px 13px;
  color: #fff !important;  }
#dashboard .user-data .gold-button{float: right;}
#dashboard .user-birthday input.standart-input.b-rounded-blue.shadowed.letter-xs.white-back.pointer {width: auto;}
#dashboard .user-birthday button.btn.gold-button.small-save {float: left;}
#dashboard .user-birthday .bday-sec {display: table;width: auto;margin: 0 auto;min-width: 75%;}
#dashboard .user-birthday .bday-sec .search-profile-main-cal {float: left;}
#dashboard .user-birthday .bday-sec .search-profile-main-cal input[type="text"] {height: 38px;}
#dashboard .gold-button:hover {
    font-weight: 400;
    background-color: transparent;
    color: #12BDC7 !important; }

#dashboard .slim-button {
  padding: 11px 5px 10px;
  width: 100%; }

#dashboard .wide-button {
  padding: 14px 50px 13px; }

#user-data {
  background-color: #f5f5f5;
  padding: 30px 0 0;
margin-left: -15px;
	margin-right: -15px;
}

.user-data-wrap {
  background-color: #fff;
  padding-top: 104px;
  margin-top: -104px;
  padding-bottom: 10px;
  z-index: 1; }
  .user-data-wrap .bg-left-corner {
    z-index: -1; }

.user-photo img {
  position: relative;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;  
}
.user-photo
{
	position: relative;
	display: inline-block;
	clear: both;
	cursor: pointer;
}
.uplo {
     z-index: 99999;
    text-align: center!important;
    background: #000;
    opacity: .7;
    padding: 10px;
    width: 160px;
    height: 80px;
    border-radius: 0 0 90px 90px;
    color: #fff!important;
    margin: auto;
    /* margin-bottom: -175px!important; */
    display: block;
    position: absolute;
    bottom: -1px;
    border-radius: 0 0 140px 140px;
	cursor: pointer;
}

.uplo span {
    color: white;
	cursor: pointer;
}

.user-birthday:after {
  content: '';
  width: 224px;
  max-width: 80%;
  height: 1px;
  background-color: #E6F2EC;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto; }

.user-joined {
  font-size: 10px;
  line-height: 25px; }

.today-meal-grid {
    margin-left: 16px;
    margin-right: 16px;
    padding: 42px;

}

.today-meal-wrap {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px; }

.total-macros, .total-calories {
  font-size: 12px;
  line-height: 25px; }

.today-meal-grid .plans-card-image img {
/*  max-height: 115px;*/
  width: 100%; }

.today-meal-social-button {
  left: 0;
  right: 0;
  top: -13px;
  text-align: center; }
  .today-meal-social-button .today-meal-social-button-wrap {
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    display: inline-block; }
  .today-meal-social-button a {
    padding-left: 18px;
    padding-right: 18px; }
    .today-meal-social-button a:first-child {
      padding-left: 36px; }
    .today-meal-social-button a:last-child {
      padding-right: 36px; }

.today-meal-full-plan {
  left: 0;
  right: 0;
  bottom: -9px;
  text-align: center; }
  .today-meal-full-plan .today-meal-full-plan-wrap {
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    display: inline-block; }
  .today-meal-full-plan a {
    padding-left: 36px;
    padding-right: 36px; }

.managers-wrap {
  background-color: #fff;
  padding: 20px 15px; }

.manager-name h4 {
  line-height: 1; }

.manager-position {
  font-size: 12px;
  line-height: 14px; }

.contact-link {
  font-size: 14px;
  line-height: 17px; }

.grey-line-1px {
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative; }
  .grey-line-1px:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #E6F2EC;
    display: block; }

.links-wrap {
  background-color: #fff;
  background-image: url('../assets/dashboard/img/bg-setting.svg');
  background-repeat: no-repeat;
  background-position: 70% 10px;
  min-height: 173px;
  padding: 0; }
  .links-wrap ul {
    list-style: none;
    margin: 0;
    padding: 0 15px 0 0; }
    .links-wrap ul a {
      color: #001F3D; }
  .links-wrap .row {
    min-height: 173px; }

.bg-links {
  background-image: url('../assets/dashboard/img/bg-links.png');
  background-repeat: no-repeat;
  background-position: -30px 0;
  min-height: 173px;
  min-width: 237px;
  position: absolute; }

#goals {
  background-color: #f5f5f5;
  padding: 0;
margin-left: -15px;
	margin-right: -15px;
    display: none;
}

.my-goal-wrap {
  background-color: #fff;
  padding: 30px 15px 15px; }

.calendar .month {
  font-size: 12px;
  line-height: 14px;
  color: #5B9C85; }

.calendar .weight-data-calendar {
  font-size: 14px;
  line-height: 25px;
  white-space: nowrap; }

.calendar .calendar-cell {
  border: 1px solid #E6F2EC;
  padding: 4px 7px;
  height: 43px; }
  .calendar .calendar-cell.disabled-month {
    opacity: .5; }

a.calendar-years {
  text-decoration: none;
  color: #001F3D;
  font-size: 10px;
  line-height: 1;
  opacity: .5; }
  a.calendar-years:hover {
    text-decoration: none;
    color: #001F3D; }

a.calendar-prev-year {
  text-decoration: none;
  font-size: 10px;
  line-height: 1; }
  a.calendar-prev-year:hover {
    text-decoration: none;
    opacity: .5; }
  a.calendar-prev-year:before {
    content: url('../assets/dashboard/img/calendar-arrow-left.svg');
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px; }
  a.calendar-prev-year.disabled {
    opacity: .5; }

a.calendar-next-year {
  text-decoration: none;
  font-size: 10px;
  line-height: 1; }
  a.calendar-next-year:hover {
    text-decoration: none;
    opacity: .5; }
  a.calendar-next-year:before {
    content: url('../assets/dashboard/img/calendar-arrow-left.svg');
    display: inline-block;
    margin-left: 3px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg); }
  a.calendar-next-year.disabled {
    opacity: .5; }

.att-document-privacy {
  font-size: 8px;
  line-height: 10px; }

.chart-wrap {
  background-color: #fff;
  padding: 30px; }

.period-buttons a {
  font-size: 12px;
  line-height: 14px;
  padding-left: 8px;
  padding-right: 8px; }

.chart-container {
  height: 310px;
  width: 100%; }

.my-progress {
  background-color: #fff;
  padding: 30px; }

.photo-progress {
  width: 100%;
  max-width: 156px;
  margin-bottom: 20px;
  height: 262px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.edit-photo-button {
  position: absolute;
  top: 3px;
  right: 4px; }

.view-history-button a {
  font-size: 14px;
  line-height: 17px; }

#meal-plans {
  background-color: #f5f5f5;
  padding: 0 0 30px; }

.meal-plans-wrap {
  background-color: #fff;
  padding: 120px 30px 30px; }

.bg-meal-plans-center {
  background-image: url('../assets/dashboard/img/bg-meal-plans.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  position: absolute;
  width: 100%;
  top: 20px;
  left: 20px;
  right: 20px;
  height: 62px; }

.bg-left-corner {
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 385px;
  height: 316px;
   }

.bg-right-corner {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 385px;
  height: 316px;
 }

#current-meal-plan {
  background-color: #f5f5f5;
  padding: 0 0 30px; }

.plans-card-title {
    min-height: 55px;
}

.plans-card-title h5 {
    font-size: 12px !important;
    font-weight: 400;
    margin: 0;
    padding: 5px;
    white-space: normal;
    /*height: 60px;*/
    line-height: 14px !important;
    text-align: left;
    font-weight: 700;

}

.manager-contacts {
    font-size: 12px;
    line-height: 14px;
    margin-top: 0;
    width: 245px;
    margin-left: -18px;
}
.manager-contacts h3{
    margin-left: 8px;
}
.success-text {
    color: green;
    font-size: 14px;
    text-transform: uppercase;
}

.small-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-left: 8px;
}

.plans-card-image {
    margin-bottom: 5px !important;
}

.plans-card-image img {

    width: 100% !important;
    margin-top: -24px;
}

.meal-card {
    border: 1px solid #d0d0d0 !important;
    width: 100%;
	padding: 15px;
    min-height: 220px;
    margin: auto !important;
    margin-bottom: 20px !important;
}

.card-macros {
    font-size: 9px;
    line-height: 12px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    margin-bottom: 6px;
}

.card-calories {
    font-size: 12px;
    line-height: 15px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    margin-bottom: 5px;
}

.card-type {
    text-align: left;
    color: #001F3D;
    font-weight: bold;
    font-size: 14px;
    padding-left: 5px;
}


.current-meal-plan-wrap {
  background-color: #fff;
  padding: 90px 30px 30px;
  background-image: url('../assets/dashboard/img/bg-current-meal-plan-m.png');
  background-repeat: no-repeat;
  background-position: -20px -20px; }

.current-meal-plan-wrap a {
  font-size: 14px;
  line-height: 17px; }

.start-date, .end-date {
  font-family: Garamond-Basiligo, serif;
  font-size: 26px;
  line-height: 34px; }

.delivery-buttons a {
  width: 48%;
  padding-left: 2px;
  padding-right: 2px; }
  .delivery-buttons a:first-child {
    margin-right: 1%; }
  .delivery-buttons a:last-child {
    margin-left: 1%; }

.current-meal-plan-description {
  font-size: 12px;
  line-height: 14px; }

.bg-current-plan-center {
    background-image: url('../assets/dashboard/img/current-meal-plan.svg');
    height: 65px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: absolute;
    top: 20px;
}

.bg-current-plan-right {
  position: absolute;
  right: -185px;
  bottom: -10px;
  width: 385px;
  height: 254px;
  background-image: url('../assets/dashboard/img/bg-dashboard-bottom-right.svg'); }

#referal-program {
  background-color: #f5f5f5;
  padding: 0; }

.invitation-wrap {
  background-color: #fff;
  padding: 30px;
  font-size: 14px;
  line-height: 17px; }
  .invitation-wrap .bg-left-corner {
    z-index: 0; }
  .invitation-wrap .invite-button {
    z-index: 1;
    position: relative; }

#friend-invited, #cdr {
  max-width: 100%; }

.rewards-table {
  font-size: 12px;
  line-height: 14px; }
  .rewards-table tr td:first-child {
    white-space: nowrap;
    vertical-align: top; }

.voucher-wrap {
  background-color: #fff;
  padding: 30px; }

.voucher-description {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px; }

.voucher-line:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: #001F3D;
  opacity: .2;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px; }

.claim-button .btn {
  width: 225px;
  max-width: 100%; }

.all-voucher-button a {
  font-size: 14px;
  line-height: 17px; }

.voucher-notes {
  font-size: 10px;
  line-height: 25px; }

.get-voucher-form input {
  height: 40px;
  width: 225px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 15px; }
  .get-voucher-form input.btn {
    padding: 11px 5px 10px; }

.gift-wrap {
  background-color: #fff;
  padding: 30px; }

.gift-title:after {
  content: url('../assets/dashboard/img/gift.svg');
  margin-left: 5px; }

.all-gift-button {
  margin-top: 20px;
  margin-bottom: 28px; }
  .all-gift-button a {
    font-size: 14px;
    line-height: 17px; }

#charts {
  background-color: #f5f5f5;
  padding: 0; }

.fitbit-wrap {
  background-color: #fff;
  padding: 50px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 365px; }

.charts-wrap {
  background-color: #fff;
  padding: 30px; }

.charts-height {
  position: relative;
  padding-bottom: 55px; }

.charts-height h5 {
  font-size: 16px;
  line-height: 21px; }

.donate-button {
  position: absolute;
  bottom: 0;
  left: 3px;
  right: 3px;
  margin-left: auto;
  margin-right: auto; }
  .donate-button a {
    width: 195px;
    max-width: 100%; }

.insta-wrap {
  background-color: #fff;
  padding: 60px 30px 30px; }

.insta-bg {
  background-image: url('../assets/dashboard/img/bg-insta.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  height: 65px;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 15px;
  right: 15px;
  margin-left: auto;
  margin-right: auto; }

  .dashboard-main-content {
    height: 3000px;
  }

.pointer {
    cursor: pointer;
}

.progressImg {
    max-width: 180px;
    max-height: 275px;
    cursor: pointer;
}
.insta-buttons a {
  width: 47.5%;
  padding-left: 2px;
  padding-right: 2px; }
  .insta-buttons a:first-child {
    margin-right: 1%; }
  .insta-buttons a:last-child {
    margin-left: 1%; }
	.navbar-nav a
	{
		color: #001F3D;
	}
	.navbar-nav a:hover
		{
			color: #12BDC7;
    }
    ul.list li{
        list-style: inside;
    }
.clr-white
{
	color: #fff !important;
}
.product div .tags li span.red{
    color: #fb0b0b!important;
}
.product div .tags li span.orange{
    color: #C94050!important;
}

.product.transition.not.light-fade {
    opacity: 0.6;
}

/* Dish Card New Design Start*/

.d-card-inr-holder img
{
  max-width:100%;
}
.d-card-inr-holder
{
  background-color:#fff;
  padding:15px 15px 20px 15px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2);
margin: 15px;
}
.d-card-img
{
  position:relative;

}
.d-card-img:after
{
content:"";
display: none;
  position: absolute;
bottom: 0;
height: 100px;
width: 100%;
z-index: 1;
left:0;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+98&0+0,1+99 */
background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.99) 98%, rgba(255,255,255,1) 99%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 98%,rgba(255,255,255,1) 99%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.99) 98%,rgba(255,255,255,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */


}
.img-holder.d-card-img img
{
width: 100%;
object-fit:cover;
}
.d-card-caption
{
  position:relative;
  z-index:111;

}
.l-green-label
{
  background-color:#DBE7E1;
  padding:6px 12px;
  font-size:13px;
  border-radius:3px;
}
.btn-label-dgreen
{

  color:#fff;
  outline:none;
  border:none;
  padding-left:12px;
  padding-right:12px;
  cursor:pointor;
}
.btn-label-dgreen:hover
{
  background-color:#B7995E;
}
.dc-caption-row-lt small
{
  color:#747474;
}
.d-kal-info
{
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 0;
    margin-top: 15px;
    margin-left: -20px;
    margin-right: -20px;
}
.d-kal-info span:after
{
  content:"";
  background-color: #DEDEDE;
  width:7px;
  height:7px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.d-v-more-btn
{
  background-color:#001F3D;
  padding:8px 16px;
  outline:none;
  border:none;
  color:#fff;
  border-radius:5px;
}
.d-v-more-btn:hover
{
  background-color:#12BDC7;

}
.d-card-caption
{
  padding:15px 0 0;
}
.btn-label-dgreenoutline
{
  background-color: #fff;
  border: 1px solid #001F3D;
  color: #001F3D;
  font-size: 12px;
}
.btn-label-dgreenoutline:hover
{
    background-color: #001F3D;
    color: #fff;
}
.btn-label-turq-outline
{
  background-color: #fff;
  border: 1px solid #001f3d;
  color: #001f3d;
}
.btn-label-turq-outline:hover
{
  background-color: #001f3d;
  color: #fff;
}
.card-kacal
{
  margin-left: -10px;
margin-top: -10px;
margin-bottom: -10px;
padding: 10px;
display: inline-block;
margin-right: 10px;
border-right: 1px solid #d0d0d0;
}
.card-kacal i
{
  margin-right: 5px;
}
.card-kacal::after,
.d-kal-info span:last-child::after
{
  display: none !important;
}
.discrd-ftr-rt .text-link
{
font-size: 13px;
cursor: pointer;
text-decoration: underline !important;
}
.d-emoji-display,
.card-kacal i
{
  display: none;
}
.dc-alergy-holder span,
.dc-dislike-holder span
{
color:#747474;
}
.dc-alergy-holder,
.discrd-ftr
{
  justify-content: space-between;
      align-items: baseline;
}
.text-link
{
color: #747474;
text-decoration: underline;;
}
.text-link:hover
{
  color: #000000;
}
.d-like-btn
{
  background-color: #fff;
    border: 1px solid #001f3d;
    border-radius: 5px;
    padding: 12px 10px 10px 10px;
    margin-left: 10px;
    position: relative;
    top: 4px;
}
.d-like-btn i
{
  background: url("../assets/heart-outline.svg") no-repeat scroll 0 0;
  width:18px;
  height:18px;
  display: block;
}
.d-like-btn:hover,.d-like-btn.active
{
  background-color: #001f3d;
  border-color: #27415b;
}
.d-like-btn:hover i,.d-like-btn.active i
{
  background: url("../assets/heart-white-ic.svg") no-repeat scroll 0 0;
}
.d-emoji-display
{
  background-color: #fff;
  border: 1px solid #001f3d;
  ;
  border-radius: 5px;
  padding: 12px 10px 10px 10px;
  margin-left: 5px;
  position: relative;
  top: 4px;
}
.d-emoji-display i
{
  background: url("../assets/emoji-outline.svg") no-repeat scroll 0 0;
  width: 18px;
  height:18px;
  display: block;
}
.d-emoji-display:hover,.d-emoji-display.active
{
  background-color: #001f3d;
}
.d-emoji-display:hover i,.d-emoji-display.active i
{
  background: url("../assets/emoji-white-outline.svg") no-repeat scroll 0 0;
}
.m-window-holder
{
  background-color: #fff;
  border-radius: 5px;
  max-width: 640px;
  -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
padding: 40px 35px 35px 35px;
}
.clr-red-txt
{
color: #AA5858;
}
.info-txt-wrapper p,
.clr-light-gry-txt
{
  color: #747474;
}
.info-heightlight-txt
{
  display: block;
  color: #000;
}
.emoji-pick-sec li p
{
  color:#000;
  font-size: 13px;
  line-height: .8;
}
.emoji-pick-sec li small
{
  color: #747474;
  font-size: 12px;
}
.emoji-pick-sec li
{
  list-style: none;
  text-align: center;
  cursor: pointer;
}
.emoji-pick-sec li p{
  margin-bottom: 0px !important;
}
/*.emoji-pick-sec li i
{
  display: inline-block;
  width:64px;
  height:72px;
  background:url("../assets/emoji-ic-set.svg") no-repeat;
}
.emoji-1
{
background-position: -17px -103px !important;
}
.emoji-1:hover,.emoji-pick-sec li.active .emoji-1
{
background-position: -17px -6px !important;
}
.emoji-2
{
background-position: -131px -103px !important;
}
.emoji-2:hover,.emoji-pick-sec li.active .emoji-2
{
background-position: -131px -6px !important;
}
.emoji-3
{
background-position: -250px -103px !important;
}
.emoji-3:hover,.emoji-pick-sec li.active .emoji-3
{
background-position: -250px -6px !important;
}
.emoji-4
{
background-position: -380px -103px !important;
}
.emoji-4:hover,.emoji-pick-sec li.active .emoji-4
{
background-position: -380px -6px !important;
}*/
.feedback-form-holder .form-control
{
font-size: .8rem;
}
ul.sides-list{
    padding: 0;
    text-align: left;
}
ul.sides-list li {
    display: inline-block;
    font-size: 12px;
    padding: 0;
    background-color: #f5f5f5;
    border-radius: 3px;
    margin-top: 0;
}
.plans-card-title h6 {
    text-align: left;
    margin: 0;
}
/* End Dish Card New Design Start*/
.alert-unsaved {
    background-color: #b7e2e5;
    position: fixed;
    width: 100%;
    z-index: 9999;
    color: #001f3d;
    text-align: center;
    left: 0;

  }
  .warning {
    display: inline-block;
    height: 25px;
    width: 25px;
    background: #b7e2e5;
    text-align: center;
    border-radius: 50%;
    color: #001f3d;
    line-height: 26px;
    margin-right: 7px;
    cursor: pointer;
    border: 1px solid #001f3d;
    font-size: 14px;
}
  .alert-unsaved strong {
    color: #001f3d;
}
.BrainhubCarousel__custom-arrowRight.BrainhubCarousel__customArrows
{
  /*position: absolute;*/
  bottom: 0;
  left: 50%;
  /*margin-left: 50px;*/
}
.BrainhubCarousel__customArrows.BrainhubCarousel__arrow--disable.BrainhubCarousel__custom-arrowLeft,
.BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft
{
  /*position: absolute;*/
  bottom: 0;
  left: 50%;
  right: auto;
}
.BrainhubCarousel__container
{
  /*padding-bottom: 40px;*/
    padding-bottom: 0px;
}
.btn-label-dgreenoutline
{
  cursor: pointer;
}
.BrainhubCarouselItem
{
      align-items: self-start !important;
}
.products
{
  /*display: flex;*/
    margin: 0;
    position: relative;
    width: 100%;
    height: 100%;
}
 

/*may css*/
.renderMealplanDays {
    color: #fff;
    padding: 0px;
    margin: 0px;
  }
  .renderMealplanDays li {
  color: rgba(255,255,255,0.5);
    text-align: left;
cursor: pointer;
padding-bottom: 10px;
border-bottom: 1px solid rgba(255,255,255,0.3);
margin-bottom: 10px;
line-height: 1;
  }
  .renderMealplanDays li:hover,
  .renderMealplanDays li.active
  {
    color: #fff;
    font-weight: 500;
  }
  .renderMealplanDays li:last-child
  {
    border-bottom: none;
  }
ul.BrainhubCarousel__track.BrainhubCarousel__track--transition.BrainhubCarousel__track--draggable{
    padding: 0 0 20px!important;
}
.col-2.col-sm-2.btnb.text-center.backtotop.mtop,.chat-holder-part{
    display: none;
}
.BrainhubCarousel__custom-arrowRight.BrainhubCarousel__customArrows
{
  /* position: absolute; */
  bottom: 0;
  left: auto;
  right:0;
  top:50%;
  /*margin-left: 50px;*/
}
.BrainhubCarousel__customArrows.BrainhubCarousel__arrow--disable.BrainhubCarousel__custom-arrowLeft,
.BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft
{
  /* position: absolute; */
  bottom: 0;
  left: 0;
  top: 50%;
  right: auto;
  z-index: 999;
}
.BrainhubCarousel__container
{
  padding-bottom: 0px;
}
.btn-label-dgreenoutline
{
  cursor: pointer;
}
.BrainhubCarouselItem
{
      align-items: self-start !important;
}
.consultation {
    background: #153759;
    padding: 10px;
    margin: 15px;
    border-radius: 5px;
}
.consultation p {
    color: #d2d2d2;
    font-size: 12px;
    text-align: center;
    line-height: 1.2;
    padding: 0 33px;
}
.consultation .button-regular.book-btn {
    background: #12bdc7 !important;
    border-color: #12bdc7 !important;
    padding: 5px 10px !important;
    height: auto !important;
    min-height: auto !important;
    width: auto !important;
    margin: 0px auto !important;
    display: table;
    border-radius: 6px;
}
.consultation .button-regular.book-btn span {
    color: #fff;
    font-size: 12px;
}
.valid-links ul {
    margin: 0 15px;
    padding: 0px;
}
.valid-links {
    border-bottom: 1px solid #656565;
}
.valid-links ul li a {
    color: #d2d2d2;
    width: 100%;
    border-bottom: 1px solid #656565;
    display: table;
    font-size: 12px;
    line-height: 30px;
    position: relative;
}
.valid-links ul li a:after {
    position: absolute;
    /*content: '\f105';*/
    font-family: 'Font Awesome\ 5 Free';
    right: 0;
    top: 0;
    font-size: 20px;
}
.valid-links ul li a i {
    color: #d2d2d2;
    float: right;
    font-size: 12px;
    position: relative;
    top: 10px;
}
.mealplanselect-list p {
    color: #fff;
    margin: 0px;
    font-size: 12px;
    margin: 20px 0;
}

.mealplanselect-list {margin: 15px;}

.mealplanselect-list ul.renderMealplanDays {
    margin: 0px;
    padding: 10px 0;
}

.valid-links ul li:last-child a {
    border: 0px;
}
.mealplanselect-list ul.renderMealplanDays li.day-list {
    font-size: 12px;
}
i.head-icon{
    background: url("../assets/nutritionist.svg") no-repeat scroll 0 0;
    margin: 0 auto;
    width: 37px;
    height: 37px;
    display: block;
    margin-bottom: 10px;
}
i.icon-meal{
    background: url("../assets/meals.svg") no-repeat scroll 0 0;
    width: 18px;
    height: 25px;
    display: block;
    line-height: 25px;
    float: left;
    padding-right: 25px;
}
.modal-message.pop-style .left-dish.dish-holder {
    text-align: left;
}
.modal-message.pop-style {
    border: 0px;
    margin: 10px;
}
.modal-message.pop-style .left-dish.dish-holder h2 {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
p.macros.calories_sec {
    margin-bottom: 0px;
}
ul.macros {
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: inline-block;
}
ul.macros li span.regular-bold {
    font-weight: normal;
    font-size: 11px;
    padding: 0px;
}
ul.macros li {
    float: left;
    line-height: normal;
    padding: 0 10px 0 0;
    position: relative;
    margin-right: 5px;
}
ul.macros li:after {
    position: absolute;
    content: '|';
    color: #666;
    right: 0px;
    font-size: 11px;
    top: 4px;
}
ul.macros li:last-child:after {
    content: none;
}
.ingridients-contain p.regular-bold.green {
    margin: 0px;
}
.ingridients-contain .ingridients{
    display: inline-block;
    list-style: none;
}
.ingridients-contain .ingridients li {
    font-size: 12px;
    position: relative;
    padding-left: 10px;
    float: left;
    width: 50%;
}
.ingridients-contain .ingridients li:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: #d2d2d2;
    left: 0;
    top: 8px;
    border-radius: 100%;
}
.macros span.regular-bold.green, .macros span.green, .ingridients-contain p.regular-bold.green {
    font-size: 12px;
    font-weight: 500;
}
.modal-custom-body {
    max-width: 100% !important;
}

.main-dish .d-kal-info span.card-kacal, .side-dish-kcal .d-kal-info span.card-kacal {
    /* float: left; */
    padding: 3px 15px 3px 0;
    margin: 0px;
    font-size: 11px;
    height: auto;
    line-height: 1;
}

.main-dish .d-kal-info span,.side-dish-kcal .d-kal-info span {
    font-size: 11px;
}

.main-dish .d-kal-info span.card-kacal i {
    display: none;
}

.d-flex.discrd-ftr .discrd-ftr-lt {
    /* margin: 0 auto; */
    text-align: center;
    display: table;
}

.d-flex.discrd-ftr .discrd-ftr-lt button.d-v-more-btn {
    padding: 5px 15px;
    height: auto;
    line-height: normal;
    font-size: 12px;
}

.dish-label-value.l-green-label {
    display: none;
}

h2.product-title.transition {
    font-size: 13px !important;
    line-height: 20px !important;
    margin-bottom: 0px !important;
    min-height: auto;
}

.side-title-cont h2.product-title.transition {
    font-size: 12px !important;
    line-height: normal !important;
    padding: 0px;
    margin: 3px 0;
    min-height: auto;
}

.leaf-image {
    padding: 12px 0 !important;
}

.d-card-inr-holder .leaf-image img {
    max-width: 12%;
}

.leaf-image:before {
    width: 40px;
    height: 40px;
}

.leaf-image:after {
    height: 2px;
}
.leaf-image
{
  position: relative;
  padding: 10px 0px 15px 0px;
  text-align: center;
}
.leaf-image::after
{
  content: "";
  position: absolute;;
  width: 100%;
  top: 50%;
  margin-top: -1px;
  height: 1px;
  background-color: #f0f8f2;
  left: 0;
}
.leaf-image::before
{
content: "";
  width: 54px;
  height: 54px;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.leaf-image img{
  position: relative;
  z-index: 4;
  background-color: #fff;
}

.side-title-cont {
    margin: 10px 0;
    min-height: 40px;
}
.dc-dislike-holder.d-flex p.orange {
    margin: 0px;
    font-size: 12px;
}
.dc-dislike-holder.d-flex p.orange span {
    font-size: 12px;
}
.img-holder.d-card-img
{
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
}
.products.sidedishcon .sidedish-content p.title
{
  font-size: 13px;
}
.sidedish-content .image-caption-holder p span
{
  font-size:11px;
}
.sidedish-content .image-caption-holder p.link
{
  font-size:12px;
}
.d-flex.discrd-ftr
{
  margin-top: 8px;
}


.main-dish .d-kal-info span.card-kacal {
    /* float: left; */
    padding: 10px 10px 10px 0;
}
.day.xyz-fin.editable-items .sidedish {
    padding: 10px;
    height: auto;
}

.day.xyz-fin.editable-items .sidedish .sidedish-content {
    position: relative;
}

.day.xyz-fin.editable-items .sidedish .image-caption-holder {
    position: absolute;
    background: rgba(255,255,255,0.5);
    padding: 14px;
}

.day.xyz-fin.editable-items .sidedish .image-caption-holder p span {
    padding-right: 7px;
    font-size: 12px;
}
.day.xyz-fin.editable-items .sidedish .image-caption-holder p {
    line-height: 1;
}

.day.xyz-fin.editable-items .products .main-dish span.card-kacal {
    float: left;
    border: 0px;
    padding: 9px 9px 0px 0px;
}

.day.xyz-fin.editable-items .main-dish p.sbold, .day.xyz-fin:not(.delivered) .side-dish-kcal b.sbold {
    font-size: 12px;
    /*color: #12bdc7;*/
    text-decoration: underline;
}

.day.xyz-fin.editable-items .side-dish-kcal span.card-kacal {
    float: left;
    padding: 10px 10px 10px 0;
}

.day.xyz-fin.editable-items .products.sidedishcon {
    padding-bottom:15px;
   /* margin: 15px 0;*/
    height: auto;
}

.day.xyz-fin.editable-items .products .chosen .d-kal-info {
    margin-bottom: 0px !important;
}

.day.xyz-fin.editable-items .img-holder.d-card-img h3 {
    position: absolute;
    bottom: 35px;
    left: 15px;
    padding: 5px 35px;
    display: none;
}

.day.xyz-fin.editable-items .img-holder.d-card-img {
    position: relative;
}
.day.xyz-fin.editable-items .edit-label {
    position: absolute;
    /* bottom: 13%; */
    bottom:0;
    width: 100%;
    padding: 10px 85px 10px 10px;
    z-index: 99;
}
.day.xyz-fin .edit-label{
    z-index: 99;
}
.day.xyz-fin.editable-items span.dish-label-value.l-green-label {
    margin: 0px;
    padding: 3px 10px;
    width: auto;
    display: table;
    font-size: 12px;
    text-align: left;
}

.day.xyz-fin.editable-items h2.product-title.transition {
    color: #fff;
}
.day.xyz-fin.editable-items .products .chosen:hover h2.product-title.transition,
.day.xyz-fin.editable-items h2.product-title.transition:hover {
    /*color: #12BEC7;*/
}
.day.xyz-fin.editable-items .img-holder.d-card-img:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    z-index: 9;
}

.day.xyz-fin.editable-items .img-holder.d-card-img .image_click.clickable::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 90%);
}
.day.xyz-fin.editable-items .inner.d-card-caption.clickable .main-dish p.sbold {
    margin: 10px 0px;
}
.day.xyz-fin.editable-items .sidedish-content p.image {
    /*height: 225px;*/
    height: 170px;
    background-position: 50%;
    background-size: cover;
}
.day.xyz-fin.editable-items .inner.d-card-caption.clickable {
    padding-top: 0px;
}
.day.xyz-fin.editable-items .prod-rat-ics{
    z-index: 10000;
}
.day.xyz-fin.editable-items .dc-dislike-holder {
    margin: 10px 0;
}
.img-holder.d-card-img h2.product-title.transition
{
  min-height: auto;
}
.inner.d-card-caption .main-dish > p,
.inner.d-card-caption .side-dish-kcal > p b
{
  margin-bottom: 0px !important;
  text-decoration: none;
}
.inner.d-card-caption .side-dish-kcal .d-kal-info
{
  margin-top: 0px !important;
}
.inner.d-card-caption .main-dish .d-kal-info
{
  background-color: transparent;
}
.main-dish .d-kal-info
{
  margin-top: 0;
padding-top: 0;
margin-bottom: 0 !important;

}
.day.xyz-fin.editable-items .img-holder.d-card-img
{
  margin-bottom: 15px;
}
.day.xyz-fin.editable-items .sidedish
{
      padding: 15px 15px 0 15px;
}
.product.transition.d-card-inr-holder .side-dish-kcal
{
  background-color: #f5f5f5;
  margin-left:-20px;
  margin-right:-20px;
}
.product.transition.d-card-inr-holder .side-dish-kcal .d-kal-info
{
  margin-left: 0;
  margin-right: 0;
  padding-top: 0px;
}
.side-dish-kcal p
{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  margin: 0px;
}
.products.sidedishcon > h3 {
    text-align: center;
    margin: 0px;
    padding: 0px;
    color: #001f3d;
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
    line-height: 1;
}

/*feedback review css*/
.general-feedback.feedback-page h5.duration2 {
    margin: 20px 0 !important;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
}
.general-feedback.feedback-page {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    flex: unset;
}
.general-feedback .button-regular {
    width: auto !important;
    max-width: unset !important;
}
.general-feedback.feedback-page .bg-gray-bordered.padded.rating-side b {
    text-align: right;
    float: left;
    padding-right: 10px;
}
.general-feedback.feedback-page .bg-gray-bordered.padded.rating-side span {
    text-align: left;
    float: left;
    margin-bottom: 5px;
}
.general-feedback.feedback-page .bg-gray-bordered.padded.rating-side {
    display: table;
    width: auto;
    margin: 0 auto;
}
.general-feedback.feedback-page .bg-gray-bordered.padded {
    margin: 0 auto;
    text-align: center;
    width: 70%;
}
.container-fluid.bottom-row.m-0 .row.step-holder.bottom-buttons-holder .col-lg-9.bg-1-2 {
    display: none;
}
.general-feedback.feedback-page .container-fluid.bottom-row.m-0 .row.step-holder.bottom-buttons-holder .col-12.col-lg-3.m-0.p-0 {
    display: table;
    margin: 0 auto !important;
    text-align: center;
    width: auto;
    flex: initial !important;
    max-width: 100%;
}
.general-feedback.feedback-page .container-fluid.bottom-row.m-0 .row.step-holder.bottom-buttons-holder .col-12.col-lg-3.m-0.p-0 .button-regular.transition.next.paper-bc {
    width: 100%;
    padding: 10px 20px;
    height: auto !important;
    min-height: auto !important;
}
.general-feedback.feedback-page .bg-gray-bordered.padded.rating-side span.icon-star.mx-1.letter-xxx.green:before {
    margin-top: -8px !important;
    float: left;
}

/*feedback review for dish css*/
.dish-feedback.feedback-page .products {
    width: 33.3%;
    float: left;
    padding: 10px;
    flex: initial !important;
    height: 100%;
    margin: -5px !important;
    -ms-flex: initial !important;
}
.dish-feedback.feedback-page .d-flex.custom-scrollbar.horizontal-scrollbar.mb-0 {
    display: inline-block !important;
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    overflow: initial;
}
.dish-feedback.feedback-page .holder .time-programm-group{
    margin: 0px 10px 10px 0px;
}
.dish-feedback.feedback-page .product.transition.chosen,.dish-feedback.feedback-page .product.transition.not{
    border: 1px solid #d2d2d2;
    padding: 10px;
}
.dish-feedback.feedback-page .product.transition.chosen {
    border: 1px solid #12BEC7;
}
.dish-feedback.feedback-page .bg-gray-bordered.padded {
    padding: 10px 0px !important;
}
.dish-feedback.feedback-page .bg-gray-bordered.padded textarea.custom-scrollbar {
    padding-left: 15px;
}
.dish-feedback.feedback-page .products .img-holder {
    height: 160px;
}
.dish-feedback.feedback-page .products h2.product-title.transition {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
}
.dish-feedback.feedback-page .form-input span.has-error {
    position: initial;
}
.dish-feedback.feedback-page .bg-gray-bordered.padded.rev-rating {
    position: relative;
}
.dish-feedback.feedback-page span.has-error {
    right: auto;
    left: 0;
}
.dish-feedback.feedback-page .holder .time-programm-group {
    margin-top: 30px;
}
.general-feedback.bottom-buttons-holder .button-regular,
.dish-feedback.bottom-buttons-holder .button-regular {
    width: auto;
    height: auto;
    display: table;
    margin: 0 auto;
    min-height: auto;
    padding: 7px 30px;
}
.general-feedback.bottom-buttons-holder .button-regular {
    width: auto;
    height: auto;
    display: table;
    margin: 0 auto;
    min-height: auto;
    padding: 7px 30px;
}
.general-feedback .form-input span.has-error {
    position: initial;
}
.general-feedback span.has-error.letter-xs.red-color {
    position: static;
    float: none!important;
    text-align: center!important;
    width: 100%;
    display: table;
}
.modal-header-styled .title-block p .m-title {max-width: 100%;margin-top: 10px;}
.modal-header-styled .title-block p span.ar-golr-l {height: 0px;display: none;}
.modal-header-styled .title-block p.w-100 {display: inline-block;width: 100%;}
.modal-header-styled .title-block p span.ar-golr-r {display: none;height: 0px;}
.popup-dash-calendar-fix {line-height: 1.2;}
.button-regular.next.transition.paper-bc {max-width: 100%; margin: 10px 0 0 0; padding: 5px 10px; height: auto; width:10em; }
.modal-message.pop-style .popup-dash-calendar-fix input.standart-input,.modal-message.pop-style .popup-dash-calendar-fix input {height: 40px;padding: 0px 10px;float: left;margin: 0 0 10px 0;}
.modal-message.pop-style .popup-dash-calendar-fix textarea.medical-text-area {resize: none;margin: 10px 0;}
.modal-message.pop-style textarea.medical-text-area {resize: none;margin: 10px 0;}
.modal-message.pop-style .address-edit-div input {height: 45px;padding-left: 10px;;float: left;margin-bottom: 10px;}
.form-input.shadowed.b-rounded-blue.styled-select.pointer {border: none;box-shadow: none;}
.modal-message.pop-style .address-edit-div input[type="checkbox"] {height: auto;top: 8px;}
.modal-message.pop-style .address-edit-div label {margin: 0px;line-height: 1;}
.modal-message.pop-style .address-edit-div .pointer {display: inline-block;width: 100%;height: 35px;margin-bottom: 10px;float: left;}
.modal-message.pop-style .popup-select-area ul.select2-selection__rendered {padding: 0px;width: auto;}
.modal-message.pop-style .popup-select-area ul.select2-selection__rendered li {font-size: 12px;padding: 0px 5px;display: table;width: auto;}
.modal-custom-body .popup-select-area b.food-prefs-header {text-align: left !important;float: left;}
.modal-custom-body .addresses-fields h3 {text-align: left;width: 100%;clear: left;}
.modal-custom-body .addresses-fields .addr-checkbox label {text-align: left;float: left;}
.modal-custom-body .addresses-fields input[type="checkbox"] {top: 2px !important;}
.modal-custom-body .dish-holder .dish-image {height: 300px;object-fit: cover;}
ul.select2-results__options li {text-align: left;}

.alice-carousel__prev-btn,.alice-carousel__next-btn  {
    position: absolute;
    width: auto !important;    
    top: 50%;    
    bottom: 50%;    
    transform: translate(0,-50%);
}
.alice-carousel__prev-btn{
    left: -20px;
}
.alice-carousel__next-btn {
    right: -20px;
}
/*.alice-carousel__prev-btn p.alice-carousel__prev-btn-item {
    padding: 0px;
    background: url('../assets/left-arrow.svg') no-repeat;
    width: 34px;
    height: 34px;
    display: inline-block;
}
.alice-carousel__next-btn p.alice-carousel__next-btn-item {
    padding: 0px;
    background: url('../assets/right-arrow.svg') no-repeat;
    width: 34px;
    height: 34px;
    display: inline-block;
}*/

.alice-carousel__wrapper {
    padding: 0 15px !important;
}
.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
    width: 30px;
    height: 30px;
    line-height: normal;
    background: #696969;
    border-radius: 100%;
    text-align: center;
    position: relative;
}
.alice-carousel__next-btn p.alice-carousel__next-btn-item span:before{
    color: #fff;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f054";
    position: relative;
    top: -3px;
    left: 1px;
}
.alice-carousel__prev-btn p.alice-carousel__prev-btn-item span:before {
    color: #fff;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f053";
    position: relative;
    top: -3px;
    left: -1px;
}
.alice-carousel__next-btn p.alice-carousel__next-btn-item span:after, .alice-carousel__prev-btn p.alice-carousel__prev-btn-item span:after{
    display: none;
}
.readonly_field{
    pointer-events: none;
}
.readonly_field input.b-rounded-blue.w-100 {
    background: rgba(219,241,242,0.5);
}
.day.xyz-fin.editable-items .control-buttons {
    display: none;
}
.day.xyz-fin.editable-items ul.dish-groups.pl-0.mb-0.edit-enabled {
    margin: 0px !important;
}
.product.transition.d-card-inr-holder.chosen {
    border-width: 2px;
}
span.selected_dish {
    display: none;
}
.product.transition.d-card-inr-holder.chosen .selected_dish{
    display: block;
}
p.alice-carousel__prev-btn-item {
    left: 5px;
}
p.alice-carousel__next-btn-item {
    right: 5px;
}
.product.transition.d-card-inr-holder.chosen {
    position: relative;
}
.product.transition.d-card-inr-holder.chosen span.selected_dish {
    position: absolute;
    right: 0;
    z-index: 100;
    top: 0;
    padding: 5px 10px;
    background: #12BEC7;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
}
.product.transition.d-card-inr-holder.chosen span.selected_dish i {
    color: #fff;
    padding-right: 5px;
}
.m-page-menu-modal.xyz-fin .scroll-auto ul li {width: 100%;clear: left;display: inline-block !important;border: 0px;text-align: center;}

.m-page-menu-modal.xyz-fin {padding: 10%;margin: 0;}

.m-page-menu-modal.xyz-fin .scroll-auto ul li a.nav-link:hover {border-bottom: 1px solid #12bdc7;color: #12bdc7 !important;}
.m-page-menu-modal .nav-item .nav-link{
    text-transform: capitalize !important;
}
.xyz-fin.modal-back-layer.ttt {
    z-index: 99999;
  }
.dishcate{display: none;}
.dishcate ul li li {
    padding: 5px 12px;
    font-size: 12px;
    text-transform: capitalize;
    border-radius: 3px;
    border: 1px solid #12BEC7;
    font-family: Montserrat,sans-serif;
    text-align: center;
    margin: 10px;
    width: 100%; 
}
.dishcate ul li li.active {
    background-color: #12BEC7;
    color: #fff;
    border: 1px solid #12BEC7;
    box-shadow: 0 5px 10px 0 rgb(0 0 0/12%);
}
.dishcate li.bg-gold {
    list-style: none;
    padding: 5px 10px;
    background: #000;
    color: #fff;
    width: auto;
    display: table;
    border-radius: 4px;
    line-height: normal;
    font-size: 12px;    
    /*smargin: 10px 0 10px 40px;*/
}
.BrainhubCarousel__customArrows .car-arrow i.fa {
    color: #fff;
    font-weight: 900;
    position: relative;
    background: #696969;
    width: 30px;
    border-radius: 100%;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  .BrainhubCarousel__arrow--disable.BrainhubCarousel__customArrows .car-arrow i.fa {
    background: #c2c2c2;
  }
.dishcate li.bg-gold,.dish-groups li.bg-gold {    
    background-color: #001f3d !important;
    border-color: #001f3d !important;
}
.dishcate li.bg-gold span,.dish-groups li.bg-gold span{
    -webkit-animation: blink 1.5s linear infinite;
    -moz-animation: blink 1.5s linear infinite;
    -ms-animation: blink 1.5s linear infinite;
    -o-animation: blink 1.5s linear infinite;
    animation: blink 1.5s linear infinite;
    color: #fff;
    font-size: 12px;
}
.dishcate li.bg-gold {
    float: left;
    width: auto;
    margin: 10px 0;
}
.dishcate p.click-on.mobile_show {
    float: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    max-width: 200px;
    margin-top: 10px;
    margin-left: 10px;
}
.dishcate .BrainhubCarousel__container{
    padding-bottom: 0px;
}
.click-on.mobile_show{
    display: none;
}
@-webkit-keyframes blink { 
    0% {opacity: 1;}
    50%{opacity:1;}
    100%{opacity:0;}
 }
 @-moz-keyframes blink { 
    0% {opacity: 1;}
    50%{opacity:1;}
    100%{opacity:0;}
 }
 @keyframes blink { 
    0% {opacity: 1;}
    50%{opacity:1;}
    100%{opacity:0;}
 }
.dishcate li.bg-gold:hover,.dish-groups li.bg-gold:hover{
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
}
.dishcate {width: 100%;float: left;}
.dishcate .alice-carousel .alice-carousel__wrapper {margin: 0 15px;width: auto;display: block;}
.dishcate p.alice-carousel__prev-btn-item, .dishcate p.alice-carousel__next-btn-item {
    top: -15px;
}
.address_div h1 {
    font-size: 20px;
    text-transform: uppercase;
    color: #12bdc7;
    font-weight: 600;
    position: relative;
    padding: 0 20px;
  }
  .address_div h1 sub {
    font-size: 17px;
    text-transform: lowercase;
    position: relative;
    top: 0px;
    font-weight: 600;
    color: #12bdc7;
  }
  .address_div label {
    display: inline-block;
    width: 100%;
  }
  .address_div label strong {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    padding-right: 15px;
  }
  .address_div {
    width: 100%;
    margin: 50px 0 !important;
    background: #f9f9f9;
    padding: 20px;
  }
  .dislist{
    float: left;
    background: #fff;
    padding: 20px;
  }
  .address_div .addr_sec {
    background: #fff;
    padding: 20px !important;
    border-radius: 5px;
    width: 32%;
    float: left;
    margin: 0 10px;
    border: 1px solid #e4ebee;
  }

.dishcont {
    background: #fff;
    display: flex;
    flex: 0 0 48%;
    border: 1px solid #e4ebee;
    margin: 10px;
  }
  .dislist h2 {
    margin: 0px;
    padding: 0px;
    color: #12bdc7;
    font-size: 18px;
    text-transform: uppercase;
  }
  .fpdiv{
    display: flex;
  }
  .image_click.clickable {
    position: relative;
  }
  .image_click {
    height: 200px;
    background-position: 50%;
    background-size: cover;
  }
  .col-2.col-sm-2.btnb.text-center.backtotop.mtop{
    display: none;
  }
  .Right .BrainhubCarousel__arrow--disable.BrainhubCarousel__customArrows .car-arrow i.fa {
    left: 5px;
  }
  .Right h3{display: none;}
  #dish-screen .plans-card-image .card-type {
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    padding: 5px 15px;
    color: #fff;
  }
  .form-input input, .bg-gr-light-2 label, .bg-gr-light-2 span, .form-input select {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
  }
.prod-rat-ics
{
  position: absolute;;
  right: 0;
  bottom: -10px;
}
.d-like-btn span {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    visibility: hidden;
    transition: .6s;
    z-index: -2;
    font-size: 2px;
    color: transparent;
    font-weight: 400;
}
.d-like-btn.active {
    animation: size .4s;
    color:#e23b3b;
  }
  .d-like-btn.active span {
    bottom: 40px;
    font-size: 14px;
    visibility: visible;
    animation: fade 1s;
    z-index: 10;
}
.d-like-btn {
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}
.d-like-btn {
    background-color: #12bdc7;
    border: 1px solid #12bdc7;
    padding: 12px 10px 10px;
    margin-left: 10px;
    position: relative;
    top: 5px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 9px 7px 10px!important;
    transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
}
@keyframes fade {
    0% {color:transparent;}
    50% {color:#fff;}
    100% {color:transparent;}
  }
@keyframes size {
    0% {padding:10px 12px 8px;}
    50% {padding:14px 16px 12px;
        margin-top:-4px;}
    100% {padding:10px 12px 8px;}
}
button.d-emoji-display {
    background-color: #fff;
    border: 1px solid #222;
    border-radius: 5px;
    padding: 12px 10px 10px;
    margin-left: 5px;
    position: relative;
    top: 7px;
    display: none;
}
.rate-emoji {
    cursor: pointer;
}

.rate-emoji {
    width: 32px;
    height: 32px;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 100%;
    position: relative;
    top: -3px;
    margin: 3px;
    background: #fff;
}
.img-holder.d-card-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.img-holder.d-card-img a.rate-emoji img.rateok{
    width: 20px;
    height: 20px;
    margin-left: 6px;
    margin-top: 3px;
}
.img-holder.d-card-img h3 {
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    padding: 6px 18px;    
    font-size: 14px;
    margin: 0;
    font-weight: bolder;
}
.products.sidedishcon
{
    background-color: #b7e2e5;
    /*border: 1px solid #12bdc7;*/
}
.products .product-title
{
  font-weight: 500 !important;
  color: #222;
}
span.card-kacal {
    border: 0px !important;
    font-weight: 500;
}
.products .product h3
{
  font-size: 12px;
}
.bottom-container .styled-select select
{
  background-color: rgba(255,255,255,0.3);
  color: #fff;

}
.ingridients-contain .ingridients{
 padding-left: 0;
}
.ingridients li {
    list-style: none;
    padding: 0;
  }
  .button-small.transition.next.paper-bc {
    margin-top: 10px;
}
.sidedish p.image
{
position: relative;
}
.sidedish p.image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.sidedish
{
  position: relative;
  height: 50%;
}
.sidedish-content
{
  right: 0;
  left: 0;
}
.sidedish-content p.title
{
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}
.sidedish-content p
{
  margin-bottom: 0px !important;
}
.sidedish-content p.link
{
  margin-bottom: 0px !important;
  cursor: pointer;
}
.sidedish-content p.link
{
  color: #001f3d;
  font-weight: 500;
  text-decoration: underline;
}
.pdf-icon:hover span
{
color: #fff;
}
.image-caption-holder
{
  position: absolute;
  bottom: 0;
}
.image-caption-holder
{
z-index: 1;
background-color: rgba(255,255,255,0.9);
left: auto;
right: auto;
width: 100%;
padding-left: 15px;
padding-top: 10px;
}
.sidedish-content
{
  height: 100%;
}
.modal-message.pop-style .col-9.stars.text-right {display: none;}

.modal-message.pop-style .left-dish.dish-holder p.regular {margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;}

p.regular-bold.green {margin: 0px;font-size: 12px;
    font-weight: 500;}
.d-card-inr-holder{
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 5px 10px 0 rgb(0 0 0 / 12%);
    margin: 0 15px;
    height: 100%;
}
.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track{
    padding: 0 0 20px 0px !important;
}
.dishcate .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track{
    padding:0px !important;
}
p.click-on.desktop_show {
    margin: 15px 0;
}
@media screen and (max-width: 992px){
    .logo-holder a {
        max-width: 100% !important;
    }
    .BrainhubCarousel__container {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .BrainhubCarousel__container {
        padding-bottom: 10px;
    }
    .today-meal-grid {        
        padding: 0;
    }
    .grey-line-1px {
        margin-top: 20px;
        margin-bottom: 20px;        
    }
    .manager-contacts {
        width: 300px;
        margin: 0 auto;
        text-align: center;
        margin-top: 15px;
    }
    .links-wrap.position-relative .my-auto.set-links {
        margin-left: 40%;
    }
    .links-wrap.position-relative .bg-links {
        background-position: 0px 0;        
    }
    .user-data-wrap.position-relative {
        margin-top: -70px;
        padding-top: 70px;
    }
    div#dish-screen {
        padding: 30px 0px;
    }
    .sign-left-inner{
        width: 95%;
    }
}
.pauseinfo{
    font-weight: 500;
    color: #12BDC7;
    font-size: 22px;
    font-style: italic;
    text-decoration: underline;
    opacity: 70%;
    margin-top: -40px;
    margin-bottom: 15px;
}

.phoneInput {
    position: relative;
  }
  .phoneInput .labelText {
    position: absolute;
    left: 26%;
  }
  .phoneInput.focusInput .labelText{
    display: none;
  }
  .PhoneInputInput, .PhoneInputInput:focus{
    border: none;
  }
  .PhoneInputCountry {
    margin-left: 10px;
  }
  .phoneInput .standart-input.PhoneInput{
    border: 1px solid #d0d0d0;
    line-height: 1.8;
    border-radius: 10px;
    min-height: 55px;
  }
  .phoneInput.focusInput .standart-input.PhoneInput{
    border-color: #001f3d;
  }
  .checkbox-con {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .checkbox-con input {
    margin-top: 2px;
  }
  .checkbox-label-con {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 15px;
    text-align: left;
  }
  .checkbox-sublabel {
    font-size: 12px;
  }
  .textLeft {
    text-align: left;
    display: block;
  }
  .checkbox-label {
  margin: 0;
  line-height: 15px;
}
.sticky-info {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    padding: 0;
    font-size: 20px;
    z-index: 999;
    background: #fff;
    padding: 10px 0;
  }
.step-tabs {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .step-tab-list {
    display: flex;
    margin: 0;
    padding: 10px 0;
    cursor: pointer;
    opacity: 0.5;
  }
.container.checkout-process{
    margin: 0 auto;
}
.step-tab-list.step-tab-active .step-label {
    font-weight: 600;
  }
.step-label {
    font-weight: 600;
  }
  .step-tab-list.step-tab-active {
    opacity: 1;
  }
  .step-tab-list .step-label {
    font-size: 22px;
  }
  .step-indicator.empty {
    opacity: 0.3;
    filter: grayscale(100%);
  }
.step-tab-list .lineIndicator{
    padding: 0 20px;
    color: #D0D0D0;
    margin-top: 45px;
}
.step-bottom-nav {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
.step-bottom-nav-right{
    display: flex;
}
.button-small.continue{
    background-color: #001f3d !important;
    padding: 10px 10px;
    width: auto;
    height: auto;
    min-width: 10rem;
    min-height: 3rem;
}
.button-small.back-btn{
    margin-right:10px;
    border-color: #001f3d !important;
}
.button-small.back-btn span{
    color: #001f3d;
}
.button-small.light-blue-btn{
    margin-right:10px;
    border-color: #12bdc7 !important;
    color: #12bdc7;
}
.button-small.transition.back-btn.paper-bc:hover {
    border-color: #12bdc7 !important;
    color: #12bdc7;
  }
  .button-small.transition.light-blue-btn.paper-bc:hover {
    border-color: #001f3d !important;
    color: #001f3d;
  }
.day-info {
    margin-top: 10px;
  }
/* .step-bottom-nav-right .button-small.continue span:first-child,.step-bottom-nav-right .button-small.back-btn span:first-child,.summary-con-right .button-small.continue span:first-child{
    margin-right: 10px;
 } */
 .button-small.continue span.icon,.button-small.back-btn span.icon {
    margin-left: 10px;
  }
.button-small.continue:hover {
    background: #12bdc7 !important;
    border: 1px solid #12bdc7;
}
.button-small.continue span{
    color: #fff;
}
.checkout-process .paper-bc *,.checkout-process .paper-bc,.checkout-process .day-info-action .button >span{
    font-weight: 500;
}
.step-form-group {
    margin: 20px 0;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.step-form-group h5 {
    margin: 0;
    padding: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    color: #001F3D;

}
.step-form-input > label {
    font-size: 17px;
    color: #001f3d;
    font-weight: 500;
    text-transform: capitalize;
    margin: 10px 0;
  }
  .step-form-input {
    margin: 0 0 20px;
  }
.checkout-diet-list {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    
  }
  .checkout-process .ing-info span, .checkout-process .side-dish-kcal .ing-info span{
    font-size: 13px;
  }
  .diet-list {
    padding: 10px 20px;
    background: #F1F9F9;
    border-radius: 5px;
    margin-right: 10px;
    color: #000;
    font-size: 22px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .diet-list span {
    color: #000;
    font-size: 14px;
    margin-left: 5px;
  }
  .diet-list.diet-active {
    background: #001F3D;
    box-shadow: 0 10px 16px 0 rgba(0, 31, 61, 0.2);
    position: relative;
  }
  .checkIcon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .diet-list.meal-type-l.diet-active .checkIcon {
    top: 3px;
  }
  .diet-list.diet-active span {
    color: #fff;
  }
  .radio-list,.checkbox-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }
  .radio-list li,.checkbox-list li {
    margin: 0;
    padding: 0;
    margin-right: 15px;
  }
  .radio-list.horiz-list li{
    margin-bottom: 7px;
    margin-right: 0;
  }
  .radio-list input[type="radio"],.checkbox-list input[type="checkbox"]{
    display: none;
}

.radio-list label,.checkbox-list label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 14px;
}
.radio-list label{
    display: flex;
    flex-direction: column;
}
.radio-list.horiz-list {
    flex-direction: column;
  }
.radio-list label:before,.checkbox-list label:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 2px solid #ccc;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 3px;
        background-color: #fff;
        transition: border-color 0.3s, background-color 0.3s;
}
.radio-list label span {
    color: #959595;
  }
.radio-list input[type="radio"]:checked + label:before,.checkbox-list input[type="checkbox"]:checked + label:before {
    border-color: #000;
}

.radio-list label:after{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    left: 5px;
    top: 8px;
    opacity: 0;
    transition: opacity 0.3s;
    background: #000;
}
.checkbox-list label:after{
    content: '\2714'; /* Checkmark */
    display: inline-block;
    color: #000;
    position: absolute;
    left: 4px;
    top: -3px;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s;
}
.checkbox-list label:before,.checkbox-list label:after{
    border-radius: 25%;
}
.radio-list input[type="radio"]:checked + label:after,.checkbox-list input[type="checkbox"]:checked + label:after {
    opacity: 1;
}

.priceCon {
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
.step-bottom-nav.border-top {
    padding-top: 30px;
    border-color: #000 !important;
    align-content: center;
    align-items: end;
    justify-content: space-between;
}
.priceCon .price {
    font-size: 30px;
    font-weight: 600;
    color: #001F3D;
    margin: 0;
    padding: 0;
}
.summary-con-right h2 {
    margin-bottom: 25px;
  }
.discountPrice {
    color: #001F3D;
    font-size: 24px;
    text-decoration: line-through;
    margin: 0;
    padding: 0;
    line-height: 20px;
    opacity: 0.5;
}
.discountPerCon {
    display: flex;
    font-size: 20px;
    border: 1px dashed #12BDC7;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    margin-left: 10px;
    color: #12BDC7;
    font-weight: 700;
    background: #F1F9F9;
    line-height: 40px;
    max-height: 35px;
    margin-top: 10px;
}
.discountOffCon {
    display: flex;
    margin: 0;
    padding: 0;
  }
.discountPerSub {
    margin: 0;
    color: #12BDC7;
    margin-left: 5px;
    font-weight: 700;
    padding: 0;
    font-size: 13px;
}
.discountPer {
    font-size: 20px;
    margin: 0;
    color: #12BDC7;
    font-weight: 700;
    padding: 0;
}
.savePrice {
    color: #12BDC7;
    margin: 0;
}
.savePrice span {
    color: #12bdc7;
    font-weight: 600;
  }
.bagPrice,.bagPrice span {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    margin: 0;
    opacity: 0.7;
  }
  
  .step-form-input .letter-xs.pointer {
    display: flex;
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
    width: 100%;
    height: 3.5em;
    flex: 1;
    flex-wrap: wrap;
    min-height: 38px;
  }
  .slide-dates {
    display: flex;
    margin: 0;
    padding: 0;
    max-width: 50%;
  }
  .slide-date {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .slide-date-con {
    border-radius: 12px;
    margin: 0 8px;
    padding: 5px;
    background: #fff;
    border: 1px solid #001f3d;
    cursor: pointer;
    width: 100%;
  }
  .slide-date-top {
    margin: 0;
    font-weight: 700;
  }
  .slide-date-bt {
    margin: 0;
  }
  .slide-dates .slide-date-con:first-child {
    margin-left: 0;
  }
  .slide-dates .BrainhubCarousel .slide-date-con:first-child {
    margin-left:8px;
  }
.slide-date-con.active-date {
    background: #001f3d;
    color: #fff;
  }
  .slide-date-con.active-date .slide-date-top, .slide-date-con.active-date .slide-date-bt span {
    color: #fff;
  }
  .total-kcal {
    font-weight: 500;
  }
  .product-list-container,.BrainhubCarousel {
    position: relative;
  }
  .product-list-container .products.sidedishcon > h3 {
    margin-bottom: .5rem;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    line-height: 1.8;
    padding-top: 0;
  }
  .product-list-container .sidedish-content .image-caption-holder{
    padding-bottom: 10px;
  }
  .product-list-container .sidedish p.image img,.product-list-container .sidedish p.image img{
    border-radius: 10px;
  }
  .product-carousel {
    position: relative;
  }
  .product-carousel .BrainhubCarousel__dots {
    position: absolute;
    top: 7px;
    left: 10px;
    z-index: 999;
  }
  .product-list-container .BrainhubCarousel__trackContainer {
    margin-left: 10px;
  }
.product-list-container .product.transition {
    display: flex;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    margin-bottom: 20px;
    min-height: 160px;
  }
  .product-list-container .product-info {
    margin-left: 20px;
    flex-basis: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
  }
  .product-list-container .image_click.clickable {
    position: relative;
  }
  .product-list-container .image_click {
    height: 185px;
    background-position: 50%;
    background-size: cover;
    position: relative;
  }
.product-list-container .img-holder.d-card-img img {
    border-radius: 10px;
  }
 .product-list-container .product.transition .img-holder.d-card-img {
    margin: 0;
    padding: 0;
    flex-basis: 65%;
  }
.product-list-container .img-holder.d-card-img h3{
    border-radius: 0 0px 10px 10px;
    width: 100%;
}
.product-list-container .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft {
    /* position: absolute;
    right: 40px;
    left: auto;
    top: -20%; */
  }

.BrainhubCarousel__customArrows .car-arrow .fa.fa-chevron-right,.BrainhubCarousel__customArrows .car-arrow .fa.fa-chevron-left {
    background: #F1F9F9;
    color: #001F3D;
  }
  .BrainhubCarousel__arrow--disable.BrainhubCarousel__customArrows .car-arrow i.fa {
    background: #e4eeee;
    color: #001F3D;
  }
  .product-list-container .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowRight {
    /* position: absolute;
    top: -20%; */
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot{
    padding: 0 5px !important;
    background: transparent;
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot::before{
    background: rgba(0, 0, 0, 0.3) !important;
    border: 1px solid rgba(255,255,255,0.2)!important;
    width: 12px !important;
    height: 12px !important;
  }
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
    background: rgba(0, 0, 0, 0.5)!important;
    border: 1px solid rgba(255,255,255,0.5)!important;
  }
  .product-list-container{
    display: grid;
    grid-template-columns: auto auto auto;
  }
    .product-list-container .sidedish p.image {
        position: relative;
        height: 190px;
    }
    .product-list-container .img-holder.d-card-img h3 {
        position: absolute;
        bottom: 0;
        background-color: rgba(0,0,0,.5);
        color: #fff;
        padding: 6px 18px;    
        font-size: 14px;
        margin: 0;
        font-weight: bolder;
    }
  .product-list-container .inner.d-card-caption {
    padding: 0;
    min-height:55px;
  }
  .product-list-container .d-flex.discrd-ftr {
    flex-direction: column;
  }
  .notpaid-notifiy {
    background: #FCF6EC;
    text-align: center;
    padding: 15px 0;
  }
  .notpaid-notifiy p {
    margin: 0;
  }
  .notpaid-notifiy p.info-text{
    color: #B7995E;
  }
  .notpaid-notifiy strong {
    font-weight: 800;
  }
.day-info-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .day-info-top h5{
    color: #001f3d;
  }
.edit-day .day-info-top{
    margin-bottom: 0;
    align-items: center;
  }
.day-info-action {
    display: flex;
}
.manager-con {
    display: none;
  }
.product-list-container .BrainhubCarousel__customArrows .car-arrow .fa.fa-chevron-left,.product-list-container .BrainhubCarousel__customArrows .car-arrow .fa.fa-chevron-right{
    background: #001f3d;
    color: #f1f9f9;
}
.wrap-progress-page .checkout-process .button-small.transition.paper-bc{
    width: auto !important;
    padding: 5px 25px !important;
    min-width: auto;
    border: 1px solid #001f3d;
    height: auto;
}
.wrap-progress-page .checkout-process .button-small.transition.paper-bc:hover{
    border: 1px solid #12bdc7;
}
.summary .step-form-group h5 {
    font-size: 32px;
  }
.wrap-progress-page .checkout-process .day-info-action  .button-small.transition {
    min-height: 2.5em;
    height: 3rem;
}
  .day-info-action .button {
    border: 1px solid #F1F9F9;
    line-height: 1.5em;
    margin: 0;
    cursor: pointer;
    display: flex;
    height: 3rem;
    border-radius: 5px;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    background: #F1F9F9;
    padding: 0 20px;
  }
  .day-info-action .skips {
    background: #001F3D;
    margin-left: 10px;
    margin-right: 0;
  }

.product-list-container h2.product-title.transition {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 20px;
    color: #001F3D;
    margin-bottom: 10px !important;
    min-height: auto;
  }

  .mealtypes {
    margin-bottom: 30px;
  }
  .day-dish {
    border: 2px solid transparent;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .day-dish.select-dish {
    border: 2px solid #12bdc7;
    margin-right: 10px;
    border-radius: 10px;
    position: relative;
  }
  .day-dish.select-dish span.selected_dish {
    position: absolute;
    left: 0;
    z-index: 100;
    top: 0;
    padding: 5px 10px;
    background: #12BEC7;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    display: block;
    border-radius:10px 0 5px 0;
}
.day-dish.select-dish span.selected_dish i {
    color: #fff;
    padding-right: 5px;
}
.product-list-container .day-dish.select-dish .img-holder.d-card-img img {
    border-radius: 10px 0 0px 10px; 
  }
  .product-list-container .day-dish.select-dish .img-holder.d-card-img h3 {
    border-radius: 0 0 0px 10px;
  }
  .summary-container {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .summary-con-right .priceCon .price {
    font-size: 32px;
    font-weight: 700;
  }
  
  .cutlery-con {
    padding: 10px 0;
    border-top: 1px dashed #DDDDE1;
    border-bottom: 1px dashed #DDDDE1;
    margin: 0 0 10px;
  }
  .cutlery-con .step-form-input > label {
    line-height: 20px;
  }
  .cutlery-con .step-form-input {
    margin: 0;
  }
  .summary-con-left {
    background: #F1F9F9;
    padding:35px 30px 0;
    border-radius: 10px;
    width: 35%;
  }
  .summary-con-right {
    justify-content: center;
    display: flex;
    align-items: center;
    align-content: center;
    width: 60%;
    padding: 20px;
  }
  .wrap-progress-page  .summary-con-right .button-small.transition.continue.paper-bc{
    width: 15em !important;
  }
  .btn-con {
    display: block;
  }
  .summary-con-right .button-small.continue {
    display: inline-block;
    padding: 10px 25px;
    font-weight: 500;
    border: 1px solid #001f3d;
    border-radius: 5px;
    cursor: pointer;
  }
  .summary-con-right .button-small.continue span {
    font-weight: 500;
  }
  .summary-con-right .button-small.continue:hover{
    border: 1px solid #12bdc7;
  }
.summary-label-con .summary-label {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.7;
  }
  .summary-label-con {
    margin-bottom: 25px;
  }
  .summary-label-con h6 {
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
  }
  .sign-container {
    min-height: 100vh;
    display: flex;
  }
  
  .sign-left{
    background: url("../assets/sign-bg.png") no-repeat scroll 0 0;
    background-size: cover;
    flex-basis: 50%;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .sign-right {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
.sign-left-inner {
    margin-right: 0;
    width: 45%;
    margin-top: 10%;
  }
 
  .sign-left-inner h5 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #fff;
    text-shadow: 2px 2px rgba(0,0,0,.4);
  }
  .sign-left-inner p {
    color: #fff;
    text-shadow: 2px 2px rgba(0,0,0,0.4);
    font-size: 26px;
    font-weight: 500;
  }
  .sign-in-top {
    text-align: left;
  }
  .sign-in-top .colorTitle {
    font-size: 20px;
    font-weight: 600;
    color: #001F3D4D;
  }
  .sign-in-top span.colorTitle {
    text-decoration: underline;
    color: #001f3d;
  }
  .sign-in-top .colorTitle:first-child {
    margin-right: 10px;
  }
  .sign-up-row{
    display: flex;
  }
  .pauseinfo{
    font-weight: 500;
    color: #b7995e;
    font-size: 22px;
    font-style: italic;
    text-decoration: underline;
    opacity: 70%;
    margin-top: -40px;
    margin-bottom: 15px;
}
.logo img {
    max-width: 180px;
  }
  .logo {
    margin-top: 30px;
  }
.buffer-text{
    color: #C35151;
}
.renew-alert {
    display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background: #F1F9F9;
  padding: 10px 0;
  margin-bottom: 15px;
}
  #dashboard .renew-alert .renew-alert-info p {
    margin: 0 0 10px 0;
  }
  .renew-alert-info strong {
    font-weight: 700;
  }
  .renew-content .radio-list {
    flex-direction: column;
  }
  .renew-content .radio-list label {
    flex-direction: row;
  }
  .renew-footer {
    display: flex;
    justify-content: space-evenly;
  }
  
.container.checkout-process {
    max-width: 1440px;
  }
.checkout-process .popup-select-area.step-form-input {
    width: 40%;
  }
.step-tabs {
    justify-content: space-between;
  }
  .sign-b-left {
    width: 100%;
   min-height: 100vh;
}
.sign-b-left .sign-up-form{
    margin: 0 auto;
}
.sign-b-right { 
    background-image: url('../assets/sign-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    z-index: 1;
   position: relative;
}

.sign-b-right::before {
    content: "";
    display: block; 
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(178,221,204);
    background: linear-gradient(0deg, rgba(178,221,204,0.7063200280112045) 0%, rgba(68,110,94,0.8267682072829132) 0%, rgba(241,249,246,0) 51%);
} 
.sign-b-right-details{
    position: absolute;
    bottom: 2%;
    left: 5%;
}
.sign-b-right-details > h2 {
    margin: 0px;
    font-family: 'Garamond-Basiligo';
    font-weight: 500;
    font-size: 48px;
    color: white;
    line-height: 48px;
    margin-bottom: 20px;
}
.sign-b-right-details > h3 {
    margin: 0px;
    text-align: left;
    color: white;
    font-family: 'Garamond-Basiligo';
    font-size: 24px;
}
.sign-b-right-details > h6 {
    margin: 0px;
    text-align: left;
    color: white;
    font-size: 24px;
}
 .sign-b-right-details > h3:first-child{
    font-family: serif;
}
.app-store-gpay {
    margin-top: 10px;
}
.app-store-gpay a:first-child {
    margin-right: 20px;
  }
.img-app,.img-play {
    width: 140px;
    height: 45px;
}
.sign-b-left .logo{
    margin: 0 auto;
    width: 70%;
    margin-top: 5%;

}
.sign-b-left h1 {
    font-size: 32px;
}
.sign-b-left h3 {
    font-size: 18px;
}
.sign-b-left h1 ,.sign-b-left h3 {
    text-align: center;
    color: black;
    margin-bottom: 0px;
    margin-top: 0px;
    font-family: 'Garamond-Basiligo';
    font-weight: 400;
}

.sign-up-form .sign-up-button .form-button {
    font-weight: bold;
  }
  .standart-input.b-rounded-blue:focus {
    border-color: #001f3d;
  }
  .sign-right .logo-icon{
    display: none;
  }
.textLeft{
    color: #001f3d;
    font-size: 15px;
    font-weight: 500;
}
.calendar-input.pointer img {
    position: absolute;
    right: 15px;
    top: 10px;
  }
.marquee {
    display: none;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    height: 50px;
    width: 100%;
    /* Full width of its parent */
    white-space: nowrap;
}

/*.marquee p {
    /*position: absolute;
    width: auto;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;    
    transform: translateX(100%);    
    animation: scroll-left 20s linear infinite;
    font-size: 18px;
}
@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
*/
.mobile-header{
    display: none;
}

.mobile-action-btn{
    display: none;
}
.product-list-container .Right h3,.product-list-container .Left h3{
    display: block;
    font-size: 20px;
  font-weight: 600;
}
.product-list-container .products.sidedishcon{
    background-color: transparent;
}
.checkout-process .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track{
    padding: 0 !important;
}
@media screen and (min-width: 992px) and (max-width: 1199px){
    .sign-left{
        align-items: end;
        position: relative;
    }
    .sign-left-inner {
        width: 70%;
        margin-top: 0;
        position: relative;
        z-index: 100;
    }
    .sign-left-inner h5, .sign-left-inner p{
        text-shadow: 2px 2px rgba(0,0,0,.8);
    }
    .sign-left::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.18);
    
    }
}
@media screen and (max-width: 767px){
    .product-list-container{
        grid-template-columns: auto ;
      }
    .day-info-action{
        width: 100%;
    }
    .slide-dates{
        max-width: 100%;
    }
    .wrap-progress-page .checkout-process .day-info-action .button-small.transition.continue{
        width: 100% !important;
    }
    .slide-date-con{
        margin:0 5px;
        padding: 5px 8px;
        border-radius: 10px;
    }
    .bagPrice {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
      }
      .checkout-process.menus .step-bottom-nav-right {
        flex-direction: column;
      }
      .checkout-process.menus .step-bottom-nav-right .light-blue-btn,.checkout-process.menus .step-bottom-nav-right .back-btn {
        display: none;
      }
      .checkout-process.menus .step-bottom-nav-right .button-small.transition.continue.paper-bc{
        width: 100%;
      }
      .mobile-action-btn {
        position: absolute;
        right: 0;
        bottom: 20px;
        display: flex;
        flex-direction: column;
      }
      .mobile-action-btn a {
        margin-bottom: 10px;
      }
      .mobile-action-btn a:last-child {
        margin-bottom: 0;
      }
      .rc-tooltip-inner .paper-bc{
        width: 100%;
      }
      .step-bottom-nav-left {
        position: relative;
        width: 100%;;
      }
    .marquee {
        display: block;
    }
    .sign-right .logo{
        display: none;
    }
    .sign-right .logo-icon{
        display: block;
        margin-top: 30px;
    }
    .sign-right{
        flex-basis: 100%;
    }
    .sign-up-form{
        padding: 1.7rem 1.2em;
    }
    .sign-up-form .form{
        min-width: auto;
        min-height: auto;
    }
    .sign-form-container{
        height: auto;
    }
    .sign-up-bottom{
        display: flex;
        flex-direction: column;
    }
    .sign-up-bottom span {
        line-height: 18px;
      }
      .sign-left{
        display: none;
     }
     .edit-day .day-info-top{
        align-items: flex-start;
     }
     .day-info-top{
        flex-direction: column;
        align-items: flex-start;
     }
     .planInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .notpaid-notifiy p {
        margin: 0;
        font-size: 12px;
        line-height: 13px;
      }
      .notpaid-notifiy p strong {
        font-size: 12px;
      }
      .edit-day .day-info-action .button-small.transition.animated.infinite.light-blue-btn.paper-bc{
        width: 100%;
      }
     .planInfo h5{
        margin-bottom: 0;
        margin-top: 0;
     }
     .slide-date-con{
        padding-top: 10px;
     }
     .slide-date p {
        line-height: 15px;
      }
      .slide-date p.slide-date-bt{
        font-size: 14px;
      }
     .mobile-header{
        display: block;
        text-decoration: underline;
        color: #001f3d;
        font-weight: 600;
        margin: 20px 0;
     }
     .summary .step-form-group h5{
        font-size: 20px;
        text-decoration: underline;
     }
     .step-indicator {
        margin-right: 5px;
      }
      .step-sublabel {
        font-size: 15px;
      }
      .step-tabs{
        justify-content: flex-start;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
      }
      .step-tabs li {
        margin-right: 20px;
      }
     .wrap-progress-page .day-info-action .paper-bc{
        width: 10em;
        height: 3em;
     }
      .summary-container{
        flex-direction: column-reverse;
      }
      .product-list-container .image_click{
        height: 170px;
      }
      .day-dish.select-dish span.selected_dish{
        font-size: 0px;
      }
      .product-list-container .img-holder.d-card-img h3,.product-list-container h2.product-title.transition{
        font-size: 12px !important;
      }
      .product-list-container .img-holder.d-card-img h3{
        bottom: 0;
      }
      .product-list-container .inner.d-card-caption{
        min-height: 30px;
        margin-top: 2px;
      }
      .day-info .mealtypes{
        margin-bottom: 5px;
      }
      .product-list-container .product.transition{
        margin-bottom: 15px;
        margin-right: 0;
      }
      .product-list-container .day-dish .product.transition{
        margin-bottom: 0;
      }
      .product-list-container .day-dish{
        margin-bottom:15px;
        margin-right: 0;
      }
      .product-list-container .day-dish .mb-3,.product-list-container .product.transition .mb-3{
        margin-bottom: 0.5rem !important;
      }
      .product-list-container .product-info{
        margin-left: 15px;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 15px;
      }
      .day-info-details h5 {
        display: none;
      }
      .day-info-details p {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
      }
      .product-list-container .product.transition .img-holder.d-card-img{
        margin-bottom: 0;
        flex-basis: 90%;
      }
      .product-list-container .day-dish{
        border: 1px solid transparent;
      }
      .product-list-container .day-dish.select-dish{
        border: 1px solid #12bdc7; 
      }
      .checkout-process .ing-info span, .checkout-process .side-dish-kcal .ing-info span{
        font-size: 12px;
        line-height: 10px;
      }

    .checkout-process .ing-info span.card-kacal {
        display: block;
    }
      .summary-con-left,.summary-con-right{
        width: 100%;
        justify-content: flex-start;
      }
      .summary-con-right{
        padding-left: 0;
        padding-top: 0;
      }
      .summary-con-left{
        padding-top: 30px;
        padding-bottom: 60px;
      }
      .priceCon{
        margin-bottom: 0;
      }
      .checkout-diet-list,.checkbox-list,.radio-list {
        display: grid;
        grid-template-columns: auto auto;
      }
      .mealtypes .checkout-diet-list{
        overflow-x: scroll;
        display: flex;
        margin-top: 10px;
      }
      .slide-dates{
        overflow-x: scroll;
      }
      .mealtypes .checkout-diet-list::-webkit-scrollbar,.slide-dates::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .mealtypes .checkout-diet-list,.slide-dates {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
      .mealtypes .checkout-diet-list .diet-list{
        padding: 10px 10px;
        min-width: 130px;
      }
      .mealtypes .checkout-diet-list .diet-list span{
        font-size: 13px;
        margin-left: 5px;
        font-weight: 500;
        text-align: center;
      }
      .cutlery-con{
        border: none;
        margin: 0;
        padding: 0;
      }
      .step-bottom-nav.border-top{
        padding-bottom: 40px;
      }
      .btn-con,.step-bottom-nav-right{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        background-color: rgba(255,255,255, 0.7);
        padding: 15px;
        -webkit-box-shadow: 0 -10px 30px 0 rgba(0,0,0,0.08);
        box-shadow: 0 -10px 20px 0 rgba(0,0,0,0.05);
        z-index: 999;
      }
      .btn-con .summary-con-right .button-small.continue{
        width: calc(100% - 60px);
      }
      .checkbox-list li,.diet-list,.radio-list li {
        margin-bottom: 10px;
      }
      .renew-content .radio-list,.radio-list.horiz-list {
        grid-template-columns: auto;
      }
      .renew-content .radio-list label {
        justify-content: flex-start;
      }
      .renew-footer {
        justify-content: flex-start;
        width: 100%;
        flex-direction: column;
      }
      .renew-footer .button-small.transition.back-btn.paper-bc {
        width: 100%;
        margin-top: 10px;
      }
      .checkout-process .popup-select-area.step-form-input {
        width: 100%;
      }
      .step-tab-list .lineIndicator{
        display: none;
      }
      .step-tab-list .step-label{
        font-size: 15px;
        display: none;
      }
      .step-tab-list{
        padding: 0;
        margin-top: 15px;
      }
     
      .checkout-process.step1 .button-small.transition.continue.paper-bc {
        width: 100%;
      }
      .step-bottom-nav-right .button-small.back-btn {
        width: 100%;
      }
      .wrap-progress-page .step-bottom-nav-right .button-small {
        width: 100%;
      }
      .step-bottom-nav-left {
        margin-bottom: 30px;
      }
    .dishcont {
        margin: 0 0 20px 0;
        flex: 0 0 100%;
    }
    .address_div .addr_sec {
        width: 100%;
        float: none;
        margin: 10px 0;
    }
    .dishcate{display: block;}
    .day.xyz-fin.editable-items ul.dish-groups.pl-0.mb-0.edit-enabled{display: none;}    
    .manager-contacts {
        width: max-content;
    }
    .links-wrap.position-relative .my-auto.set-links ul li a {
        font-size: 12px;
        margin-left: 20px;
    }
    .today-meal-full-plan.position-absolute .today-meal-full-plan-wrap a {
        padding: 0px;
        margin: 10px 0 0;
        float: left;
    }
    .alice-carousel__wrapper {
        padding: 0px !important;
    }
    .col-8.offset-2.col-md-1.col-lg-2.offset-md-0.logo-holder.d-flex.justify-content-center.px-0 {
        padding: 8px 0px;
        display: block;
    }
    .leaf-image {
        display: none;
    }
    .d-kal-info.mb-3 {
        padding-bottom: 0px;
    }
    .d-kal-info.mb-3 span.card-kacal {
        padding-bottom: 0px;
    }
    .inner.d-card-caption .d-flex.discrd-ftr {
        margin: 15px 0 0 !important;
    }
    .dish-holder .head-line span.mini,.day-header h5 span {
        font-size: 12px;
        color: #333 !important;
        font-weight: 200;
    }
    .day .day-header h5 {
        margin: 0px;
        font-weight: 500;
        font-size: 14px;
        color: #000;
    }
    .dish-holder .head-line{
        z-index: 99;
    }
    .uplo {
        width: 120px;
        height: 60px !important;
        padding: 10px;
        font-size: 12px;
        z-index: 999;
    }      
    .uplo span {
        line-height: 1.2;
        font-size: 13px;
    }    
    .form-input {
        height: 45px;
        line-height: 45px;
    }
    .form-input .css-10nd86i {
        height: 45px;
        line-height: 45px;
    }
    .css-1ns25le {
        height: 45px;
        line-height: 45px;
        border: 1px solid #ede2e7 !important;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    }
    .css-rsyb7x {
        line-height: 45px;
        height: 45px;
        padding: 0px;
        margin: 0px;
    }
    span.css-d8oujb {
        background: #ede2e7 !important;
    }
    svg.css-19bqh2r {
        fill: #ede2e7 !important;
    }
    .css-1rtrksz {
        line-height: 45px;
        height: 45px;
    }
    .mealdays-selection {
        margin-bottom: 20px;
    }
    .product.transition.d-card-inr-holder.not {
        margin: 0px 10px;
    }
    .top-params-filters ul.dish-groups {
        width: 100%;
        padding: 0px;
        /*border-bottom: 2px solid #d2d2d2;*/
    }
    .day .day-header {
        border: 0px;
        margin-bottom: 10px;
    }
    .top-params-filters ul.dish-groups li {
        padding: 5px 12px;
        font-size: 14px;
        text-transform: capitalize;
        border-radius: 3px;
    }
    .top-params-filters ul.dish-groups li.can-change {
        padding: 0px !important;
        border: 0px;
        font-size: 12px;
        margin-top: 10px;
        font-weight: 500;
        line-height: 1.2;
    }
    .day .day-header .control-buttons {
        float: right;
        position: absolute;
        right: 10px;
        z-index: 10;
    }
    .day .day-header .control-buttons span.button {
        font-size: 14px;
        padding: 5px 12px;
        border-radius: 3px;
    }
    .day .day-header .control-buttons span.button:hover {
        background: #12BEC7 !important;
        color: #fff !important;
        border-color: #12BEC7 !important;
    }
    .day .day-header .control-buttons span.skips {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
    span.diet-overview-edit {
        width: 100%;
        display: inline-block;
        float: left;
        line-height: normal;
        font-weight: 500;
        font-size: 16px;
    }
    .dish-holder .head-line span.mini {
        float: left;
        width: 100%;
        display: inline-block;
    }
    .dish-holder .head-line h5 {
        display: inline-block;
        width: 100%;
        padding: 20px 0 10px;
    }
    .day .day-header h5 {
        font-size: 16px;
        color: #000;
        font-weight: 500;
    }
    .user-data-wrap.position-relative {
        margin: 0px;
        padding: 20px 0 0;
        border: 0px;
        box-shadow: none;
    }
    .mb-2.user-photo {
        float: left;
        padding: 0px;
    }
    .user-data-wrap.position-relative .user-name,.user-data-wrap.position-relative .user-name h3{
        font-size: 20px !important;
        margin: 0px;
    }
    .mt-3.mb-3.user-data {
        border-radius: 10px;
        border: 1px solid #e5e0e2;
        box-shadow: 0 -3px 8px 0 rgb(0 0 0 / 10%);
        padding: 10px;
        border-bottom: 0px;
        margin-bottom: 0px !important;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin: 0pxpx !important;
    }
    .grey.user-joined {
        border-radius: 10px;
        border: 1px solid #e5e0e2;
        box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
        padding: 10px;
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .pb-3.position-relative.user-birthday.grey:after {
        content: none;
    }
    .user-name, .user-details, .user-birthday {
        text-align: left;
        padding-left: 15px;
        display: table;
        margin-bottom: 0px !important;
    }
    .pb-3.position-relative.user-birthday.grey {
        margin-bottom: 20px !important;
    }
    .meal-card .position-relative.my-4.plans-card-image {
        height: 160px;
        object-fit: contain;
        margin: 0 0 10px 0 !important;
        z-index: 1;
    }
    .meal-card .card-type {
        z-index: 999;
        position: absolute;
        background-color: rgba(0,0,0,.5);
        color: #fff;
        padding: 6px 18px;
        bottom: 0;
    }
    .meal-card .card-macros {
        margin: 0px;
    }
    .meal-card .position-relative.my-4.plans-card-image img.img-fluid {
        width: 100%;
        height: 100%;
        margin-top: 0px;   
    }
    .btnb.bk-con-btn p {
        margin: 0px !important;
        border: 1px solid #12bdc7 !important;
        line-height: normal !important;
        padding: 10px !important;
        min-height: auto !important;
        border-radius: 4px;
        background: #12bdc7;
        color: #fff;
        font-size: 16px;
    }
    .row.togle-dishes-menu.hidden-on-desktop {
        z-index: 999;
    }
    .btnb.bk-con-btn p:hover {
        background: #fff;
        color: #12bdc7;
    }
    ul.dish-groups.pl-0.mb-0.edit-enabled {
        margin-top: 50px !important;
    }
    ul.dish-groups.pl-0.mb-0.edit-enabled li.bg-gold {
        position: relative;
    }
    .user-data-wrap input.standart-input {
        height: auto;
    }
    .user-data-wrap button.btn.gold-button {
        padding: 8px 20px !important;
        float: right;
    }
    .user-data-wrap input[type="text"] {
        padding: 0 10px !important;
        height: 35px;
    }
    #dashboard .today-meal-wrap h3.text-center {
        font-size: 20px;
        line-height: 25px;
    }
    .dish-holder .alert.alert-unsaved {
        line-height: 1.2;
        text-align: left;
    }
    .dish-holder .alert-unsaved strong {
        color: #222;
        font-size: 13px;
        line-height: 1.2;
        float: right;
        width: 90%;
    }
    .dish-holder .alert.alert-unsaved span.warning {
        width: 22px;
        height: 20px;
        line-height: 20px;
        float: left;
        display: table;
    }
    .dish-holder .alert-unsaved{
        margin-top: -10px;
    }
    .logo-holder a{        
        background-position: left center;
        margin-left: 15px;
    }
    .col-8.offset-2.col-md-1.col-lg-2.offset-md-0.logo-holder.d-flex.justify-content-center.px-0 {
        margin: 0px;
    }
    .modal-back-layer {    
        z-index: 99999;
    }
    .modal-custom-body .col-12.left-dish.dish-holder.custom-scrollbar.vertical-scrollbar {
        width: 100%;
        padding: 0px;
        margin-bottom: 0px;
    }
    .ingridients-contain .ingridients {
        margin-bottom: 0px;
    }
    .modal-custom-body .emoji-icon {
        width: 50px;
        height: 40px;
    }
    .modal-custom-body .emoji-icon.active:before {
        width: 70px;
        height: 70px;
        left: -10px;
        top: -10px;
    }
    .modal-custom-body .emoji-pick-sec li,
    .modal-custom-body .emoji-pick-sec li small {        
        line-height: 1;
    }
    .modal-header-styled .title-block p .m-title,.modal-custom-body .clr-light-gry-txt {
        line-height: 1.2;
    }
    .text-center.modal-message.pop-style.dark-text.w-100.letter-sm.px-3 {
        text-align: left !important;
    }
    .modal-custom-body ul.list {
        margin: 0px;
        padding: 0px;
        text-align: left;
    }
    .modal-custom-body ul.list li {
        list-style: none;
        padding-left: 10px;
        position: relative;
    }
    .modal-custom-body ul.list li:before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        background: #000;
        border-radius: 100%;
        left: 0;
        top: 10px;
    }
    .button-small.transition.next.paper-bc {
        width: 100%;
    }
    .modal-custom-body.b-rounded-blue.personal-shadow .row p,
    .feedback-form-holder.pt-3.text-left .form-group,
    .text-center.modal-message.pop-style.dark-text.w-100.letter-sm.px-3,
    ul.emoji-pick-sec.d-flex.justify-content-between.p-0 {
        margin: 0px;
    }
    .d-flex.justify-content-end.align-items-center.px-3.close {
        z-index: 1000000;
    }
    .day.xyz-fin.editable-items h2.product-title.transition {        
        line-height: 1.2 !important;
    }
    .main-dish .d-kal-info span.card-kacal {
        position: relative;
    }
    .main-dish .d-kal-info span.card-kacal:after {
        content: "";
        background-color: #d0d0d0;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
    }
    .day.xyz-fin.delivered .inner.d-card-caption .d-flex.discrd-ftr {
        margin-top: 5px !important;
    }
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder.chosen {
        margin: 0 10px;
    }
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder {
        height: auto;
    }
    .dish-holder .alert.alert-unsaved{
        z-index: 999;
    }
    .modal-header-styled .title-block p .m-title {
        text-align: left;
        width: 100% !important;
        float: left;
    }
    .product.transition.d-card-inr-holder.not{
        height: auto;
    }
    .product.transition.d-card-inr-holder.chosen span.card-kacal {
        width: 100%;
    }
    .product.transition.d-card-inr-holder.not,.product.transition.d-card-inr-holder.chosen{
        margin:10px !important;
    }
    .product.transition.d-card-inr-holder.not {
        border: 1px solid #fff;
    }
    .product.transition.d-card-inr-holder.not span.card-kacal {
        width: 100%;
    }
    .mb-2.user-photo img.img-fluid.dash-ava{
        width: 120px;
        height: 120px;
    }
    #dashboard .grey.user-joined {
        font-size: 12px;
    }
    .container-fluid.regular-page.feedback-page {
        width: 100%;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded.rating-side b {
        text-align: left;
        width: 100%;
        min-width: unset;
        line-height: 1.2;
        text-transform: capitalize;
    }    
    .general-feedback.feedback-page .bg-gray-bordered.padded.rating-side {
        padding: 0px;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded {
        width: 100%;
        display: inline-block;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded .form-input.text-area-input {
        width: 100%;
        height: auto;
    }
    .dish-feedback.feedback-page .products {
        width: 50%;
    }
    .dish-feedback.feedback-page .products h2.product-title.transition {
        height: 70px;line-height: 1.2 !important;
    }
    .dish-feedback.feedback-page .letter-xs.mb-1.light-dark-text,.dish-feedback.feedback-page .letter-xs.light-dark-text {
        text-align: left;
    }
    .dish-feedback.feedback-page .products .img-holder {
        height: 100px;
    }
    .dish-feedback.feedback-page span.has-error {
        position: relative;
    }
    .dish-feedback.feedback-page .form-input.text-area-input.has-error.letter-xs.red-color {
        height: auto;
        line-height: normal;
    }
    .dish-feedback.feedback-page span.has-error.letter-xs.red-color {
        position: initial;
    }
    .dish-feedback.feedback-page .bg-gray-bordered.padded.rev-rating > span {
        width: 100%;
    }
    .feedback-page.dish-feedback .products {
        margin: 0px !important;
    }
    .today-meal-full-plan.position-absolute {
        position: relative !important;
    }
    .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition {
        padding-bottom: 10px !important;
    }
    .dishcate .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition{
        padding-bottom: 0px !important;
    }
    .today-meal-full-plan.position-absolute {
        position: relative !important;
        top: auto;
        bottom: auto;
    }
    .scroll-auto ul.border-bottom-light-dark,.scroll-auto ul {
        padding: 0px !important;
    }
    .m-page-menu-modal .scroll-auto ul li.nav-item.pl-4 {
        padding: 0 20px 0 !important;
        border: 0px;
    }
    .m-page-menu-modal .scroll-auto ul li.nav-item a.nav-link {        
        padding: 5px 0 5px 20px;
    }
    .m-page-menu-modal .scroll-auto ul.border-bottom-light-dark {
        border: 0px !important;
        margin: 0px !important;
    }
    .scroll-auto ul.border-bottom-light-dark li, .scroll-auto ul li {
        border-bottom: 1px solid #e4ebee;
    }
    .m-page-menu-modal.xyz-fin {
        padding-top: 10px;
    }    
    .BrainhubCarousel__customArrows.BrainhubCarousel__arrow--disable.BrainhubCarousel__custom-arrowLeft, .BrainhubCarousel__customArrows.BrainhubCarousel__custom-arrowLeft {        
        z-index: 99;
    }
    .product.transition.d-card-inr-holder.not span.card-kacal,
    .product.transition.d-card-inr-holder.chosen span.card-kacal{
        padding: 0px;
    }

    .product.transition.d-card-inr-holder .side-dish-kcal div.d-kal-info {
        margin-bottom: 0px !important;
    }
    .product.transition.d-card-inr-holder.not .inner.d-card-caption .d-flex.discrd-ftr,
    .product.transition.d-card-inr-holder.chosen .inner.d-card-caption .d-flex.discrd-ftr {
        margin-top: 5px !important;
    }
    .product.transition.d-card-inr-holder.not .side-dish-kcal p, 
    .product.transition.d-card-inr-holder.chosen .side-dish-kcal p {
        padding-top: 0px;
        line-height: 1;
    }
    .day.xyz-fin.editable-items .sidedish .image-caption-holder p {
        line-height: 1.1;
    }
    .day.xyz-fin.editable-items .product.transition.d-card-inr-holder.chosen .main-dish .d-kal-info, 
    .day.xyz-fin.editable-items .product.transition.d-card-inr-holder .main-dish .d-kal-info {
        line-height: 1;
    }
    .modal-message.pop-style .dish-holder .dish-image {height: 160px;object-fit: cover;}
    .dish-holder .day.xyz-fin.delivered {margin-bottom: 10px;}
    .pauseinfo{ margin-top:-10px;}
    /*.day.xyz-fin.delivered .product.transition.d-card-inr-holder {padding: 15px;}*/
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder .img-holder {
        height: 100%;
        background-position: center center;
        background-size: auto;
    }
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder .img-holder.d-card-img {
        margin: 0px;
    }
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder .inner.d-card-caption {
        padding: 15px;
    }
    .day.xyz-fin.delivered .product.transition.d-card-inr-holder.not {
        padding: 0px;
    }    
    /*.BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition li.BrainhubCarouselItem {
        align-items: center !important;
    }*/
    .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track.BrainhubCarousel__track--transition li.BrainhubCarouselItem .products {        
        height: auto;
    }    
    .modal-header-styled .title-block p .m-title {max-width: 100%;margin-top: 10px;}
    .modal-header-styled .title-block p span.ar-golr-l {height: 0px;display: none;}
    .modal-header-styled .title-block p.w-100 {display: inline-block;width: 100%;}
    .modal-header-styled .title-block p span.ar-golr-r {display: none;height: 0px;}
    .popup-dash-calendar-fix {line-height: 1.2;}
    .button-regular.next.transition.paper-bc {max-width: 100%; margin: 10px 0 0 0; padding: 5px 10px; height: auto;width:10em; }
    .modal-message.pop-style .popup-dash-calendar-fix input.standart-input,.modal-message.pop-style .popup-dash-calendar-fix input {height: 40px;padding: 0px 10px;float: left;margin: 0 0 10px 0;}
    .modal-message.pop-style .popup-dash-calendar-fix textarea.medical-text-area {resize: none;margin: 10px 0;}
    .modal-message.pop-style textarea.medical-text-area {resize: none;margin: 10px 0;}
    .modal-message.pop-style .address-edit-div input {height: 45px;padding-left: 10px;;float: left;margin-bottom: 10px;}
    .form-input.shadowed.b-rounded-blue.styled-select.pointer {border: none;box-shadow: none;}
    .modal-message.pop-style .address-edit-div input[type="checkbox"] {height: auto;top: 8px;}
    .modal-message.pop-style .address-edit-div label {margin: 0px;line-height: 1;}
    .modal-message.pop-style .address-edit-div .pointer {display: inline-block;width: 100%;height: 35px;margin-bottom: 10px;float: left;}
    .modal-message.pop-style .popup-select-area ul.select2-selection__rendered {padding: 0px;width: auto;}
    .modal-message.pop-style .popup-select-area ul.select2-selection__rendered li {font-size: 12px;padding: 0px 5px;display: table;width: auto;}
    .select2-container--open .select2-dropdown {left: 50px !important;width: 320px !important;top: -15px !important;}
    .select2-container--open input.select2-search__field {width: 100% !important;}
    .select2-container--open .select2-results ul {padding: 0px !important;}
    .select2-container--open .select2-search.select2-search--dropdown {padding: 0px !important;}
    .col-2.col-sm-2.btnb.text-center.backtotop{
        display: none;
    }
    .col-2.col-sm-2.btnb.text-center.backtotop.mtop {
      display: block;
      position: fixed;
      z-index: 10000000;
      bottom: 0px;
      padding: 10px;
      right: 10px;
      left: auto;
    }
    .col-2.col-sm-2.btnb.text-center.backtotop.mtop p {
      margin: 0px;
    }
    .Right.col-12.col-lg-9.mb-12 {
        padding: 0;
    }
    .Right .BrainhubCarousel__arrow--disable.BrainhubCarousel__customArrows .car-arrow i.fa{
        left: 0;
    }
    /*side dish css*/
    .Right h3 {
        display: block;
        margin: 0;
        margin-top: 0px;
        padding: 0;
        padding-left: 10px;
        font-weight: 500;
        font-size: 14px;
        margin-top: 0;
        text-align: center;
    }
    
    .day.xyz-fin.editable-items .sidedish-content p.image {
        height: 100%;
        min-height: 100px;
    }
    .day.xyz-fin.editable-items .sidedish-content p.image img {
        display: none;
    }
    .day.xyz-fin.editable-items .sidedish .image-caption-holder {
        background: #fff;
    }
    .products.sidedishcon > h3,.Right h3 {
        text-align: left;
        line-height: normal;
    }
    .Right h3 {
        margin: 10px 0;
    }
    .day.xyz-fin.editable-items .sidedish {
        padding: 0px;
    }
    .day.xyz-fin.editable-items .Left .products.sidedishcon {
        padding: 0;
        border: 0px;
        padding-bottom: 15px;
    }
    .day.xyz-fin.editable-items .Left{
        background: #b7e2e5;
        /*border: 1px solid #12BEC7;*/
    }
    .container-fluid.mob_side {
        background: #fff;
        box-shadow: 0 5px 10px 0 rgb(0 0 0/12%);
        border: 1px solid #f7f7f7;
        margin-top: 10px;
    }
    /*.BrainhubCarousel__custom-arrowLeft {
        position: absolute;
        bottom: 10px;
        top: auto;
        left: 40%;
        visibility: hidden;
    }
    .BrainhubCarousel__custom-arrowRight {
        position: absolute;
        bottom: 10px;
        top: auto;
        right: 40%;
        visibility: hidden;
    }*/
    .Right .product.transition.d-card-inr-holder.not {
        margin: 0px 10px 0px 0px !important;
    }
    .dishcate .BrainhubCarousel__custom-arrowLeft,
    .dishcate .BrainhubCarousel__custom-arrowRight{
        visibility: visible;
    }
    .dishcate .BrainhubCarousel__custom-arrowLeft{        
        bottom: 0 !important;
        right: auto !important;
        left: 0 !important;
        top: 0px !important;
        position: relative !important;
    }    
    .dishcate .BrainhubCarousel__custom-arrowRight{
        bottom: 0 !important;
        left: auto !important;
        right: 0 !important;
        top: 0px !important;
        position: relative !important;
    }
    /*side dish css*/
    .click-on.mobile_show{
        display: block;
    }
    .click-on.desktop_show{
        display: none;
    }
    
    .dishcate ul li li{
        font-size: 10px;
    }
    .meal-card, .plans-card-title{
        min-height: auto !important;
    }
    .today-meal-full-plan.position-absolute {
        width: 100%;
    }
    .day-header h5 span {
        display: block;
    }
     /*Body Analaysis */
     .row.progress-page-inner.grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }       
    .bd-month-year {
        margin: 0;
        padding: 0;
    }
    .bd-month-year > li:first-child{
        width: 100%;
    }
    .row.progress-page-inner.grid.grid-3 {
        grid-template-columns: 1fr;
    }
    .grid-3 .pro-page-col {
        /*! grid-template-columns: auto 1fr 1fr; */
        justify-content: space-evenly;
        align-items: center;
        padding: 5px 10px;
        border: none;
        border-bottom: 1px solid #fff;
        }
    .pro-page-col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
      }
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .product-list-container{
        grid-template-columns: auto auto;
    }
    .slide-dates{
        max-width: 100%;
    }
    .step-bottom-nav.border-top{
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
     }
    .product-list-container h2.product-title.transition{
        font-size: 12px !important;
    }
    .product-list-container .product.transition .img-holder.d-card-img{
        flex-basis: 100%;
    }
    .product-list-container .img-holder.d-card-img h3{
        font-size: 14px;
    }
    .product-list-container .product.transition{
        margin-right: 0;
    }
    .sign-left{
        align-items: end;
        position: relative;
    }
    .sign-left-inner {
        width: 70%;
        margin-top: 0;
        position: relative;
        z-index: 100;
    }
    .sign-left-inner h5, .sign-left-inner p{
        text-shadow: 2px 2px rgba(0,0,0,.8);
    }
    .sign-left::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.18);
    
    }
    .address_div .addr_sec {
        width: 47%;
        margin: 0 10px 20px 10px;
      }
      .dishcont {
        flex: 0 0 47%;
        -ms-flex: 0 0 47%;
      }
    .col-8.offset-2.col-md-1.col-lg-2.offset-md-0.logo-holder.d-flex.justify-content-center.px-0 {
        margin: 0px !important;
    }
    .logo-holder a {
        text-align: left !important;
        background-position: left;
        float: left;
        margin: 5px 10px;
        background-size: 210px;
    }
    header.row.page-header {
        height: 8vh;
    }
    .meal-card .position-relative.my-4.plans-card-image {
        height: 160px;
        object-fit: contain;
        margin: 0 0 10px!important;
        z-index: 1;
    }
    .meal-card .position-relative.my-4.plans-card-image img.img-fluid {     
        height: 100%;
        margin-top: 0;
    }
    .meal-card .card-type {
        z-index: 999;
        position: absolute;
        background-color: rgba(0,0,0,.5);
        color: #fff;
        padding: 6px 18px;
        bottom: 0;
    }
    .today-meal-full-plan.position-absolute {
        position: relative !important;
    }
    .product.transition.d-card-inr-holder.not {
        margin: 0 5px;
    }
    .btnb.bk-con-btn p {
        margin: 0px !important;
        border: 1px solid #12bdc7 !important;
        line-height: normal !important;
        padding: 10px !important;
        min-height: auto !important;
        border-radius: 4px;
        background: #12bdc7;
        color: #fff;
        font-size: 16px;
    }
    .btnb.bk-con-btn p:hover {
        background: #fff;
        color: #12bdc7;
    }
    .top-params-filters ul.dish-groups li.can-change {
        padding: 0!important;
        border: 0;
        font-size: 14px;
        margin-top: 10px;
        font-weight: 500;
        line-height: 1.2;
    }
    .day .day-header .control-buttons span.button, .top-params-filters ul.dish-groups li {
        font-size: 13px;
        padding: 2px 10px;
        border-radius: 3px;
        text-transform: capitalize;
        text-shadow: none;
        line-height: 25px;
    }
    .top-params-filters ul.dish-groups li.bg-gold {
        position: initial;
    }
    .dish-holder .alert.alert-unsaved{
        z-index: 999;
    }
    .col-12.left-dish.dish-holder.custom-scrollbar.vertical-scrollbar {
        /*width: 100%;*/
        width: 75vw;
    }
    .col-12.left-dish.dish-holder.custom-scrollbar.vertical-scrollbar ul.ingridients {
        margin: 0px 0px !important;
    }
    .day.xyz-fin.editable-items h2.product-title.transition {        
        line-height: 1.2 !important;
    }
    .day.xyz-fin.editable-items .side-dish-kcal span.card-kacal,.day.xyz-fin.editable-items .products .main-dish span.card-kacal {
        width: 100%;
    }
    .modal-custom-body ul.list {
        margin: 0px;
        padding: 0px;
        text-align: left;
    }
    .modal-custom-body ul.list li {
        list-style: none;
        padding-left: 10px;        
        position: relative;
    }
    .modal-custom-body ul.list li:before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        background: #000;
        border-radius: 100%;
        left: 0;
        top: 10px;
    }
    .button-small.transition.next.paper-bc {
        width: 100%;
    }
    .modal-custom-body.b-rounded-blue.personal-shadow .row p {
        margin: 0px;
    }
    .feedback-form-holder.pt-3.text-left .form-group {
        margin: 0px;
    }
    .text-center.modal-message.pop-style.dark-text.w-100.letter-sm.px-3 {
        margin: 0px;
    }
    .BrainhubCarousel .products .product .img-holder.d-card-img {
        height: 200px;
    }
    .form-input {
        height: 50px;
        line-height: 50px;
    }
    .form-input .css-10nd86i {
        height: 50px;
        line-height: 50px;
    }
    .css-1ns25le {
        height: 50px;
        line-height: 50px;
        border: 1px solid #ede2e7 !important;
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    }
    .css-rsyb7x {
        line-height: 50px;
        height: 50px;
        padding: 0px;
        margin: 0px;
    }
    span.css-d8oujb {
        background: #ede2e7 !important;
    }
    svg.css-19bqh2r {
        fill: #ede2e7 !important;
    }
    .css-1rtrksz {
        line-height: 50px;
        height: 50px;
    }
    .dish-holder .head-line{
        z-index: 999;
    }
    ul.emoji-pick-sec.d-flex.justify-content-between.p-0 {
        margin: 0px;
    }
    .d-flex.justify-content-end.align-items-center.px-3.close {
        z-index: 1000000;
    }
    .day.xyz-fin.editable-items h2.product-title.transition {        
        line-height: 1.2 !important;
    }
    ul.dish-groups.pl-0.mb-0 {
        width: 100%;
        display: inline-block;
        flex-wrap: initial;
        padding: 0px !important;
    }
    .modal-message.pop-style p.clr-light-gry-txt {
        line-height: 1.2;
    }
    .dish-holder .head-line span.mini,.day-header h5 span {
        font-size: 14px;
        color: #333 !important;
        font-weight: 200;
    }
    .dish-holder .head-line span.mini, span.diet-overview-edit {
        width: 100%;
        display: inline-block;
        float: left;
    }    
    .dish-holder .head-line h5 {display: inline-block;
        width: 100%;
        padding: 20px 0 10px;
    }
    .mealdays-selection {
        margin-bottom: 20px;
    }
    .diet-name-edit {        
        font-size: 25px;            
    }
    span.diet-overview-edit {
        font-size: 18px;
    }
    .product.transition.d-card-inr-holder.chosen, .product.transition.d-card-inr-holder.not {
        margin: 0 10px;
    }
    input.standart-input.b-rounded-blue.shadowed.letter-xs.white-back.pointer {
        width: auto;
        height: 40px;
        margin-right: 20px;
    }
    .search-profile-main-cal.form-input.calendar-input.pointer.absolute-cal {
        float: left;
    }
    .pb-3.position-relative.user-birthday.grey {
        display: table;
        margin: 0 auto;
    }
    input#weightEdit {
        height: 40px;
        margin-right: 10px;
    }
    #dashboard button.btn.gold-button.small-save {
        margin: 0px;
        padding: 10px 20px !important;
    }
    input#heightEdit {
        height: 40px;
        margin-right: 10px;
    }    
    .mb-2.user-photo {
        float: left;
        padding: 20px;
    }
    .user-data-wrap.position-relative {
        margin: 0px;
        padding: 20px 40px;
        text-align: left;
        float: left;
        width: 70%;
        border: 0px;
        box-shadow: none;
    }
    .mb-2.user-photo .uplo {
        bottom: 20px;
    }
    .pb-3.position-relative.user-birthday.grey {
        float: left;
        width: 100%;
    }
    .user-data-wrap.position-relative .mt-3.mb-3.user-data mx-auto {
        float: left;
    }
    .user-data-wrap.position-relative .mt-3.mb-3.user-data table.mx-auto {
        display: inline-block;
    }    
    #user-data .col-12.col-lg-3.mb-4.text-center {
        display: inline-block;
        width: 100%;
        border-radius: 10px; 
        border: 1px solid #e5e0e2; 
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
        margin: 20px;max-width: unset;
        flex: unset;
    }
    #dashboard .user-data table td.text-right,#dashboard .user-data table td.text-left {
        text-align: left !important;
        width: auto;
    }
    #dashboard .grey.user-joined {
        font-size: 16px;
    }
    .manager-contacts {
        width: 100%;
    }
    .managers-wrap.mb-4.position-relative .col-8.my-auto {
        width: 100%;
        max-width: 100%;
    }
    .managers-wrap.mb-4.position-relative .col-8.my-auto .manager-contacts a {
        font-size: 18px;
    }
    .managers-wrap.mb-4.position-relative .col-8.my-auto .manager-contacts a img.small-icon {
        width: 32px;
        height: 32px;
    }
    #dashboard .managers-wrap.mb-4.position-relative .manager-name h4.mb-1, #dashboard .managers-wrap.mb-4.position-relative .manager-position {
        font-size: 16px !important;
    }
    .macros-kcal-container .text-center {
        font-size: 16px;
    }
    .container-fluid.regular-page.general-feedback.feedback-page {
        width: 100%;
        flex: initial;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded.rating-side b {
        height: 45px;
        line-height: 25px;
        text-align: right;
        font-size: 20px;
        text-transform: capitalize;
        width: 50%;
        clear: both;
    }    
    .general-feedback.feedback-page .bg-gray-bordered.padded.rating-side {
        width: 100%;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded {
        width: 100%;
        padding-bottom: 40px;
    }
    .general-feedback.feedback-page .bg-gray-bordered.padded .form-input.text-area-input {
        height: auto;
        line-height: normal;
    }
    b.food-prefs-header {text-align: left !important;float: left;}
    .select2-selection.select2-selection--multiple {min-height: initial !important;}
    .modal-custom-body.b-rounded-blue.personal-shadow.col-sm-10.col-md-6.col-lg-4.px-0 {flex: initial;width: 80%;}
    .button-regular.next.transition.paper-bc {display: table;margin: 10px 0 0 0;width: 100%;float: left;}
    #dashboard .modal-message.pop-style p.popup-note-text {margin-bottom: 5px;}
    .modal-header-styled .title-block p span.ar-golr-l,.modal-header-styled .title-block p span.ar-golr-r {display: none;}
    .modal-header-styled .title-block p span.m-title {width: 100%;margin-top: 10px;text-align: left;}
    .modal-message.pop-style .addresses-fields h3 {float: left;width: 100%;display: inline-block;text-align: left;font-size: 20px;margin-top: 15px;}
    .modal-message.pop-style .addresses-fields {display: inline-block;width: 100%;float: left;}
    .row.modal-header-styled {display: inline-block;width: 100%;margin: 0px !important;}
    .row.modal-header-styled .title-block {margin: 0px !important;padding: 0px !important;}
    .modal-message.pop-style .addresses-fields .address-edit-div {display: inline-block;width: 100%;}
    .modal-message.pop-style .addresses-fields .address-edit-div .form-input,.modal-message.pop-style .addresses-fields .address-edit-div .pointer {display: inline-block;width: 100%;float: left;margin-bottom: 10px;height: auto;}
    .modal-message.pop-style .addresses-fields .address-edit-div .pointer select.pointer {height: 45px;margin: 0;}
    .modal-message.pop-style .addresses-fields .address-edit-div .addr-checkbox {clear: left;text-align: left;position: relative;}
    .modal-message.pop-style .addresses-fields .address-edit-div .addr-checkbox label {line-height: 1.2;padding-left: 30px;}
    .modal-message.pop-style .addresses-fields .address-edit-div .addr-checkbox input[type="checkbox"] {position: absolute;top: 8px;margin-left: 10px;}
    .modal-message.pop-style .addresses-fields .address-edit-div .form-input input {font-size: 14px;}
    .modal-message.pop-style .addresses-fields .address-edit-div .pointer .form-input {margin: 0px;}
    .search-profile-main-cal.form-input.calendar-input.pointer.absolute-cal {width: 50%;}
    input.standart-input.b-rounded-blue.shadowed.letter-xs.white-back.pointer {float: left;width: 90%;font-size: 15px !important;}
    a.date-changer {clear: left;text-align: left;margin: 10px 0 !important;}
    .popup-dash-calendar-fix {text-align: left;}
    textarea.medical-text-area {margin: 10px 0;}

} 
@media screen and (min-width: 768px) and (max-width: 991px){
    .checkout-process .popup-select-area.step-form-input{
        width: 100%;
    }
    .diet-list img {
        display: none;
      }
    .summary-container{
        flex-direction: column;
    }
    .summary-con-left,.summary-con-right{
        width: 100%;
    }
    .summary-con-right{
        justify-content: flex-end;
    }
    .bagPrice{
        flex-direction: column;
        display: flex;
    }
    .step-tabs{
        justify-content: flex-start;
    }
    .step-tab-list{
        margin-right: 30px;
    }
    .step-tab-list .lineIndicator{
        width: 35px;
        overflow: hidden;
        position: relative;
        left: 5px;
    }
    .day-info-top{
        align-items: center;
    }
    .day-info-details p {
        display: flex;
        flex-direction: column;
      }
    .main-holder.checkout-process-con.wrap-progress-page {
        padding-top: 80px;
      }
    .sign-right{
        align-items: center;
    }
    .sign-left{
        align-items: end;
        background-position: center;
        position: relative;
    }
    .sign-up-bottom {
        display: flex;
        flex-direction: column;
    }
    .sign-up-form .form{
        min-width: auto;
    }
    .sign-left-inner{
    position: relative;
    z-index: 1;
    }
    .sign-left::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.18);
    
    }
    .sign-left-inner h5{
        font-size: 32px;
        line-height: 36px;
    }
    .sign-left-inner p {
        font-size: 22px;
    }
    .sign-up-form{
        padding: 1.7rem 2em;
    }
    .step-tab-list .step-label{
        font-size: 15px;
    }
     .dish-feedback.feedback-page .products {
        width: 50%;
    }
    .dish-feedback.feedback-page .inputs.holder.mt-3 {
        display: block;
    }
    .dish-feedback.feedback-page .products h2.product-title.transition {
        height: 80px;
    }
    .dish-feedback.feedback-page .products .img-holder {
        height: 100px;
    }
    .dish-feedback.feedback-page .form-input.text-area-input.has-error.letter-xs.red-color {
        height: 100% !important;
        line-height: normal;
    }
    .Right h3{display: none;}
    .Right .BrainhubCarousel__custom-arrowLeft,
    .Right .BrainhubCarousel__custom-arrowRight{
        visibility: hidden;
        bottom: 0 !important;
        top: 0px !important;
        position: absolute !important;
    }
    .day.xyz-fin.editable-items .Right .edit-label {
        padding: 0px 0px 20px 10px;
    }   
    .day.xyz-fin.editable-items .Left .products.sidedishcon{
        margin: 0px;
    }
    .container-fluid.mob_side {
        margin: 15px -15px;
    }
}
@media screen and (min-width: 769px) and (max-width: 991px){
    header.page-header .col-5.hidden-md-down.px-0 {
        max-width: 25%;
        flex: 0 0 25%;
        -ms-flex:  0 25%;
    }
    header.page-header .col-8.offset-2.col-md-1.col-lg-2.offset-md-0.logo-holder.d-flex.justify-content-center.px-0 {
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    header.page-header .logo-holder a {        
        margin: 0 auto;
        text-align: center !important;
        float: none !important;
        flex: inherit;
    }
    .user-birthday:after{
        content: none;
    }
    header.page-header .col-5.hidden-md-down.px-0 ul li {
        padding: 0 10px;
    }
    .dish-feedback.feedback-page .products {
        width: 50%;
    }
    .dish-feedback.feedback-page .inputs.holder.mt-3 {
        display: block;
    }
    .dish-feedback.feedback-page .products h2.product-title.transition {
        height: 80px;
    }
    .dish-feedback.feedback-page .products .img-holder {
        height: 100px;
    }
    .dish-feedback.feedback-page .form-input.text-area-input.has-error.letter-xs.red-color {
        height: 100% !important;
        line-height: normal;
    }
    .height-papa.main-holder.modify-page.xyz-fin {
        padding-top: 90px;
    }
    .product.transition.d-card-inr-holder.chosen, .product.transition.d-card-inr-holder.not {        
        overflow: hidden;
    }
    .day.xyz-fin.editable-items .edit-label {        
        padding: 10px 85px 10px 20px;
    }
    .product.transition.d-card-inr-holder.chosen, .product.transition.d-card-inr-holder.not {
        margin: 0 10px;
        padding: 20px;
    }
    /*.day.xyz-fin.editable-items .BrainhubCarousel .products .product.transition.d-card-inr-holder.not .img-holder.d-card-img {
        margin: -10px;
        margin-bottom: 0px;
    }
    .day.xyz-fin.editable-items .BrainhubCarousel .products .product.transition.d-card-inr-holder.chosen .img-holder.d-card-img {
        margin: -20px;
        margin-bottom: 0px;
    }*/
    /*.product.transition.d-card-inr-holder .side-dish-kcal {        
        margin-left: -10px;
        margin-right: -10px;
    }*/
    .day.xyz-fin.editable-items .sidedish {
        padding: 0 10px 10px 10px;
    }
    .day.xyz-fin.editable-items span.dish-label-value.l-green-label {        
        line-height: 1.2;
        text-align: left;
    }
    .dish-holder .alert.alert-unsaved {
        top: 90px;
    }        
}
@media only screen and (min-width: 1024px) and (max-width: 1192px){
    .dish-feedback.feedback-page .products {
        width: 50%;
    }
    .dish-feedback.feedback-page .products {
        width: 50%;
    }
    .regular-page.feedback-page.dish-feedback ul.dish-groups.pl-0.mb-0 {
        padding: 0px !important;
        margin-bottom: 10px !important;
    }
    .regular-page.feedback-page.dish-feedback ul.dish-groups.pl-0.mb-0 li {
        padding: 5px 10px;
        font-size: 13px;
    }
    .regular-page.feedback-page.dish-feedback .time-programm-group.w-100 {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .today-meal-grid .card.meal-card-wrapper .meal-card {padding: 10px !important;}
    .today-meal-wrap div#dish-screen {column-count: 2;column-gap: 10px;orphans: initial;widows: initial;}
    .today-meal-grid.position-relative {padding: 30px 0px 0px;}
    .mb-2.user-photo img.img-fluid.dash-ava {width: 75%;}
    .user-data-wrap.position-relative {margin-top: -70px;padding-top: 70px;}
    .mb-2.user-photo .uplo {width: 120px;left: 20px;height: 60px;}
    .mb-2.user-photo .uplo span {font-size: 12px;}
    #dashboard .managers-wrap.mb-4.position-relative .my-auto .manager-name h4.mb-1 {font-size: 14px !important;line-height: 1.2;}
    #dashboard .managers-wrap.mb-4.position-relative col-8.my-auto {padding: 0px 5px;}
    #dashboard .managers-wrap.mb-4.position-relative .col-3.text-center.my-auto {padding: 0px 0px 0px 10px;}
    .manager-contacts {width: 140px;margin: 0px;clear: left;display: inline-block;padding-top: 15px;}
    .links-wrap.position-relative .bg-links {background-size: 60% 100%;background-position: left center;}
    .links-wrap.position-relative .my-auto.set-links {margin: 15px 0 !important;width: 100%;flex: none !important;max-width: initial !important;padding-left: 100px;}
    .links-wrap.position-relative .my-auto.set-links ul {padding: 0px;}
    .links-wrap.position-relative .my-auto.set-links ul li a {font-size: 12px;line-height: 1 !important;}
    .links-wrap.position-relative .my-auto.set-links ul li {line-height: 1.2;margin-bottom: 5px;}
    .today-meal-full-plan.position-absolute {position: initial !important;}
    .modal-header-styled .title-block p .m-title {max-width: 100%;margin-top: 10px;}
    .modal-header-styled .title-block p span.ar-golr-l {height: 0px;display: none;}
    .modal-header-styled .title-block p.w-100 {display: inline-block;width: 100%;}
    .modal-header-styled .title-block p span.ar-golr-r {display: none;height: 0px;}
    .popup-dash-calendar-fix {line-height: 1.2;}
    .button-regular.next.transition.paper-bc {max-width: 100%; margin: 10px 0 0 0; padding: 5px 10px; height: auto;width:10em; }
    .modal-message.pop-style .popup-dash-calendar-fix input.standart-input,.modal-message.pop-style .popup-dash-calendar-fix input {height: 40px;padding: 0px 10px;float: left;margin: 0 0 10px 0;}
    .modal-message.pop-style .popup-dash-calendar-fix textarea.medical-text-area {resize: none;margin: 10px 0;}
    .modal-message.pop-style textarea.medical-text-area {resize: none;margin: 10px 0;}
    .modal-message.pop-style .address-edit-div input {height: 45px;padding-left: 10px;;float: left;margin-bottom: 10px;}
    .form-input.shadowed.b-rounded-blue.styled-select.pointer {border: none;box-shadow: none;}
    .modal-message.pop-style .address-edit-div input[type="checkbox"] {height: auto;top: 8px;}
    .modal-message.pop-style .address-edit-div label {margin: 0px;line-height: 1;}
    .modal-message.pop-style .address-edit-div .pointer {display: inline-block;width: 100%;height: 35px;margin-bottom: 10px;float: left;}
    .modal-message.pop-style .popup-select-area ul.select2-selection__rendered {padding: 0px;width: auto;}
    .modal-message.pop-style .popup-select-area ul.select2-selection__rendered li {font-size: 12px;padding: 0px 5px;display: table;width: auto;}
    #dashboard p{margin-bottom: 0; }    
    .modal-custom-body .popup-select-area .select2-selection.select2-selection--multiple {min-height: auto !important;}
    .modal-custom-body .popup-select-area ul.select2-selection__rendered {display: inline-block;float: left;margin-bottom: 10px;}
    .modal-header-styled .title-block p .m-title {text-align: left;width: 100%;}
    .modal-custom-body .popup-select-area b.food-prefs-header {text-align: left !important;float: left;}
    .modal-custom-body .addresses-fields h3 {text-align: left;width: 100%;clear: left;}
    .modal-custom-body .addresses-fields .addr-checkbox label {text-align: left;float: left;}
    .modal-custom-body .addresses-fields input[type="checkbox"] {top: 2px !important;}
} 
.card-kacal {
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
}
.dc-alergy-holder.d-flex p {
    margin: 0px;
}
.dc-dislike-holder.d-flex {
    margin: 0px !important;
}
.side-dish-kcal p,.side-dish-kcal .d-kal-info {
    padding-top: 0px;
    padding-bottom: 0px;
}
.address_div.dashboard {
    margin: 0 !important;
    padding: 0;
  }
.address_div.dashboard .addr_sec {
    width: 100%;
    margin-bottom: 10px;
}
.address_div.dashboard strong {
    font-weight: 600;
}

.container-fluid.sub-details {
    background-color: #001f3d;
    color: #fff;
    border-top: 1px solid #fff;
    padding-top: 0;
  
    line-height: 80px;
  }
  .sub-details p,.sub-details p strong {
    color: #fff;
    text-align: center;
  }
  .border-l-r {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  .tabs {
    margin: 0;
    text-align: left;
    padding: 0;
  }
  .tabs li {
    display: inline-block;
    margin-right: 5px;
    padding: 10px;
    background: #818d99;
    color: #fff;
  }
  .tabs li.selected {
    background: #001f3d;
  }
  .bd-month-year {
    margin: 0;
    padding: 0;
  }
.bd-month-year li {
    display: inline-block;
    padding: 5px 15px;
    font-weight: bold;
    position: relative;
}
.bd-month-year > li:first-child {
    min-width: 100px;
}
.bd-month-year li ul  {
    position: absolute;
    display: none;
    min-width: 100px;
    background: #fff;
    z-index: 0;
    padding: 0;
    margin: 0;
    border: 1px solid #f5f5f5;
    top: auto;
    text-align: center;
    left: -18px;
}
.bd-month-year li:hover > ul {
    display: block;
}
.bd-month-year li ul li {
    color: #12bdc7;
  }
  .sub-details p strong {
    font-weight: bold;
    margin-top: 10px;
    display: block;
  }
.body-msg img {
    max-width: 100%;
    max-width: 160px;
    text-align: center;
}
.wrap-progress-page .paper-bc {
    width: 14em;
  }
.empty-chart.measurement:after {
    content: "Click measurement tracking button to add new data";
}
.empty-chart.bodymass:after {
    content: "Click edit icon near Body Fat Mass to add new data";
}
.gender p{
    text-transform: capitalize;;
}
.pref .dislist {
    float: none;
    margin: 0;
    padding: 5px 0;
  }
.pref .dislist h2{
  font-size: 16px;  
}
.styled-select select.company{
    font-weight: normal;
    font-size: 15px;;
}
.companylist{
    float: left;
    width: 100%;
}
.modal-custom-body strong,.modal-custom-body b{
    font-weight: 600;
  }
  .deliverypartner-name{
    font-size: 12px;
    float: left;
    margin-top: 10px;
    margin-right: 5px;
}
.deliverypartner-name strong{
    font-weight:bold;
    font-size: 12px;
}
.sign-up-form .sign-up-button:hover {
    background: #12bdc7;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #001f3d;
  }
  ::-webkit-scrollbar-thumb {
    background: #12bdc7;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #12bdc7;
  }
  .thank-container{
    max-width: 360px;
    margin: 0 auto;
  }
  .wrap-progress-page .checkout-process .thank-container .button-small.transition.light-blue-btn.paper-bc {
    padding: 8px 25px !important;
    margin-right: 0;
  }


/*14-2-25*/
.marquee {
    position: inherit;
    background: #f1f9f9;
    border-radius: 10px;
    padding: 1rem 1.2rem;
    display: block;
    width: max-content;
    white-space: normal;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    min-width: 350px;
    max-width: 500px;
    height: max-content;
}
.marquee img {
    width: auto;
    height: 75px;
    position: relative;
    left: -10px;
}
.diet-text p {
    margin: 0px;
    font-style: italic;
    font-weight: 500;
    display: inline-block;
    text-align: left;
    line-height: normal;
    margin-bottom: 10px;
    font-size: 15px;
}
.diet-text p:last-child {
    font-weight: 400;
    margin: 0px;
}
.notify-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
@media only screen and (max-width: 767px){
    .marquee {
        margin: 0 1.2em;
        width: auto;
    }
} 
@media only screen and (min-width: 768px) and (max-width: 1199px){
    .sign-left {
        display: none;
    }
    .sign-container {
        justify-content: center;
    }
    .sign-up-form .form {
        min-height: auto;
    }
}