/* SM */
@media screen and (max-width: 1655px){
    .bg-cos p .ammount{
        font-size: 50px;
    }
}
@media screen and (min-width: 769px){
    .weeks-nav .mini {
        margin-bottom: 0;
    }
    .hidden-block{
        display: none;
    }
    .progress-page-inner{
        height: 100%!important;
    }
    .progress-page {
        overflow: hidden;
    }
    .hidden-on-desktop,
    .title-for-mobile{
        display: none;
    }
    .blog-filters .dish-groups li:hover {
        background-color: #333e53;
        color: #fff;
        border: 1px solid #465261;
    }
    .horizontal-scrollbar::-webkit-scrollbar{
        height: 5px;
    }
    .top-params .always-scrollbar{
        overflow-y: hidden!important;
    }
    .top-params .always-scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
    }
    .top-params-filters .custom-scrollbar{
        min-height: 355px;
        margin-top: .5rem;
        margin-bottom: 1rem;
    }
    /* Scrollbar styles ==================*/
    .vertical-scrollbar::-webkit-scrollbar-track,
    .horizontal-scrollbar::-webkit-scrollbar-track {
        background-color: #fff;
        border-left: 1px solid #f3f3f3;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #f3f3f3;
    }
    .vertical-scrollbar::-webkit-scrollbar,
    .horizontal-scrollbar::-webkit-scrollbar {
        width: 10px;
    }
    .vertical-scrollbar::-webkit-scrollbar-thumb,
    .horizontal-scrollbar::-webkit-scrollbar-thumb {
        background-color: #f3f3f3;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;
        border-radius: 0.2rem;
    }
    .always-scrollbar{
        overflow: scroll!important;
    }
    /* Scrollbar end================= */
    .contact-modal .vertical-scrollbar::-webkit-scrollbar-track,
    .contact-modal .horizontal-scrollbar::-webkit-scrollbar-track{
        border-left: none;
        border-bottom: none;
    }
}
@media screen and (min-width: 769px) {
    .m-page-menu-modal{
        visibility: hidden;
        transform: scale(0);
    }
    .programm-titles .tool-tip-on-mb{
        display: none;
    }
}
@media screen and (min-width: 1050px) and (max-width: 1250px)  {
    .check-block{
        width: 70px;
    }
    .check-block .check-block-text, .check-block .check-block-text *{
        font-size: 11px;
    }
}
@media screen and (min-width: 769px) and (max-width: 1049px)  {
    .check-block{
        width: 55px;
    }
    .check-block .hide-aed{
        display: none;
    }
    .allert-message-aed{
        display: block;
    }
}
@media screen and (min-width: 576px) and (max-width: 768px)  {
    .time-programm-group{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .h-100{
        height: initial!important;
        min-height: 100%;
    }
}
/* LG */
@media screen and (min-width:991px){
    .dish-holder {
        position: relative;
    }
    .horizontal-scrollbar{
        overflow-x: auto;
        overflow-y: hidden;
        border-right: none;
        border-left: none;
    }
    .vertical-scrollbar{
        overflow-y: auto;
        overflow-x: hidden;
    }
    .horizontal-scrollbar::-webkit-scrollbar{
        height: 5px;
    }
    .top-params .always-scrollbar{
        overflow-y: hidden!important;
    }
    .top-params .always-scrollbar::-webkit-scrollbar-track {
        background-color: transparent;
        border: none;
    }
    .top-params-filters .custom-scrollbar{
        min-height: 355px;
        margin-top: .5rem;
        margin-bottom: 1rem;
    }
    /* Scrollbar styles ==================*/
    .always-scrollbar{
        overflow: scroll!important;
    }
    /* Scrollbar end================= */
    .contact-modal .custom-scrollbar::-webkit-scrollbar-track{
        border-left: none;
        border-bottom: none;
    }
}
@media screen and (max-width:992px){
    .calendar-holder{
        display: none!important;
    }
    .discussions-inner .masonry-wrap .masonry .article{
        width: 48%;
    }
    .regular-page .checkbox-container{
        margin-top: 10px;
    }
    .progress-page .row .part-2 .part-2-inner {
        padding-right: 0;
        border-left: none;
        margin-left: 0!important;
    }
    .bg-gr-light-2>div{
        padding-right: 0;
    }
    .first-mt-2 .bg-1-2{
        display: none;
    }
    .calendar-on-progress{
        margin: 0 auto;
        max-width: 100%;
        flex: 1 1 100%;
    }
    .form-line > div .row .form-input{
        margin-bottom: 20px;
    }
    .progress-class{
        width: 100% !important;
        position: fixed !important;
        top: 0 !important;
        transform: none !important;
        height: 100vh;
        overflow: auto;
    }
    .modal-wrapper{
        max-width: 480px;
    }
    .discussions-inner .all-my .category{
        padding: 0 10px;
    }
    .discussions-inner .all-my .category .row{
        margin: 0 -8px;
    }
    .blog-heading.single{
        margin: 0;
    }
    .blog-heading{
        margin: 0;
    }
    .category-holder{
        position: sticky;
        z-index: 12;
    }
    .add-one-more{
        margin-bottom: 0 !important;
    }
    .next-prev-article .word-content .categoryes{
        display: none;
    }
    .next-prev-article .word-content {
        padding: 10px;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .single-blog-page{
        margin-bottom: 7px;
    }
    .bg-and-sh{
        margin-bottom: 10px;
    }
    .single-blog-page{
        box-shadow: 0 1px 1px rgba(0,0,0,.1);
    }
    .single-blog-page{
        margin-top: 0;
    }
    .blog-heading.single h1, .title-holder .title-block h1{
        font-size: 2rem;
        line-height: 1.1;
    }
    .next-prev-article .word-content .article-title{
        font-size: 14px;
        line-height: 1.2;
    }
    .next-prev-article .word-content h1:before{
        font-size: 8px;
    }
    .next-prev-article .image-bg {
        display: none;
    }
    .sidebar{
        width: 100%;
        padding-top: 0.5rem;
    }
    .form-and-map,
    .list-and-map{
        height: 90%;
    }
    .list-holder{
        height: 100%;
    }
    .nav-item-active::after{
        position: absolute;
        content: '';
        border: none;
    }
    .top-nav-heading>.col-5{
        flex: 0 0 45%;
        max-width: 45%;
    }
    .logo-holder a{
        background-size: contain;
        max-width: 65px;
    }
}

@media screen and (max-width: 1250px) {
    .navbar-nav a{
        font-size: 13px;
    }
    .my-plan .checkbox-container{
        width: 20%;
    }
}

@media screen and (max-width: 1050px) {
    .navbar-nav a{
        text-align: center;
        line-height: 15px;
    }
    .my-plan .checkbox-container{
        width: 25%;
    }
    .empty-chart:after{
        font-size: 20px;
        line-height: 25px;
    }
}

@media screen and (max-width: 768px) {

    .voucher{
        padding: 0;
    }
    .blog-page-post{
        padding-left: 0!important;
        padding-right: 0!important;
    }
    .custom-scrollbar{
        overflow-y: initial!important;
    }
    .single-blog-page{
        padding: 15px;
    }
    .blog-border .single-blog-page{
        margin-top: 8px;
    }
    .masonry-wrap {
        margin-top: 0px !important;
    }
    .support-holder.add-form-holder{
        padding: 0;
    }
    .add-form-holder .add-thread-form {
        margin-top: 0;
        border-radius: 2px;
        box-shadow: 0 2px 3px rgba(0,0,0,.05);
        padding: 15px 0;
        background: #fff;
    }
    .bottom-buttons-holder > div{
        margin: 10px 0!important;
        padding: 0;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .programm-titles .checkbox-container .check-block{
        width: auto;
    }
    .order-buttons{
        margin-bottom: 60px;
        margin-top: 0;
        padding-top: 0;
    }
    .dish-holder{
        margin-bottom: 40px;
    }
    .button-regular.main{
        min-height: 62px;
    }
    .chat-holder-part{
        max-width: initial!important;
    }
    .destroy-mob-scroll .vertical-scrollbar{
        overflow: inherit;
    }
    .modify-page .head-line{
        margin-top: 0;
    }
    .first-mt-2 .bottom-buttons-holder > div{
        padding: 15px;
    }
    .bottom-buttons-holder .d-m-none{
        display: none;
    }
    .wrap-progress-page{
        padding-top: 62px;
    }
    .programm-titles .tool-tip-on-mb{
        margin-bottom: 6px;
    }
    .logo-holder{
        max-width: 66.666667%;
        flex: 0 0 66.666667%;
        margin-left: 16.666667%;
    }
    /*.time-programm-group,*/
    .regular-page .buttons.grid{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .time-programm-group .plan-list{
        height: 93.6%;
        align-items: flex-start;
    }
    .allert-message-aed{
        text-align: center;
    }
    .my-plan .checkbox-container{
        width: 40%;
    }
    .day-header .control-buttons .button.button-skipped{
        width: 123px;
        text-align: center;
        line-height: 14px;
        padding: 5px 0;
        margin: 7px 0px;
    }
    .order-block-holder .head-line{
        margin: 0 -15px;
    }
    .order-block-holder .head-line h5,
    .head-line h5{
        padding: 14px 0 .5rem;
    }
    .sub-line{
        margin: 15px 0 0 0;
        width: 100%;
        text-align: center;
    }
    .hidden-on-desktop.replicate > div{
        padding: 0;
        margin: 1%;
    }
    .hidden-on-mb{
        display: none;
    }
    .bg-gray-bordered.rev-rating{
        margin-right: 0;
        margin-top: 0;
    }

    .f-page .heading p{
        display: none;
    }
    .f-page .line-h-initial{
        line-height: 1;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
        text-align: center;
    }
    .f-page .bottom-row{
        margin-top: 1rem !important;
    }
    .article-wrapper.about{
        padding-top: 7px !important;
    }
    .article-wrapper.about .dsc{
        text-align: center;
    }
    .progress-class .calendar-on-progress{
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0;
    }
    .progress-class .calendar-on-progress .simple-calendar{
        width: 100%;
        padding: 0;
        max-width: 100%;
        margin-top: 1rem;
    }
    .progress-class .place-order.button-regular{
        width: 100%;
        margin-bottom: 2rem;
    }
    .voucher .heading-p {
        padding: 10px 0 10px 10px;
    }
    .voucher .row{
        margin: 0;
    }
    .bg-cos p .ammount{
        font-size: 2rem;
    }
    .track-progress{
        display: block;
    }
    .next-delivery{
        display: none;
    }
    .progress-page .row .part-1 {
        padding: 0 2% 90px;
    }
    .progress-page .row .part-2-inner {
        padding-right: 0 !important;
        border-left: 1px solid #f3f3f3;
    }
    .progress-page .part-2.right-part .dish-holder{
        right: 0;
        border: none;
    }
    .part-1-inner h5.green {
        line-height: 1.2;
    }
    .empty-chart:after{
        text-align: center;
        font-size: 18px;
        line-height: 23px;
    }
    .article .word-content .article-title{
        word-break: break-all;
    }
    .blog-page{
        padding: 0;
    }
    .blog-heading{
        margin-bottom: 0;
    }
    .filters-on-block{
        padding-top: 1px;
        margin: 0 -15px;
    }
    .filters-on-block .category-holder{
        overflow: auto;
        white-space: nowrap;
        padding-bottom: 10px;
    }
    .filters-on-block .category-holder .category{
        display: inline-block;
        color: white;
        text-align: center;
        padding: 14px;
        text-decoration: none;
        display: inline-block;
        color: #fff;
        text-align: center;
        padding: 0 15px;
        text-decoration: none;
        width: max-content;
        margin-right: 5px;
    }
    .filters-on-block,
    .sticky-back{
        position: sticky;
        top: 60px;
        z-index: 10;
        background-color: #f2f2f2;
        margin: 0 -30px;
        padding: 0 15px 8px;
    }
    .filters-on-block.forum{
        top: 0;
    }
    .sticky-back .blog-filters{
        background: none;
    }
    .article {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .support-holder{
        width: 100%;
    }
    .blog-heading:before{
        /*height: 25%;*/
    }
    .title-holder .title-block p span {
        line-height: 18px;
        max-width: 210px;
    }
    .tool-tip-on-mb{
        display: inline-block;
        background: #fff;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        border: 1px solid #fff;
        text-align: center;
    }
    .tool-tip-on-mb span{
        line-height: 30px;
        color: #7fa994;
        font-size: 12px;
        font-weight: 700;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    [class*=" icon-"], [class^=icon-] {
        line-height: inherit;
    }
    .inp-on-mobile.chheck-holder{
        margin: 10px 10px !important;
        padding-left: 0;
    }
    .inp-on-mobile.chheck-holder .check-bas{
        right: 15px;
    }
    .summary-page .bg-gr-light-duration{
        display: flex;
    }
    .summary-check .green{
        line-height: 20px;
    }
    .summary-product-holder p {
        display: inline-block;
        width: 100%;
        line-height: inherit !important;
        text-align: left!important;
    }
    .summary-product-holder span{
        line-height: inherit !important;
        text-align: left!important;
    }
    .summary-product-holder p span{
        padding-left: 0 !important;
    }
    .form-line > div .row > div{
        padding: 0;
    }
    .form-line > div .bg-gray-bordered{
        padding: 15px;
        padding-top: 0;
    }

    .form-line > div .bg-gray-bordered > div.d-flex{
        height: 60px;
        padding: 0;
    }
    .bg-gray-bordered.form-line{
        padding-top: 0;
    }
    .form-line > div .bg-gray-bordered > div.d-flex .checkbox-container{
        padding-left: 5px;
    }
    .form-line > div .row .form-input.text-area-input{
        margin-bottom: 0;
    }
    textarea.standart-input {
        font-size: 15px!important;
        margin-top: 15px;
        margin-bottom: -10px;
    }
    .chat-holder-part{
        background: #fff;
    }
    .main-inner{
        width: 100%!important;
        height: auto!important;
        flex-basis: 100%;
    }

    .main-on-mb {
        margin: 0 !important;
    }
    #root{
        min-height: unset;
        -webkit-overflow-scrolling: touch;
    }
    .main-holder {
        min-height: initial;
        max-width: initial;
        height: unset;
        min-height: unset;
        max-height: unset;
    }
    .regular-page{
        height: unset;
        min-height: unset;
        max-height: unset;
    }
    .main-on-mb .sign-up-form{
        width: 100%;
        padding: 15px 35px;
        border: none;
        min-width: auto;
    }
    .xxx{
        display: none;
        position: fixed;
        bottom: 20px;
        background: black;
    }

    .wrapper {
        max-height: unset;
    }
    .calendar-holder, .dish-holder, .list-holder .list{
        padding: 12px 15px;
        /*min-height: 95vh;
        height: inherit;
        max-height: inherit;*/
    }
    .dish-holder{
        min-height: unset;
        max-height: unset;
    }
    .head-line{
        position: relative;
        z-index: 0;
        /*margin-top: 60px;*/
    }
    .discussions-inner .masonry-wrap .masonry .article{
        width: calc(50% - 10px);
    }
    .togle-dishes-menu p{
        width: inherit !important;
        margin: 0 auto;
        padding: 0 !important;
        line-height: 40px;
        text-align: center;
    }
    .togle-dishes-menu {
        position: fixed;
        width: 100%;
        z-index: 998;
        bottom: 0px;
        left: 0;
        margin: 0;
        padding: 0 10px;
       /* background: #fff;
        border-top: none;        
        border-top: 1px solid #f4f4f4;
        box-shadow: 0 1px 5px rgba(0,0,0,.15);*/
    }
    .togle-dishes-menu .regular-button {
        display: none;
    }
    .togle-dishes-menu .regular-button.next {
        display: block;
    }
    .togle-dishes-menu > div{
        padding: 10px 5px;
    }
    .togle-dishes-menu .regular-button{
        background: #fff !important;
        border-radius: 3px !important;
        box-shadow: 0 1px 5px rgba(0,0,0,.02) !important;
        border: 1px solid #a5a6a7!important;
    }
    .togle-dishes-menu .regular-button.next{
        background: #e5cb9a!important;
        color: #353c48!important;
        border-color: #e5cb9a!important;
    }
    .day-menu {
        height: auto;
        /*min-height: 79px;*/
    }
    .hidden-on{
        left: 100%;
    }
    .togle-dishes-menu p.week{
        height: 42px;
        line-height: 25px;
        padding: 5px !important;
        font-size: 14px;
    }
    .togle-dishes-menu p.week.disabled{
        border-color: #dddddd !important;
    }
    .chat-holder-part .dish-holder, .chat-holder-part .one-day-menu {
        padding: 15px 15px 10px;
    }
    .position-hidden,
    .position-hidden .mobile,
    .position-front{
        overflow: visible !important;
    }
    .day-menu:last-child{
        margin-bottom: 20px;
    }
    .chat-holder-part.active-part{
        width: 100%;
        max-width: 100%;
    }
    one-day-menu{
        background: #fff;
        margin-top: -50px;
    }
    .position-hidden .mobile .left-dish{
        position: fixed;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100vh !important;
        padding-bottom: 55px;
    }
    .heading .stars p .icon-star{
        display: inline;
    }
    .position-hidden{
        position: absolute;
        background: #fff;
        left: 100%;
    }
    .chat-holder-part.position-front{
        left: 0;
    }
    /*.hidden-off,*/
    .chat-holder-part .dish-holder,
    .one-day-menu{
        margin-left: 0;
        animation: play2 0.1s steps(10);
    }
    .position-hidden .mobile .left-dish{
        animation: play 0.1s steps(10);
    }
    .main-inner .d-flex {
        display:block;
    }
    .hidden-on-desktop{
        display: flex;
    }
    .hidden-block{
        display: none !important;
    }
    .hidden-off{
        left: 0;
    }

    .m-page-menu-modal{
        top: 63px;
        padding-top: 30px;
    }
    .m-page-menu-modal .nav-item .nav-link{
        text-transform: uppercase;
    }
    .burger-content{
        box-shadow: none !important;
        border: none  !important;
        padding: 0  !important;
    }
    .burger-content .nav-link, .burger-content .nav-link:hover {
        padding: 0 0 7px 0;
        font-size: 16px;
        color: #90aa9e !important;
    }
    .icon-profile-ava:after{
        content: "❯";
        font-size: 14px;
        position: absolute;
        position: absolute;
        top: 15px;
        padding-left: 10px;
        padding-left: 10px;
    }
    .burger-content {
        position: absolute;
        top: 1.5vh;
        left: 87px;
        width: 12rem;
        background: #fff;
        box-shadow: 0 1px 10px rgba(0,0,0,.15);
        padding: 10px 10px;
    }
    .light-dark-text,
    h2.green, h5.green{
        text-align: center;
        width: 100%;
        display: block;
    }
    .light-dark-text{
        margin-bottom: 0;
    }
    .multiselect .subtext {
        line-height: 16px;
        text-align: center;
        width: 100%;
        display: inline-block;
    }
    .bottom-buttons-holder .button-regular {
        padding: 0 20px;
        border-radius: 2px!important;
    }
    .program-choose {
        display: none;
    }
    .selected-plan {
        display: block;
    }
    .bg-gr-light-duration{
        display: none;
    }
    .text-center-on-mobile{
        text-align: center !important;
    }
    .time-programm-group .plan-name {
        padding: 18px 15px;
        cursor: pointer;
        line-height: 1.2;
    }
    .program-choose {
        padding: 10px 10px 10px 10px;
        background: #d2eade;
        flex: 1 1 auto;
    }

    .plan-selection-header{
        position: sticky;
        top: 63px;
        background: #fff;
        z-index: 12;
    }
    .time-programm-group .choosed{
        position: sticky;
        top: 112px;
        z-index: 11;
        background: #d2eade;
        border-right: 1px solid #b6cec2;
    }
    .plan-selection-header h5.green{
        background: #fff;
        line-height: 1.3rem;
        margin: 0 !important;
    }
    .title-for-mobile{
        font-weight: bold;
    }
    .program-choose .checkbox-container {
        width: initial !important;
    }
    .time-programm-group > .row:first-child{
        border-bottom: 1px solid #efefef;
    }
    .bottom-buttons-holder .check-bas {
        right: 0;
    }
    .rc-tooltip-placement-right .rc-tooltip-arrow{
        display: none;
    }
    .calendar-holder .bottom-container {
        position: inherit;
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
    }
    .simple-calendar {
        max-width: 90%;
        margin: 0 auto;
        padding: 0 15px;
    }
    .calendar-holder.d-flex,
    .calendar-holder .d-flex{
        display: inherit;
    }
    .to-button.down{
        display: none;
    }
    .chat-holder-part.menu-part,
    .calendar-holder,
    .dish-holder{
        width: 100vw;
        border: none;
    }
    .chat-holder-part.menu-part{
        padding-bottom: 55px;
    }
    .chat-holder-part.menu-part{
        border-bottom: none;
        z-index: 101;
    }
    .order-block-holder{
        border-bottom: none;
    }
    .dish-holder {
        flex-direction: column;
    }
    .day .top-params-filters .d-flex{
        display: flex !important;
    }
    /*test

    .main-inner .d-flex.calendar-holder{
        display: none !important;
    }
    test end*/
    .programm-title{
        display: none !important;
    }
    .hidden-md-down{
        display: none!important;
    }
    .contact-modal{
        width: 90%;
        min-width: unset;
    }
    .main-inner{
        display: flex;
    }
    .profile-menu .link-item{
        min-height: 100%;
        flex-grow: 1;
        border: 1px solid transparent!important;
    }
    .profile-menu .link-item:first-child{
        border-top-left-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .profile-menu .link-item:last-child{
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
    }
    .profile-menu .link-item.active-link-item{
        border: 1px solid #8dbfaa!important;
        box-shadow: inset 0 0 0 1px #8dbfaa;
        z-index: 2;
    }
    .profile-menu{
        height: 50px!important;
    }
    .hidden-lg-up{
        display: block!important;
    }
    .container{
        max-width: initial;
    }
}
@media screen and (max-width: 576px){
    .mce-window.mce-in{
        max-width: 100%;
        height: 100vh !important;
        top: 0!important;
    }
    .mce-window.mce-in .mce-checkbox.mce-abs-layout-item.mce-last.mce-checked{
        left: 0px !important;
        top: 38px !important;
        width: 155px !important;
        height: 18px !important;
    }
    .mce-window.mce-in .mce-reset .mce-container-body{
        height: calc(100vh - 90px) !important;
        width: 100% !important;
    }
    .mce-window.mce-in .mce-reset .mce-container-body .mce-container .mce-container-body input{
        width: auto !important;
        max-width: 120px;
    }
    .mce-window.mce-in .mce-reset .mce-container-body .mce-container .mce-container-body,
    .mce-window.mce-in .mce-reset .mce-foot{
        max-width: 100%;
    }
    .mce-window.mce-in .mce-reset .mce-foot .mce-container-body{
        text-align: center;
    }
    .mce-window.mce-in .mce-reset .mce-foot .mce-btn{
        position: unset;
        display: inline-block;
        width: 120px !important;
        margin: 10px 10px;
    }
    .mce-window.mce-in .mce-reset .mce-container-body textarea{
        width: calc(100vw - 40px) !important;
    }

    .button-regular.paper-bc:focus:not(.disabled), .regular-button:focus:not(.disabled),
    .button-regular.paper-bc:hover:not(.disabled), .regular-button:hover:not(.disabled){
        background: #ffffff!important;
        box-shadow: none;
    }
    .button-regular.paper-bc:focus:not(.disabled),
    .button-regular.paper-bc:hover:not(.disabled){
        border-color: #eef2ee!important;
    }
    .regular-button:focus:not(.disabled),
    .regular-button:hover:not(.disabled){
        border-color: #a5a6a7!important;
    }
    .button-regular.paper-bc.next:focus:not(.disabled), .regular-button.next:focus:not(.disabled),
    .button-regular.paper-bc.next:hover:not(.disabled), .regular-button.next:hover:not(.disabled){
        background: #e5cb9a!important;
        box-shadow: 0 1px 5px rgba(0,0,0,.15);
        border-color: #e5cb9a!important;
    }
    .order-modify-modal .full-size + .full-size{
        margin-top: 15px;
    }
    .order-modify-modal .full-size{
        max-width: 100%;
        flex: 1 1 auto;
    }
    .order-modify-modal .full-size .button-regular{
        margin: auto;
    }
    .search-profile-main-cal.absolute-cal .rdtOpen{
        width: 100%!important;
    }
    .simple-calendar{
        padding: 0;
        max-width: 100%;
    }
    .plan-selection-header h5.green{
        font-size: 13px;
    }
    .bottom-row{
        padding-left: 30px;
        padding-right: 30px;
    }
    .rdt > *{
        z-index: 2;
        position: relative;
    }
    .calendar-selected{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 2;
    }
    .calendar-selected:after {
        top: 76px;
        position: absolute;
        content: "\D7";
        right: 21px;
        display: block;
        width: 20px;
        height: 20px;
        font-size: 45px;
        line-height: 20px;
        height: 100vh;
    }
    .search-profile-main-cal.absolute-cal .rdtOpen{
        padding: 15px;
        background: transparent!important;
        border: none!important;
        box-shadow: none;
        position: fixed !important;
        top: 95px;
    }
    .main-page .button-regular{
        width: 100%!important;
        max-width: 450px;
    }
    .h-100{
        height: initial!important;
        min-height: 100%;
    }
    .modify-page,.plan-page,.wrap-progress-page{
        min-height: 100%!important;
        height: initial!important;
    }
    .dish-groups li.bg-gold{
        min-width: 52px;
    }
    .togle-dishes-menu p.week{
        display: flex;
        flex-direction: column;
    }
    .dish-groups li{
        font-size: 10px;
        letter-spacing: .05em;
        padding: 5px 7px;
    }
    .dish-groups{
        padding-right: 70px;
    }
    .feedback-page .dish-groups{
        padding-right: 0;
        padding-bottom: 15px;
    }
    .day-header .control-buttons .button{
        margin: 10px 0 15px;
    }
    .day .day-header .control-buttons{
        float: none;
    }
    .day .day-header h5{
        padding: 0;
    }
    .progress-modal .paper-bc{
        width: 100%!important;
        margin: 10px auto;
    }
    .progress-modal .modal-header-styled{
        line-height: 20px;
    }
    .progress-modal .modal-header-styled .title-block p .m-title{
        max-width: initial;
        font-size: 16px;
    }
    .togle-dishes-menu p.week span{
        line-height: 14px;
        font-size: 13px;
    }
    .bg-cos{
        top: -310px;
    }
    .bg-cos p .ammount{
        font-size: 23px;
    }
    .discussions-inner .masonry-wrap .masonry .article{
        width: 100%;
        margin-bottom: 20px;
    }
    .programm-titles .tool-tip-on-mb{
        margin-right: 15px;
    }
    .blog-heading-menu .blog-heading.menu-heading h1, .blog-heading-menu .blog-heading h1{
        margin-bottom: 0;
    }
    .title-holder .title-block p span{
        line-height: 18px;
    }
    .list-and-map{
        flex-basis: 100%;
        height: auto!important;
    }
    .nav{
        flex-wrap: nowrap;
        width: 100%;
    }
    .nav-item-active{
        border: 1px solid #8dbfaa!important;
    }
    .profile-general,
    .list {
        border-radius: 0 !important;
    }
    .title-holder {
        padding: 0;
    }
    .modal-custom-body {
        width: 95%;
    }
    .title-holder .title-block p,
    .modal-header-styled .title-block p{
        position: relative;
    }
    .title-holder .blog h1{
        color: #efefef !important;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 21px;
    }
    .title-holder .blog p span,
    .modal-header-styled .title-block p .m-title {
        max-width: 210px;
    }
    h2.green, h5.green {
        margin-bottom: 0;
    }
    .checkbox-box, .my-plan .checkbox-container, .program-choose .checkbox-container {
        position: relative;
        padding-right: 0;
        margin-right: 0;
        margin-left: 4%;
        margin-bottom: 0;
        display: inline-block;
        top: 0;
        right: 0;
    }
    .my-plan .checkbox-container{
        padding-right: 45px;
        width: 100%;
        margin-left: 0;
    }
    .marged-on-mobile{
        margin-bottom: 1rem !important;
    }
    .d-m-none{
        display: none;
    }
    .paper-bc span{
        line-height: 18px;
    }
    .pref-page .multiselect:first-child{
        padding-top: 0;
    }
    .empty-jar{
      bottom: 0;
    }
}
@media screen and (max-width: 1293px) {
    .main-page .button-regular {
        border-color: transparent;
        background: #fff;
        width: 75%;
        border-radius: 3px;
    }
}

@media screen and (max-width: 1120px) {
    .blog-page .all-my .blog-filters, .all-my .blog-filters {
        top: -70px;
    }
    .blog-page .all-my .blog-filters .back-holder, .all-my .blog-filters .back-holder{
        background: none;
    }
    .blog-page .all-my .all-my .blog-filters .back-holder .back-link span, .all-my .blog-filters .back-holder .back-link span{
        font-size: 24px;
        color: #f1f1f1;
    }
    .all-my .blog-filters{
        left: 4px;
    }
}
@media screen and (max-width: 1440px) {

    .check-block-text-second{
        font-size: .7rem!important;
    }
    .main-page .button-regular {
        border-color: transparent;
        background: #fff;
        width: 73%;
        border-radius: 3px;
    }
    .navbar-nav li a{
        text-transform: capitalize;
    }
    .bg-gr-light.programm-title{
        padding: 0 5px;
    }
    .calendar-holder{
        max-width: 250px;
    }
    .chat-holder-part{
        width: 300px;
    }
}

@media screen and (min-width: 1441px) {
    .programm-title h5{
        font-size: 18px;
        line-height: 25px;
    }
    .main-page .button-regular span.title {
        line-height: 21px;
        min-width: 93%;
        padding: 0 15%;
    }
    .first-block .full-bg-holder{
        width: 73%;
        padding-top: 73%;
        margin: auto;
    }
    .list-holder .list .list-item{
        padding: 1.5rem 0;
    }
    .main-inner{
        width: 100%!important;
    }
    .become-sitter-modal .col-3.decreasing{
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
        margin-left: 25%;
    }
    .m-page-long-button{
        height: 2.6rem;
    }
    .full-bg-holder {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;
    }
}
@keyframes play {
    0% { left: 100%; }
    100% { left: 0 !important; }
}
@keyframes play2 {
    0% { margin-left: 100%; }
    100% { margin-left: 0; }
}
